import Loading from "../../../Componentes/Loading";
import {
  TextField,
  Grid,
  Icon,
  InputAdornment,
  IconButton,
  Snackbar,
  Button,
  Typography,
  FormControlLabel,
  Radio,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useState } from "react";

import { useVentas } from "./VentasProvider";
import VentasTablaFactura from "./VentasTablaFactura";
import { Styles } from "./Styles";

/** componente principal */
const VentasFactura = () => {
  const classes = Styles();

  const [noExisteitems, setNoExisteItems] = useState(false);
  const {
    itemsFactura,
    BuscaProducto,
    switchBuscaProducto,
    openSnack,
    setOpenSnack,
    setTerminarVentaDialog,
    inputBuscaCodigo,
    limpiarTodo,
    cantidadProductoInput,
    nroFactura,
    BotonAgregar,
    cambioMoneda,
    moneda,
    monedas,
    setOpenDialogCotizacion,
    cargandoItem,
    cargando,
    listaCajas,
    setIdCaja,
    idCaja,
    totalPrecioFactura,
    numberWithCommas,
    redondearNro, setOrdenDeCompra,ordenDeCompra
  } = useVentas();

  const TOTAL_FACTURA_CON_IVA = redondearNro(
    totalPrecioFactura / moneda.valor_moneda
  );

  const date = new Date();
  const fechita =
    date.getFullYear().toString() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, 0) +
    "-" +
    date.getDate().toString().padStart(2, 0);

  const verificarSIhayITEMS = () => {
    if (itemsFactura.length === 0) {
      setNoExisteItems(true);
      inputBuscaCodigo.current.focus();
    } else {
      setNoExisteItems(false);
      setTerminarVentaDialog(true);
    }
  };
  const ProcesarVentaBotones = (
    <div className={classes.botones}>
      <Button
        variant="outlined"
        onClick={verificarSIhayITEMS}
        size="large"
        color="secondary"
      >
        FACTURAR
      </Button>
      <Button
        variant="outlined"
        onClick={limpiarTodo}
        size="large"
        color="primary"
      >
        NUEVA
      </Button>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        onClick={() => setOpenDialogCotizacion(true)}
      >
        Cotización
      </Button>
    </div>
  );

  const KeyCapture = (e) => {
    if (e.ctrlKey && e.shiftKey && e.which === 83) {
      switchBuscaProducto();
    }
  };

  if (cargando) {
    return <Loading />;
  }

  return (
    <div className={classes.contenedor}>
      <Snackbar
        open={noExisteitems}
        onClose={() => {
          setNoExisteItems(!noExisteitems);
        }}
        autoHideDuration={1000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity="error" variant="filled">
          No hay productos en la factura
        </Alert>
      </Snackbar>

      <Snackbar
        open={openSnack}
        onClose={() => {
          setOpenSnack(false);
        }}
        autoHideDuration={1500}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity="error" variant="filled">
          Código de producto inexistente
        </Alert>
      </Snackbar>

      <Grid container spacing={0}>

        <Grid item xs={12} md={4}>

          <Grid container spacing={1}>
            <Grid xs={6} item>
              <TextField
                inputRef={inputBuscaCodigo}
                id="input_inserta_codigo_producto"
                autoComplete="off"
                autoFocus
                name="codigo_producto"
                variant="outlined"
                label="Código de Producto"
                placeholder="Shift + CTRL + s"
                fullWidth
                onKeyUpCapture={KeyCapture}
                onKeyPress={BuscaProducto}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={switchBuscaProducto}>
                        <Icon>search</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment>
                      {cargandoItem && <CircularProgress size={24} />}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid xs={3} item>
              <TextField
                inputRef={cantidadProductoInput}
                type="number"
                name="cantidad"
                variant="outlined"
                label="Cantidad"
                defaultValue="1"
              />
            </Grid>
            <Grid item xs={3}>
              <IconButton onClick={BotonAgregar}>
                <Icon color="primary">add_circle</Icon>
              </IconButton>
            </Grid>

            <Grid item xs={12} md={12}>
              <div className={classes.infoFactura}>
                <Typography variant="button">
                  FACTURA NRO: {nroFactura}
                  {` FECHA: ${fechita}`}
                </Typography>
              </div>
            </Grid>
            
            

            <Grid item xs={12} md={12}>
              <FormControl className={classes.selectsLabels}>
                <InputLabel>Seleccione caja</InputLabel>
                <Select
                  variant="outlined"
                  onChange={(e) => setIdCaja(e.target.value)}
                  value={idCaja}
                  fullWidth
                >
                  {listaCajas.map((item, index) => (
                    <MenuItem key={index} value={item.id_caja}>
                      {item.nombre_caja}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField variant="outlined" label="Orden de compra" value={ordenDeCompra} helperText="Si no tiene deje en blanco" onChange={e=>setOrdenDeCompra(e.target.value)} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h6">
                Total: {`  `}  
                {numberWithCommas(TOTAL_FACTURA_CON_IVA)}
                {moneda.abreviatura_moneda}
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              {ProcesarVentaBotones}
            </Grid>

          </Grid>
        </Grid>




        <Grid xs={12} item md={8}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography variant="button">MONEDA: </Typography>
              {monedas.map((data) => (
                <FormControlLabel
                  key={data.id_moneda}
                  value={data.id_moneda}
                  control={
                    <Radio
                      checked={moneda.id_moneda === data.id_moneda}
                      onChange={cambioMoneda}
                      color="primary"
                    />
                  }
                  label={`${data.nombre_moneda}`}
                  labelPlacement="start"
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <VentasTablaFactura />
            </Grid>
          </Grid>
        </Grid>



      </Grid>
    </div>
  );
};

export default VentasFactura;
