import React from 'react';

import { CircularProgress, makeStyles} from '@material-ui/core'


const useStyles = makeStyles(()=>({

    grip:{
        position:"absolute",
        top:"50%",
        left:"50%",
        transform:"translate(50%,50%)",
        
    }
}))

const Loading = ()=>{
    const clases = useStyles();
    return (
            <div className={clases.grip}>
                <CircularProgress /> 
            </div>
    )
}


export default Loading;