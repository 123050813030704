import React from 'react'
import CategoriasLista from './CategoriasLista'

const CategoriasMain = () => {
  return (
    <>
      <CategoriasLista />
    </>
  )
}

export default CategoriasMain
