import React from 'react'

const NotaNew = () => {
  return (
    <>
      <h1>nueva nota</h1>
    </>
  )
}

export default NotaNew
