import {Dialog, DialogContent, Typography, Button, DialogActions } from "@material-ui/core"
import { useVentas } from "./VentasProvider"


const Cotizacion = () => {
    const {monedas,openDialogCotizacion,setOpenDialogCotizacion,numberWithCommas} = useVentas()

  return (
    <Dialog open={openDialogCotizacion} onClose={()=>setOpenDialogCotizacion(false)} fullWidth>
        <DialogContent>
        <Typography variant="h6">
              Cotización del día
            </Typography>
            {
              monedas.map(e=>(
                <p key={e.id_moneda}>
                  {e.nombre_moneda}: {numberWithCommas(e.valor_moneda)}
                </p>
              ))
            }
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" size="large" onClick={()=>setOpenDialogCotizacion(false)} color="primary">OK</Button>
        </DialogActions>
    </Dialog>
  )
}

export default Cotizacion
