import React from 'react'
import Proximante from '../../../Componentes/Proximante'

const Inventario = () => {
  return (
    <>
      <Proximante title="Inventario" />
    </>
  )
}

export default Inventario
