import { useState, useEffect, useContext, createContext, useRef } from "react";
import swal from "sweetalert";
import { APICALLER } from "../../../Api/ApiCaller";
import { useLogin } from "../../../Contextos/LoginProvider";
import { BASEURL, history } from "../../../VariablesGlobales/globales";

const Contexto = createContext();

const ComprasProvider = ({ children }) => {
  const {token_user} = useLogin()
  const [cargando, setCargando] = useState(false);
  const [cargandoItem, setCargandoItem] = useState(false);
  const [dialogCompra, setDialogCompra] = useState(false);
  const [itemsFactura, setItemsFactura] = useState([]);
  const [openDialogSearch,setOpenDialogSearch] = useState(false)

  const [formulario, setFormulario] = useState({
    codigo_producto:"",
    nombre_producto:"",
    preciom_producto:"",
    precio_producto:"",
    costo_producto:"",
    stock_producto:"",
    stock_nuevo:""
  });
  
    
  const date = new Date();
  const f = date.getFullYear().toString() +"-" +(date.getMonth() + 1).toString().padStart(2, 0) +"-" +date.getDate().toString().padStart(2, 0);

  const inputCodigo = useRef(null);

  const [datosCompra,setDatosCompra] = useState({
    fecha_compra:f,
    nro_factura:"",
    tipo_factura:"1",
    fecha_pago:f,
    total_factura:0,
  })
  
  
  
  const Borrar = (codigo) => {
    let array =  [...itemsFactura]
    let index = array.findIndex(i => i.codigo_producto === codigo)
    array.splice(index, 1);
    setItemsFactura(array);
  };
  
  
  const InsertarProductoTabla = (item) => {
    setFormulario({...formulario, codigo_producto: item.codigo_producto, stock_producto:item.stock_producto, nombre_producto: item.nombre_producto});
    setDialogCompra(true);
  };






  const EfectuarCompra = async()=>{
    if(itemsFactura.length>0){
      let arr = [...itemsFactura]
      var data ={}
      arr.forEach(async(item)=>{
        data = {
          precio_producto: item.precio_producto,
          preciom_producto:item.preciom_producto,
          stock_producto: `${ parseFloat(item.stock_producto)+ parseFloat(item.stock_nuevo)}`
        }
        let res = await APICALLER.update({table:'productos',data,token:token_user,namecolumn:'codigo_producto',equalTo:item.codigo_producto})
        res.response!=="ok" && console.log(res)
      })

      console.log(datosCompra)
      
      swal({text:'Compra realizada!',icon:'success',timer:5000}).then(()=>{
        LimpiarTodo();
      })
    }
    else{
      swal({icon:"error",text:'No hay items en la factura'}).then(()=>{inputCodigo.current.focus() })
    }
    
  }

  const LimpiarTodo = ()=>{
    setItemsFactura([])
    setDatosCompra({
      fecha_compra:f,
      nro_factura:"",
      tipo_factura:"1",
      fecha_pago:f,
      total_factura:0,
    })
    enfocarEnInputCodigo();
  }


  const Hacer_total = (array)=>{
    let suma = 0;
    array.forEach((item)=>{
      suma += ( parseFloat(item.costo_producto) * parseFloat(item.stock_nuevo) ) 
    })
    setDatosCompra({...datosCompra,total_factura:suma})
    //setTotalFactura(suma)

  }




  const ConsultarProducto = async () => {
    var codigo = inputCodigo.current.value;
    // busca si ya hay en el item
    let it = [...itemsFactura];
    let found = it.filter((i) => i.codigo_producto === codigo);
    // si ya hay un producto tons aumenta la cantidad
      if (found.length > 0) {
        inputCodigo.current.value=""
        swal({text:"Ese producto ya está en la lista",icon:'info',timer:3500}).then(()=>{enfocarEnInputCodigo()})
      }
      else{
        setCargandoItem(true);
          // tipo de producto fisico es 1 y 2 es servicio
          let res = await APICALLER.listar({
            table: "productos",
            fields:"codigo_producto,nombre_producto,tipo_producto,stock_producto",
            linkTo: "codigo_producto",
            equalTo: `${codigo}`,
          });
          if (res.response === "ok") {
            if (res.results.length > 0) {
              if(res.results[0].tipo_producto==="2"){
                swal('Ese código pertenece a un servicio').then(()=>{enfocarEnInputCodigo()})
              }
              else{
                setFormulario({...formulario, codigo_producto: res.results[0].codigo_producto, stock_producto:res.results[0].stock_producto, nombre_producto: res.results[0].nombre_producto});
                setDialogCompra(true);
              }
            } else {
              swal({text:'Desea registrar ese producto?',icon:'info',buttons:['No','Si']}).then((si)=>{
                if(si){
                  history.push(`${BASEURL}/productos/new/?codigo=${codigo}`)
                }
              })
            }
            
          } else {
            console.log(res);
          }
      }
    
    setCargandoItem(false);
  };







  const enfocarEnInputCodigo = () => {
    inputCodigo.current.focus();
  };

  useEffect(() => {
    enfocarEnInputCodigo();
  }, []);

  return (
    <Contexto.Provider
      value={{
        cargando,
        setCargando,
        dialogCompra,
        setDialogCompra,
        datosCompra,setDatosCompra,
        itemsFactura,
        setItemsFactura,
        inputCodigo,
        ConsultarProducto,
        formulario,
        setFormulario,
        InsertarProductoTabla,
        Borrar,
        cargandoItem,
        setCargandoItem,
        EfectuarCompra,
        Hacer_total,
        openDialogSearch,setOpenDialogSearch
      }}
    >
      {children}
    </Contexto.Provider>
  );
};

export const useCompras = () => {
  const {
    cargando,
    setCargando,
    dialogCompra,
    setDialogCompra,
    datosCompra,setDatosCompra,
    itemsFactura,
    setItemsFactura,
    inputCodigo,
    ConsultarProducto,
    formulario,
    setFormulario,
    InsertarProductoTabla,
    Borrar,
    cargandoItem,
    setCargandoItem,
    EfectuarCompra,
    Hacer_total,
    openDialogSearch,setOpenDialogSearch
  } = useContext(Contexto);
  return {
    cargando,
    setCargando,
    dialogCompra,
    setDialogCompra,
    datosCompra,setDatosCompra,
    itemsFactura,
    setItemsFactura,
    inputCodigo,
    ConsultarProducto,
    formulario,
    setFormulario,
    InsertarProductoTabla,
    Borrar,
    cargandoItem,
    setCargandoItem,
    EfectuarCompra,
    Hacer_total,
    openDialogSearch,setOpenDialogSearch
  };
};

export default ComprasProvider;
