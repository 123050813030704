
import UsuariosLista from './UsuariosLista'
import UsuarioForm from './UsuarioForm'
import UsuarioDialogConfirm from './UsuarioDialogConfirm'
import UsuarioFormEdit from './UsuarioFormEdit'
import PermisosDialog from './PermisosDialog'


const UsuariosMain = () => {
  return (
    <>
      <UsuariosLista />
      <UsuarioDialogConfirm />
      <UsuarioForm />
      <PermisosDialog />
      <UsuarioFormEdit />
    </>
  )
}

export default UsuariosMain

