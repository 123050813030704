import Proximante from "../../../Componentes/Proximante"



const Notas = () => {
  return (
    <>
      <Proximante />
    </>
  )
}

export default Notas
