import React from 'react'
import Proximante from '../../Componentes/Proximante'

const Empleados = () => {
  return (
    <>
      <Proximante title="modulo de empleados" />
    </>
  )
}

export default Empleados
