import { BASEURL } from "./globales";

export const lista_menu = [
  {
    id: 1,
    texto: "Inicio",
    icono: "apps",
    color: "#a0aec0",
    url: `${BASEURL}/dashboard`,
    id_permiso:"30",
  },

  {
    id: 2,
    texto: "Productos",
    icono: "inventory_2",
    color: "#a0aec0",
    open: false,
    id_permiso:"2",
    submenu: [
      {
        id: 3,
        texto: "Lista",
        icono: "content_paste",
        color: "#a0aec0",
        url: `${BASEURL}/productos`,
        id_permiso:"2",
      },
      {
        id: 4,
        texto: "Categorias",
        icono: "category",
        color: "#a0aec0",
        url: `${BASEURL}/categorias`,
        id_permiso:"4",
      },
      {
        id: 5,
        texto: "Proveedores",
        url: `${BASEURL}/proveedores`,
        icono: "local_shipping",
        color: "#a0aec0",
        id_permiso:"8",
      },
      {
        id: 6,
        texto: "Marcas",
        url: `${BASEURL}/marcas`,
        icono: "branding_watermark",
        color: "#a0aec0",
        id_permiso:"6",
      },
      {
        id: 7,
        texto: "Apartados",
        icono: "save",
        color: "#a0aec0",
        url: `${BASEURL}/apartados`,
        id_permiso:"15",
      },
      {
        id: 8,
        texto: "Compras",
        icono: "shopping_basket",
        color: "#a0aec0",
        url: `${BASEURL}/compras`,
        id_permiso:"16",
      },
      {
        id:29,
        texto:"Depositos",
        url:`${BASEURL}/depositos`,
        icono:"local_convenience_store",
        color:"#a0aec0",
        id_permiso:"31",
      },
      {
        id:40,
        texto:"Inventario",
        url:`${BASEURL}/inventario`,
        icono:"production_quantity_limits",
        color:"#a0aec0",
        id_permiso:"38",
      },
    ],
  },
  {
    id: 9,
    texto: "Ventas",
    icono: "sell",
    color: "#a0aec0",
    url: "url",
    open: false,
    id_permiso:"1",
    submenu: [
      
      {
        id: 10,
        texto: "Notas",
        url: `${BASEURL}/notas`,
        icono: "receipt",
        color: "#a0aec0",
        id_permiso:"13",
      },
      {
        id: 11,
        texto: "Nueva venta",
        url: `${BASEURL}/ventas`,
        icono: "point_of_sale",
        color: "#a0aec0",
        id_permiso:"1",
      },
      {
        id: 12,
        texto: "Lista Facturas",
        url: `${BASEURL}/facturas`,
        icono: "assignment",
        color: "#a0aec0",
        id_permiso:"10",
      },
      {
        id: 13,
        texto: "Presupuestos",
        url: `${BASEURL}/presupuestos`,
        icono: "receipt_long",
        color: "#a0aec0",
        id_permiso:"12",
      },
      {
        id: 14,
        texto: "Entregas",
        url: `${BASEURL}/entregas`,
        icono: "delivery_dining",
        color: "#a0aec0",
        id_permiso:"14",
      },
    ],
  },
  {
    id: 15,
    texto: "Arqueo de Cajas",
    icono: "savings",
    color: "#a0aec0",
    open: false,
    id_permiso:"17",
    submenu: [
      {
        id: 35,
        texto: "Apertura y Cierre",
        url: `${BASEURL}/aperturacierrecaja`,
        icono: `rule_folder`,
        color: "#a0aec0",
        id_permiso:"35",
      },
      {
        id: 16,
        texto: "Cajas",
        url: `${BASEURL}/cajas`,
        icono: `point_of_sale`,
        color: "#a0aec0",
        id_permiso:"19",
      },
      {
        id: 17,
        texto: "Movimientos",
        url: `${BASEURL}/movimientos`,
        icono: `leaderboard`,
        color: "#a0aec0",
        id_permiso:"18",
      },
    ],
  },
  {
    id: 18,
    texto: "Mantenimiento",
    icono: "engineering",
    color: "#a0aec0",
    open: false,
    id_permiso:"20",
    submenu: [
      {
        id: 19,
        texto: "Clientes",
        url: `${BASEURL}/clientes`,
        icono: "people",
        color: "#a0aec0",
        id_permiso:"21",
      },

      {
        id: 33,
        texto: "Empleados",
        url: `${BASEURL}/empleados`,
        icono: "badge",
        color: "#a0aec0",
        id_permiso:"33",
      },

      {
        id: 20,
        texto: "Monedas",
        url: `${BASEURL}/monedas`,
        icono: "monetization_on",
        color: "#a0aec0",
        id_permiso:"24",
      },
      {
        id: 21,
        texto: "Unidad de medida",
        icono: "square_foot",
        color: "#a0aec0",
        url: `${BASEURL}/medidas`,
        id_permiso:"25",
      },
      {
        id: 22,
        texto: "Inventario",
        icono: "handyman",
        color: "#a0aec0",
        url: `${BASEURL}/inventario`,
        id_permiso:"26",
      },
      {
        id: 23,
        texto: "Usuarios",
        icono: "manage_accounts",
        color: "#a0aec0",
        url: `${BASEURL}/usuarios`,
        id_permiso:"27",
      },
    ],
  },

  {
    id: 24,
    texto: "Ajustes",
    icono: "settings",
    color: "#a0aec0",
    id_permiso:"28",
    open: false,
    submenu: [
      {
        id: 25,
        texto: "Datos de Empresa",
        icono: "admin_panel_settings",
        color: "#a0aec0",
        url: `${BASEURL}/settings`,
        id_permiso:"29",
      },
      {
        id: 34,
        texto: "Registro de facturas",
        icono: "app_registration",
        color: "#a0aec0",
        url: `${BASEURL}/registrofacturas`,
        id_permiso:"34",
      },
    ],
  },
  
];
