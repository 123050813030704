import { Dialog,DialogContent, DialogTitle,DialogActions,Button,DialogContentText,TextField, Select, MenuItem,FormControl, InputLabel } from '@material-ui/core'
import {useState,useEffect} from 'react'
import { StylesGenerales } from '../../../Styles/StylesGenerales'
import { APICALLER } from '../../../Api/ApiCaller'
import { useLogin } from '../../../Contextos/LoginProvider'
import { useRegistroFacturas } from './RegistroFacturasProvider'


const DialogFacturaNew = () => {
    const classes = StylesGenerales()
    const { token_user} = useLogin()
    const {openModal,setOpenModal,getFacturas} = useRegistroFacturas()
    const inicial = {
      id_empresa_empresa: "1",
      id_caja_factura : "",
      timbrado_factura : "",
      inicio_timbrado:"",
      fin_timbrado:"",
      nro_datos_factura:"",
      nro_inicio_factura:"",
      nro_fin_factura:"",
      last_nro_factura:"",
    }
    const [formulario,setFormulario] = useState(inicial)
    const [listaCajas,setListaCajas] = useState([])
    
    const enviarFormulario = async (e)=>{
      e.preventDefault();
      let form  = {...formulario};
      form.last_nro_factura = formulario.nro_inicio_factura;
      
      let res = await APICALLER.insert({table:"empresa_facturas",data:form,token:token_user})
      if(res.response==="ok"){
        getFacturas()
        cerrar();
      }else{ console.log(res)}
    }

    const onChange = e =>{
      const {name,value} = e.target
      setFormulario({...formulario,[name]:value})
    }

    const cerrar = ()=>( setOpenModal(false))


    const getCajas = async()=>{
      let res = await APICALLER.listar({table:'cajas',fields:'nombre_caja,id_caja'})
      res.response==="ok" ? setListaCajas(res.results) : console.log(res)
    }

    useEffect(()=>{
       getCajas()
    },[])

  return (
    <Dialog open={openModal} fullWidth onClose={cerrar}>
      <form onSubmit={enviarFormulario} >
        <DialogTitle>Registrar talonario</DialogTitle>
        <DialogContent>
            <DialogContentText>Recuerde que este formulario solo será válido cuando la SET le genere una factura</DialogContentText>
            <TextField fullWidth
              autoFocus
              label="Timbrado nro"
              variant="outlined"
              name="timbrado_factura"
              onChange={onChange}
              helperText="Tenga cuidado no la vaya a cagar"
              value={formulario.timbrado_factura}
              required
              className={classes.textfield}
            />
            <TextField 
              helperText="Fecha inicio de timbrado"
              variant="outlined"
              name="inicio_timbrado"
              onChange={onChange}
              type="date"
              value={formulario.inicio_timbrado}
              required
              className={classes.textfield}
            />

          <TextField 
              helperText="Fecha fin de timbrado"
              variant="outlined"
              name="fin_timbrado"
              onChange={onChange}
              type="date"
              value={formulario.fin_timbrado}
              required
              className={classes.textfield}
            />

          <TextField fullWidth
              label="Nros iniciales de factura"
              variant="outlined"
              name="nro_datos_factura"
              onChange={onChange}
              helperText="Ej: 001-002"
              value={formulario.nro_datos_factura}
              required
              className={classes.textfield}
            />

            
            <TextField 
              label="Nro de inicio de factura"
              variant="outlined"
              name="nro_inicio_factura"
              onChange={onChange}
              helperText="Ej: 300"
              type="number"
              value={formulario.nro_inicio_factura}
              required
              className={classes.textfield}
            />
            <TextField
              label="Nro de final de factura"
              variant="outlined"
              name="nro_fin_factura"
              onChange={onChange}
              helperText="Ej: 350"
              type="number"
              value={formulario.nro_fin_factura}
              required
              className={classes.textfield}
            />
            <FormControl className={classes.SelectForm} >
              <InputLabel >Asignar factura a caja</InputLabel>
                  <Select
                    value={formulario.id_caja_factura} 
                    name="id_caja_factura"
                    variant="outlined"
                    onChange={onChange}
                    >
                      {
                        listaCajas.map((item)=>(
                          <MenuItem key={item.id_caja} value={item.id_caja} >{item.nombre_caja}</MenuItem>
                        ))
                      }
                      
                    </Select>
            </FormControl>

        </DialogContent>
        <DialogActions>
            <Button variant="outlined" type="submit" color="primary" >Registrar</Button>
            <Button variant="outlined" onClick={cerrar} >Cancelar</Button>
        </DialogActions>
        </form>
    </Dialog>
  )
}

export default DialogFacturaNew
