import React, { createContext, useState, useContext, useEffect } from "react";
import { APICALLER } from "../Api/ApiCaller";
import { history, BASEURL,POSIBLES_ERRORES } from "../VariablesGlobales/globales";
import swal from 'sweetalert';
import CryptoJS from "crypto-js";

const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  // estado si esta o no logueado
  const [logueado, setLogueado] = useState(false);
  // cargando la aplicacion
  const [cargando, setCargando] = useState(true);

  // MENSAJE DE BACKEND
  const [mensajeAlerta, setMensajeAlerta] = useState(null);
  const [cargandoMensaje, setCargandoMensaje] = useState(false);

  // datos del usuario

  const CifrarTexto = (text)=> CryptoJS.AES.encrypt(text,'saeta-store').toString()

  const storage = JSON.parse(sessionStorage.getItem("dataUser")) || JSON.parse(localStorage.getItem("dataUser"))
  const [token_user, setToken_user] = useState(storage?.token_user);
  const [permisos,setPermisos] = useState(storage?.permisos)

  const [nombre_user, setNombre_user] = useState("");
  const [username_user, setUsername_user] = useState("");
  const [rol_user, setRol_user] = useState("");
  const [id_user, setId_user] = useState("");
  

  useEffect(() => {
    const verificarLogueoUE = async () => {
      try {
        
        if (token_user) {
          let res = await APICALLER.verifytoken(token_user);
          if (res.results.length > 0 && res.response==="ok") {
            let result = res.results[0];
            
            let permisos = await APICALLER.listar({table:`permisos_users`, linkTo:`id_user_permiso`, fields:`id_permiso_permiso`,equalTo:result.id_user})
            permisos.response==="ok" ? setPermisos(permisos.results) : console.log(permisos)
            setToken_user(result.token_user);
            setNombre_user(result.nombre_user);
            setRol_user(result.rol_user);
            setId_user(result.id_user);
            setUsername_user(result.username_user);
            setLogueado(true);
            let dataUser = {
              id_user: result.id_user,
              username_user: CifrarTexto(result.username_user),
              token_user: result.token_user,
              nombre_user: result.nombre_user,
              rol_user: result.rol_user,
              permisos: permisos.results,
            }
            sessionStorage.setItem("dataUser",JSON.stringify(dataUser))
            //localStorage.setItem("dataUser",JSON.stringify(dataUser))
          }else{
            swal({
              title:"Sesión expirada", text:"Inicie sesión",
              icon:"error",dangerMode:true
            })
            DesLoguearse(); 
          }
        }
        setCargando(false);
      } catch (error) {
        setCargando(false)
        swal({
          title:"Error", text:POSIBLES_ERRORES.conexion,
          icon:"error"
        })
      }
    };
    verificarLogueoUE();
  }, [token_user]);



  const Loguearse = async (data) => {
    setMensajeAlerta(null);
    setCargandoMensaje(true);
    try {
      let res = await APICALLER.loguearse(data);
      
      if (res.results.length > 0 && res.response==="ok") {
        let result = res.results[0];
            let permisos = await APICALLER.listar({table:`permisos_users`,linkTo:`id_user_permiso`, fields:`id_permiso_permiso`,equalTo:result.id_user})
            permisos.response==="ok" ? setPermisos(permisos.results) : console.log(permisos)
            setToken_user(result.token_user);
            setNombre_user(result.nombre_user);
            setRol_user(result.rol_user);
            setId_user(result.id_user);
            setUsername_user(result.username_user);
            setLogueado(true); 

            
            let dataUser = {
              id_user: result.id_user,
              username_user: CifrarTexto(result.username_user),
              token_user: result.token_user,
              nombre_user: result.nombre_user,
              rol_user: result.rol_user
            }
            
            sessionStorage.setItem("dataUser",JSON.stringify(dataUser))
            //localStorage.setItem("dataUser",JSON.stringify(dataUser))
            history.push(BASEURL + "/dashboard");
            setCargandoMensaje(false); 
      } else {
        setMensajeAlerta(res.message);
        setCargandoMensaje(false);
      }
    } catch (error) { 
      console.log(error);
      setCargandoMensaje(false);
    }
    
  };

  const DesLoguearse = async () => {
    try {
            setLogueado(false);
            setCargandoMensaje(false);
            sessionStorage.removeItem("dataUser");
            //localStorage.removeItem("dataUser");
            localStorage.removeItem("factura");
            history.push(BASEURL + "/");
    } catch (error) {}
  };
  const context = {
    loguearse: Loguearse,
    desloguerase: DesLoguearse,
    logueado: logueado,
    permisos:permisos,
  }

  return (
    <LoginContext.Provider
      value={{
        logueado,
        cargando,
        Loguearse,
        setMensajeAlerta,
        mensajeAlerta,
        nombre_user,
        id_user,
        rol_user,
        token_user,
        cargandoMensaje,
        DesLoguearse, context,
        username_user, setUsername_user,permisos
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = () => {
  const {
    logueado,
    cargando,
    Loguearse,
    setMensajeAlerta,
    mensajeAlerta,
    nombre_user,
    id_user,
    rol_user,
    token_user,
    cargandoMensaje,
    DesLoguearse,context,
    username_user, setUsername_user,permisos
  } = useContext(LoginContext);

  return {
    logueado,
    cargando,
    Loguearse,
    setMensajeAlerta,
    mensajeAlerta,
    nombre_user,
    id_user,
    rol_user,
    token_user,
    cargandoMensaje,
    DesLoguearse, context,
    username_user, setUsername_user,permisos
  };
};
