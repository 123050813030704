import {useState} from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Icon,
  Collapse,
} from "@material-ui/core";

import { NavLink, useLocation } from "react-router-dom";

import { lista_menu } from "../VariablesGlobales/listamenu";
import { useLogin } from "../Contextos/LoginProvider";

const estilos = makeStyles((theme) => ({

  links: {
    textDecoration: "none",
    color: "inherit",
  },
  selected: {
    color: theme.palette.primary.main,
  },
  expanded: {
    paddingLeft: theme.spacing(4),
  },
  root: {
    width: '100%',
    maxWidth: 280,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  oculto:{
    display:"none",
    visibility:"hidden"
  },
  iconItem:{
    minWidth:"35px",
  }
}));




const ListaMenu = (props) => {
  const {onClose} = props
  const classes = estilos();
  const location = useLocation();
  const [listaMenu,setListaMenu] = useState(lista_menu)
  const {permisos} = useLogin()
  // funcion para abrir menu
  const switchOpen = (sw,id)=> {
    let array = [...lista_menu];
    let index = array.findIndex((e)=> e.id===id)
    array[index].open = !sw;
    setListaMenu(array);
  }

  

  return (
    <List
      component="nav"
      className={classes.root}
    >
      {
        listaMenu.map((data)=>(
          <div key={data.id}>
          {data.submenu ? 
          <div className={permisos.some(item => item.id_permiso_permiso ===data.id_permiso) ? `` : classes.oculto } >
            <ListItem button onClick={()=>switchOpen(data.open,data.id)} classes={{selected:classes.selected}}>
              <ListItemIcon className={classes.iconItem}>
                <Icon style={{ color: data.color }}>{data.icono}</Icon>
              </ListItemIcon>
              <ListItemText primary={data.texto} />
              <Icon>{ data.open ? `expand_less` : `expand_more` }</Icon>
            </ListItem>
            <Collapse in={data.open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className={classes.nested}>
                  {
                    data.submenu.map((sub,i)=>(
                      <div key={i}  className={permisos.some(item => item.id_permiso_permiso ===sub.id_permiso) ? `` : classes.oculto }   >
                        { permisos.indexOf(element => element.id_permiso_permiso===sub.id_permiso) >-1 && console.log(`coinciden`) }
                        <NavLink  to={sub.url} className={classes.links}  activeClassName={classes.selected} >
                          <ListItem button onClick={onClose} classes={{selected:classes.selected}} selected={sub.url===location.pathname ? true : false} >
                            <ListItemIcon className={classes.iconItem}>
                              <Icon style={{ color: `${sub.url===location.pathname ? '#3f51b5' : sub.color}` }}>{sub.icono}</Icon>
                            </ListItemIcon>
                            <ListItemText primary={sub.texto} />
                          </ListItem>
                        </NavLink>
                    </div>
                    ))
                  }
              </List>
            </Collapse>
          </div> 
          :
          <div className={permisos.some(item => item.id_permiso_permiso ===data.id_permiso) ? `` : classes.oculto }  >
          <NavLink to={data.url} className={classes.links}  activeClassName={classes.selected} >
            <ListItem button onClick={onClose} classes={{selected:classes.selected}} selected={data.url===location.pathname ? true : false} >
              <ListItemIcon className={classes.iconItem} >
                <Icon style={{ color: `${data.url===location.pathname ? '#3f51b5' : data.color}` }}>{data.icono}</Icon>
              </ListItemIcon>
              <ListItemText primary={data.texto} />
            </ListItem>
          </NavLink>
          </div>
          }
          </div>
        ))
      }

    </List>
  );
};

export default ListaMenu;
