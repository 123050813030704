import React, { createContext, useContext, useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import { ThemeProvider, createMuiTheme,  } from "@material-ui/core/styles";


const ThemeContextValue = createContext();

export const ThemeProviderContext = ({ children }) => {


  const [themeMode, setThemeMode] = useState("light");

  const drawerWidth = 240;

  let theme = createMuiTheme({

    palette: {
      type: themeMode==='light' ? "light" : "dark",
      background:{
        paper:themeMode==='light' ? "#ffffff" : "#171721",
        default:themeMode==='light' ? "#f1f1f1" : "#101013",
      },
      
    },
    breakpoints:{

    },
    typography:{
      fontSize:13,
      fontWeightMedium:"bold",
      fontWeightRegular:"500",
      fontFamily:"Montserrat"
    }
  });
  document.body.style.backgroundColor = themeMode==="dark" ? `#171721` : `#fff`;
  

  const changeTheme = ()=>{
    if(themeMode==='light') 
      { 
        localStorage.setItem("themeMode","dark");
        setThemeMode("dark") 
      } 
    else
      { 
        localStorage.setItem("themeMode","light");
        setThemeMode("light")
      }
  }

  const verifica = ()=>{
    const themeModeLocal = localStorage.getItem("themeMode");
    if(themeModeLocal){
      setThemeMode(themeModeLocal);
    }
    else{
      localStorage.setItem("themeMode","light");
    }
  }
  

  useEffect(() => {
    verifica();
  }, [])

  return (
    <ThemeContextValue.Provider value={{themeMode, setThemeMode,changeTheme,drawerWidth}}>
      <ThemeProvider theme={theme}>
        <Paper elevation={0} square >
          {children}
        </Paper>
      </ThemeProvider>
    </ThemeContextValue.Provider>
  );
};

export const useTheme = ()=>{
    const {themeMode, setThemeMode,changeTheme,drawerWidth} = useContext(ThemeContextValue);
    return {themeMode, setThemeMode,changeTheme,drawerWidth}
}

