import {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { useLogin } from "../../Contextos/LoginProvider";
import { APICALLER } from "../../Api/ApiCaller";
import swal from "sweetalert";

const UsuarioContext = createContext();

const UsuariosProvider = ({ children }) => {
  const [openDialogPermisos, setOpenDialogPermisos] = useState(false);
  const [idUserPermiso,setIdUserPermiso] = useState(null)
  const [nombreUserPermiso,setNombreUserPermiso] = useState("")
  const [listaPermisos,setListaPermisos] = useState([])

  const [openDialog, setOpenDialog] = useState(false);

  const [openDialogFormEdit, setOpenDialogFormEdit] = useState(false);

  const [dialogConfirmacion, setDialogConfirmacion] = useState(false);

  const [password_confirm, setPassword_confirm] = useState("");

  const [mensajeError, setMensajeError] = useState("");

  const table = `users`;

  const [lista, setLista] = useState([]);
  const [cargando, setCargando] = useState(true);
  const { token_user, id_user } = useLogin();

  const initialForm = {
    nombre_user: "",
    username_user: "",
    password_user: "",
    email_user: "",
    rol_user: 2,
  };
  const [formulario, setFormulario] = useState(initialForm);

  /*************************************************************** */
  const borrarUser = (id, nombre) => {
    if (id === id_user) {
      swal({
        icon: "error",
        title: "Que estás haciendo?",
        text: "No te puedes borrar a ti mismo",
      });
    } else {
      swal({
        title: "Borrar",
        icon: "warning",
        text: `Borrar a ${nombre}`,
        buttons: ["Cancelar", "Borrar"],
      }).then((e) => {
        if (e) {
          swal({
            title: "Confirmar contraseña",
            icon: "info",
            dangerMode: true,
            buttons: ["Cancelar", "Confirmar"],
            content: {
              element: "input",
              attributes: {
                placeholder: "Debemos estar seguros de que no eres un farzante",
                type: "password",
              },
            },
          }).then(async (pass) => {
            if (pass) {
              const res = await APICALLER.confirmPassword({
                token: token_user,
                password_user: pass,
              });

              if (res.response === "ok") {
                const resp = await APICALLER.delete({
                  table: "users",
                  namecolumn: "id_user",
                  equalTo: id,
                  token: token_user,
                });
                //console.log(resp);
                if (resp.response === "ok") {
                  swal({ icon: "success", title: "Borrado correctamente" });
                  getLista();
                }
              } else {
                swal({
                  icon: "error",
                  title: "Ha ocurrido un error",
                  text: `${res.message}`,
                });
              }
            }
          });
        }
      });
    }
  };

  /*************************************************************** */

  const enviarFormulario = (e) => {
    e.preventDefault();
    setDialogConfirmacion(true);
  };

  /******************************************************* */
  const enviarDeVerdad = async () => {
    const res = await APICALLER.confirmPassword({
      token: token_user,
      password_user: password_confirm,
    });
    if (res.response === "ok") {
      formulario.id_user ? updateDeVerdad() : insertDeVerdad();
    } else {
      setMensajeError(res.message);
    }
  };

  const updateDeVerdad = async () => {
    //console.log(formulario);
    const res = await APICALLER.updateuser({
      token: token_user,
      data: formulario,
    });
    if (res.response === "ok") {
      swal({
        icon: "success",
        text: "Actualizado correctamente",
      }).then(() => {
        setMensajeError("");
        setPassword_confirm("");
        setDialogConfirmacion(false);
        setOpenDialogFormEdit(false);
        setFormulario(initialForm);
        getLista();
      });
    }
  };

  /******************************************************* */

  const insertDeVerdad = async () => {
    const res = await APICALLER.register({ data: formulario });
    if (res.response === "ok") {
      swal({
        icon: "success",
        text: "Agregado correctamente",
      });
      setMensajeError("");
      setPassword_confirm("");
      setDialogConfirmacion(false);
      setOpenDialogFormEdit(false);
      setFormulario(initialForm);
      getLista();
    }
    else{ console.log(res)} 
  };

  /****************************************** */

  const getLista = useCallback(async () => {
    let data = { token: token_user };
    const res = await APICALLER.listausers(data);
    setLista(res.results);
    setCargando(false);
  }, [token_user]);
  const getListaPermisos = useCallback( async()=>{
    let res = await APICALLER.listar({table:`permisos`,orderBy:"descripcion_permiso", orderMode:"ASC",startAt:0,endAt:60});
    if(res.response==="ok"){
      let array = [...res.results]
      array.forEach((element,index) => {
        array[index].checked=false
      });
      setListaPermisos(array)
    }else{ console.log(res)}
  },[])
  useEffect(() => {
    getLista();
    getListaPermisos()
  }, [getLista,getListaPermisos]);

  return (
    <UsuarioContext.Provider
      value={{
        openDialog,
        setOpenDialog,
        cargando,
        setCargando,
        lista,
        setLista,
        enviarFormulario,
        dialogConfirmacion,
        setDialogConfirmacion,
        enviarDeVerdad,
        password_confirm,
        setPassword_confirm,
        mensajeError,
        setMensajeError,
        formulario,
        setFormulario,
        initialForm,
        borrarUser,
        table,
        openDialogFormEdit,
        setOpenDialogFormEdit,
        openDialogPermisos,
        setOpenDialogPermisos,
        idUserPermiso,setIdUserPermiso,nombreUserPermiso,setNombreUserPermiso,listaPermisos,setListaPermisos
      }}
    >
      {children}
    </UsuarioContext.Provider>
  );
};

export const useUsuarios = () => {
  const {
    openDialog,
    setOpenDialog,
    cargando,
    setCargando,
    lista,
    setLista,
    enviarFormulario,
    dialogConfirmacion,
    setDialogConfirmacion,
    enviarDeVerdad,
    password_confirm,
    setPassword_confirm,
    mensajeError,
    setMensajeError,
    formulario,
    setFormulario,
    initialForm,
    borrarUser,
    openDialogFormEdit,
    setOpenDialogFormEdit,
    openDialogPermisos,
    setOpenDialogPermisos,
    idUserPermiso,setIdUserPermiso,nombreUserPermiso,setNombreUserPermiso,listaPermisos,setListaPermisos
  } = useContext(UsuarioContext);
  return {
    openDialog,
    setOpenDialog,
    cargando,
    setCargando,
    lista,
    setLista,
    enviarFormulario,
    dialogConfirmacion,
    setDialogConfirmacion,
    enviarDeVerdad,
    password_confirm,
    setPassword_confirm,
    mensajeError,
    setMensajeError,
    formulario,
    setFormulario,
    initialForm,
    borrarUser,
    openDialogFormEdit,
    setOpenDialogFormEdit,
    openDialogPermisos,
    setOpenDialogPermisos,
    idUserPermiso,setIdUserPermiso,nombreUserPermiso,setNombreUserPermiso,listaPermisos,setListaPermisos
  };
};

export default UsuariosProvider;
