import React from 'react'
import { Switch, Route,Redirect} from "react-router-dom";
import Loading from '../Componentes/Loading';
import { useLogin } from '../Contextos/LoginProvider';


import Categorias from '../Pages/Productos/Categorias/Categorias';
import CategoriasForm from '../Pages/Productos/Categorias/CategoriasForm';
import Clientes from '../Pages/Clientes/Clientes';
import ClientesForm from '../Pages/Clientes/ClientesForm';
import Dashboard from '../Pages/Dashboard/Dashboard';
import Facturas from '../Pages/Ventas/Facturas/Facturas';
import FacturasView from '../Pages/Ventas/Facturas/FacturasView';



import LoginForm from '../Pages/Formularios/LoginForm';

import MainPage from '../Pages/MainPage';
import NotFound from '../Pages/NotFound/Index';
import Presupuestos from '../Pages/Ventas/Presupuestos/Presupuestos';

import Productos from '../Pages/Productos/Productos/Productos';
import ProductosForm from '../Pages/Productos/Productos/ProductosForm';
import ProductosFormEdit from '../Pages/Productos/Productos/ProductosFormEdit';
import ProductosApartados from '../Pages/Productos/ProductosApartados/ProductosApartados';
import Proveedores from '../Pages/Productos/Proveedores/Proveedores';


import Salir from '../Pages/Salir';
import Settings from '../Pages/Settings/DatosEmpresa/Settings';
import Usuarios from '../Pages/Usuarios/Usuarios';
import Ventas from '../Pages/Ventas/Vender/Ventas';
import { BASEURL } from '../VariablesGlobales/globales';
import Marcas from '../Pages/Productos/Marcas/Marcas';
import Compras from '../Pages/Productos/Compras/Compras';
import Caja from '../Pages/Caja/Cajas/Caja';
import NotAutorized from '../Pages/NotFound/NotAutorized';
import Depositos from '../Pages/Productos/Depositos/Index';
import Monedas from '../Pages/Monedas/Index';
import Notas from '../Pages/Ventas/Notas/Notas';
import NotaNew from '../Pages/Ventas/Notas/NotaNew';
import Entregas from '../Pages/Ventas/Entregas/Entregas';
import EntregasView from '../Pages/Ventas/Entregas/EntregasView';
import Movimientos from '../Pages/Caja/Movimientos/Index';
import RegistroFacturas from '../Pages/Settings/RegistroFacturas/RegistroFacturas';
import AperturaCierreCaja from '../Pages/Caja/AperturaCierre/AperturaCierreCaja';
import Empleados from '../Pages/Empleados/Empleados';
import Inventario from '../Pages/Productos/Inventario/Inventario';
import PresupuestosNew from '../Pages/Ventas/Presupuestos/PresupuestosNew';
import Medidas from '../Pages/Medidas/Medidas';

/*=======================
ACA IRIAN TODAS LAS RUTAS NECESARIAS Y TODAS LAS PAGINAS
========================*/


const RouteCustom = ({isPrivado, idPermiso, ...rest})=>{

    const {logueado,cargando,permisos} = useLogin()

    if (isPrivado && !logueado) {
        return <Redirect to={BASEURL+'/'} />
    }

    if(!(permisos.some(item => item.id_permiso_permiso ===idPermiso))){
        return <Redirect to={BASEURL+'/notautorized'} />
    }

    if (cargando) {
        return <Loading />
    }
    if(isPrivado && logueado){
        return(
            <MainPage>
                <Route {...rest} />
            </MainPage>
        )
    }
    
    return (
        <Route {...rest} />
    )
} 


const AllRutas = () => {
  return (
    <Switch>
        
        <Route path={BASEURL+'/salir'} component={Salir} />
        <Route path={BASEURL+`/notautorized`} component={NotAutorized} />

        <RouteCustom isPrivado idPermiso="33" exact path={BASEURL+'/empleados'} component={Empleados} />

        <RouteCustom isPrivado idPermiso="38" exact path={BASEURL+'/inventario'} component={Inventario} />

        <RouteCustom isPrivado idPermiso="1" exact path={BASEURL+'/ventas'} component={Ventas} />
        <RouteCustom isPrivado idPermiso="30" exact path={BASEURL+'/dashboard'} component={Dashboard} />
        <RouteCustom isPrivado idPermiso="21" exact path={BASEURL+'/clientes'} component={Clientes} />
        <RouteCustom isPrivado idPermiso="22" exact path={BASEURL+'/clientes/new'} component={ClientesForm} />
        <RouteCustom isPrivado idPermiso="23" path={BASEURL+'/clientes/new/:id'} component={ClientesForm} />
        
        <RouteCustom isPrivado idPermiso="29" exact path={`${BASEURL}/settings`} component={Settings} />
        <RouteCustom isPrivado idPermiso="34" exact path={`${BASEURL}/registrofacturas`} component={RegistroFacturas} />

        <RouteCustom isPrivado idPermiso="32" exact path={BASEURL+'/depositos'} component={Depositos} />
        <RouteCustom isPrivado idPermiso="2" exact path={`${BASEURL}/productos`} component={Productos} />
        <RouteCustom isPrivado idPermiso="3" exact path={`${BASEURL}/productos/new`} component={ProductosForm} />
        <RouteCustom isPrivado idPermiso="3" exact path={`${BASEURL}/productos/new/:id?`} component={ProductosFormEdit} />
        <RouteCustom isPrivado idPermiso="15" exact path={`${BASEURL}/apartados`} component={ProductosApartados} />
        <RouteCustom isPrivado idPermiso="4" exact path={`${BASEURL}/categorias`} component={Categorias} />
        <RouteCustom isPrivado idPermiso="5" exact path={`${BASEURL}/categorias/new`} component={CategoriasForm} />
        <RouteCustom isPrivado idPermiso="5" exact path={`${BASEURL}/categorias/new/:id?`} component={CategoriasForm} />
        <RouteCustom isPrivado idPermiso="6" exact path={`${BASEURL}/marcas`} component={Marcas} />
        <RouteCustom isPrivado idPermiso="16" exact path={`${BASEURL}/compras`} component={Compras} />

        <RouteCustom isPrivado idPermiso="27" exact path={`${BASEURL}/usuarios`} component={Usuarios} />
        <RouteCustom isPrivado idPermiso="24" exact path={`${BASEURL}/monedas`} component={Monedas} />
        
        <RouteCustom isPrivado idPermiso="13" exact path={`${BASEURL}/notas`} component={Notas} />
        <RouteCustom isPrivado idPermiso="13" exact path={`${BASEURL}/notas/new`} component={NotaNew} />

        <RouteCustom isPrivado idPermiso="14" exact path={`${BASEURL}/entregas`} component={Entregas} />
        <RouteCustom isPrivado idPermiso="14" exact path={`${BASEURL}/entregas/view/:id?`} component={EntregasView} />

        <RouteCustom isPrivado idPermiso="12" exact path={`${BASEURL}/presupuestos`} component={Presupuestos} />
        <RouteCustom isPrivado idPermiso="12" exact path={`${BASEURL}/presupuestos/new`} component={PresupuestosNew} />

        <RouteCustom isPrivado idPermiso="19" exact path={`${BASEURL}/cajas`} component={Caja} />
        <RouteCustom isPrivado idPermiso="35" exact path={`${BASEURL}/aperturacierrecaja`} component={AperturaCierreCaja} />
        <RouteCustom isPrivado idPermiso="18" exact path={`${BASEURL}/movimientos`} component={Movimientos} />

        <RouteCustom isPrivado idPermiso="25" exact path={`${BASEURL}/medidas`} component={Medidas} />

        <RouteCustom isPrivado idPermiso="10" exact path={`${BASEURL}/facturas`} component={Facturas} />
        <RouteCustom isPrivado idPermiso="11" exact path={`${BASEURL}/facturas/:id?`} component={FacturasView} />
        <RouteCustom isPrivado idPermiso="8" exact path={`${BASEURL}/proveedores`} component={Proveedores} />
        <Route exact path={BASEURL+'/'} component={LoginForm} />
        <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default AllRutas
