import { Button, TextField,InputAdornment,Icon,IconButton,Tooltip } from "@material-ui/core"
import Tablas from "../../../Componentes/Tablas"
import { StylesGenerales } from "../../../Styles/StylesGenerales"
import { useCaja } from "./CajaContext"


const ListaCajas = () => {
    const classes = StylesGenerales()

    const {setOpenDialog,lista, cargando,setOpenModalAsignar,setCajaAsignacionID,setCajaAsignacionNombre} = useCaja()
   

    const columnas = [
          {
            field: "id_caja",
            headerName: "ID",
          },
          {
            field:"nombre_caja",
            headerName:"Nombre de caja"
          },
          {
            field: "habilitado_caja",
            headerName: "Habilitado",
            items: {
              0:"Deshabilitado",
              1:"Habilitado"
            },
            comparaItem:"habilitado_caja",
          },
          {
            field: "monto_caja",
            headerName: "Monto Efectivo",
            isNumber:true,
          },
    ]

    const asignarTrigger = (id,nombre)=>{
      setCajaAsignacionID(id);
      setCajaAsignacionNombre(nombre)
      setOpenModalAsignar(true)
    }

    const Acciones = ({id,extraprops})=> (
    <div className={classes.botones}>
      <Tooltip title="Asignar" arrow  >
      <IconButton onClick={()=>{asignarTrigger(id,extraprops); }} >
        <Icon color="primary" >badge</Icon>
      </IconButton>
      </Tooltip>
      <Tooltip title="Deshabilitar" arrow>
      <IconButton>
        <Icon color="secondary" >block</Icon>
      </IconButton>
      </Tooltip>
    </div>
    )
    const search = (
      <div className={classes.inputs}>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={()=>{}}>
                  <Icon>search</Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
          onKeyPress={e=>{if(e.key==='Enter'){ } } }
          onChange={(e) => {}}
          variant="outlined"
          label="Buscar"
        />
        <Button  color="primary" variant="outlined" size="large" 
          onClick={() => {setOpenDialog(true)}}
        >
          AGREGAR
          </Button>
      </div>
    );

  return (
    <>
      <Tablas 
      nombretabla="Cajas"
      icono="point_of_sale"
      bgicono="#303f9f"
      extraprops="nombre_caja"
      namecolumnID={`id_caja`}
      columnas={columnas}
      filas={lista}
      Acciones={Acciones}
      search={search}
      cargando={cargando}
      />
    </>
  )
}

export default ListaCajas
