import {makeStyles} from '@material-ui/core'

export const Styles = makeStyles((theme) => ({
    absolute: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(3),
    },
    inputs:{
      display:"flex",
      alignItems:"center",
      "& > *":{
        marginRight:"25px",
      }
    },
    siatras: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      marginTop: 10,
    },
    botones: {
      "& > *": {
        margin: theme.spacing(0, 1),
      },
    },
    inputbuscar: {
      display: "flex",
      marginBottom: "10px",
    },
    page:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        "& > *": {
            margin: theme.spacing(0, 1),
          },
    },
    textfield: {
      marginBottom: theme.spacing(3),
    },
  }));