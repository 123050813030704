import React, { createContext, useContext, useEffect, useState } from "react";
import swal from "sweetalert";
import { APICALLER } from "../../../Api/ApiCaller";
import { useLogin } from "../../../Contextos/LoginProvider";

const Context = createContext();

const MarcasProvider = ({ children }) => {
  const {token_user} = useLogin()
  const [lista, setLista] = useState([]);
  const [cargando,setCargando] = useState(true)
  const initial = {nombre_marca:"",}
  const [formulario,setFormulario] = useState(initial)
  const [openDialog,setOpenDialog]= useState(false)


  const enviarFormulario = async(e)=>{
    e.preventDefault()
    let msj = formulario.id_marca ? `Actualizado` : `Agregado` ;
    const table = "marcas";
    const res = formulario.id_marca ? await APICALLER.update({table,data:formulario,token:token_user,namecolumn:`id_marca`,equalTo:formulario.id_marca}) 
    : await APICALLER.insert({table,data:formulario, token: token_user})
    res.response==="ok" ? swal({icon:"success",text:`${msj} correctamente`}) : console.log(res)
    getLista()
    setOpenDialog(false)
    setFormulario(initial)
  }

  const getLista = async()=>{
    let res = await APICALLER.listar({table:`marcas`})
    res.response==="ok" ? setLista(res.results) : console.log(res);
    setCargando(false)
  }
  useEffect(() => {
    getLista()
  }, []);

  return (
    <Context.Provider value={{ lista, setLista,cargando,setCargando,openDialog,setOpenDialog,formulario,setFormulario,enviarFormulario }}>{children}</Context.Provider>
  );
};

export const useMarcas = () => {
  const { lista, setLista,cargando,setCargando,openDialog,setOpenDialog,formulario,setFormulario,enviarFormulario } = useContext(Context);
  return { lista, setLista,cargando,setCargando,openDialog,setOpenDialog,formulario,setFormulario,enviarFormulario };
};

export default MarcasProvider;
