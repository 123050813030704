import { createContext,useState,useCallback,useEffect, useContext } from 'react'
import { APICALLER } from '../../../Api/ApiCaller'


const Contexto = createContext()

const RegistroFacturasProvider = ({children}) => {

    const [openModal,setOpenModal] = useState(false)
    const [listaFacturas,setListaFacturas] = useState([])
    const getFacturas = useCallback(async()=>{
        let fac = await APICALLER.listartablas({tables:`empresas,empresa_facturas,cajas`,on:`id_empresa_empresa,id_empresa,id_caja,id_caja_factura`})
        fac.response==="ok" ?  fac.founds>0 && setListaFacturas(fac.results) : console.log(fac)
      },[])

    useEffect(()=>{
      getFacturas()
    },[getFacturas])


  return (
    <Contexto.Provider value={{getFacturas,listaFacturas,setListaFacturas,openModal,setOpenModal}}>
        {children}
    </Contexto.Provider>
  )
}

export const useRegistroFacturas = ()=>{
  const {getFacturas,listaFacturas,openModal,setOpenModal} = useContext(Contexto)
  return {getFacturas,listaFacturas,openModal,setOpenModal}
}

export default RegistroFacturasProvider
