
import { Button, IconButton, Tooltip, Icon} from '@material-ui/core';
import Motion from '../../../Componentes/Motion';
import Tablas from '../../../Componentes/Tablas';
import { StylesGenerales } from '../../../Styles/StylesGenerales';
import { useProductosApartados } from './ProductosApartadosProvider';

const ProductosApartadosLista = () => {
    const classes = StylesGenerales()
    const {cargando,lista} = useProductosApartados()

    const FilterData = [...lista]


    const columns = [
        {
          field: "id_producto",
          headerName: "ID",
        },
        {
            field: "codigo_producto",
            headerName: "Código",
        },
        {
            field: "nombre_producto",
            headerName: "Producto",
        },
        {
            field:"nombre_cliente",
            headerName:"Cliente"
        },
        {
            field:"cantidad_apartado",
            headerName:"Cantidad"
        }
        
    ];



    const Acciones = () =>(<div className={classes.botones}>
        <Tooltip title="Devolver al stock" arrow >
        <IconButton>
            <Icon color="primary">swap_horiz</Icon>
        </IconButton>
        </Tooltip>
        </div>
    )

    const Search = (<div>
        <Button variant="outlined" color="primary" >Apartar producto</Button>
    </div>)

  return (
    <Motion>
      <Tablas
        nombretabla="Productos Apartados"
        subtitle="Módulo de apartado de stock de los dépositos"
        icono="save"
        bgicono="#3f51b5"
        namecolumnID="id_producto"
        columnas={columns}
        filas={FilterData}
        Acciones={Acciones}
        cargando={cargando}
        search={Search}
      />
    </Motion>
  )
}

export default ProductosApartadosLista
