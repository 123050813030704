import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,FormHelperText, LinearProgress,
  Icon,IconButton
} from "@material-ui/core";

import { useCaja } from "./CajaContext";

import { useLocation } from "react-router-dom";
import {  useEffect, useCallback,useState } from "react";
import { BASEURL, history } from "../../../VariablesGlobales/globales";
import { APICALLER } from "../../../Api/ApiCaller";
import { useLogin } from "../../../Contextos/LoginProvider";
import { StylesGenerales } from "../../../Styles/StylesGenerales";
import swal from "sweetalert";
import InputNumberFormat from "../../../Componentes/InputNumberFormat";




const DialogApertura = () => {
  const classes = StylesGenerales();
  const { token_user } = useLogin();
  const location = useLocation();
  const query = location.search && new URLSearchParams(location.search);
  
  const [idUser,setIdUser] = useState("")
  const [listaUsers,setListaUsers] = useState([])

  //const [monedas,setMonedas]=useState([])
  
  const [error,setError] = useState(false)
  const [errorTxt,setErrorTxt] = useState("")
  const [cargando,setCargando] = useState(true)
  const { openDialog, setOpenDialog,getlista } = useCaja();


  
  const [montoInicial,setMontoInicial] = useState(0);

  const [nombreCaja,setNombreCaja] = useState("")

  const cerrar = ()=>{ setOpenDialog(false)}

  const apertura_caja = async () => {
    let valor_inicial = parseFloat(montoInicial);
    if(valor_inicial>=0 && nombreCaja!=="" && idUser!==""){
      setCargando(true)
      setError(false)
      setErrorTxt("")
      let f = new Date();
      let fecha = `${f.getFullYear()}-${f.getMonth()+1}-${f.getDate()} ${f.getHours()}:${f.getMinutes()}`;
      let datas = {
        nombre_caja: nombreCaja,
        monto_inicial:valor_inicial,
        monto_caja:valor_inicial,
        fecha_creacion: fecha,
        fecha_apertura:fecha,
        habilitado_caja:1,
        estado_caja:1, // 1 es abierto 0 es cerrado
      }
      let cajanew = await APICALLER.insert({table:`cajas`,data:datas,token:token_user})
      if(cajanew.response==="ok") { 
        let ID_CAJA = cajanew.results[0].last_id 
        let caja_user = await APICALLER.insert({table:`cajas_users`,data:{id_caja_caja:ID_CAJA,id_user_caja:idUser},token:token_user})
        caja_user.response!=="ok" && console.log(caja_user);
        let dataregistro = {
          id_caja_movimiento: ID_CAJA,
          id_user_caja: idUser,
          id_tipo_registro: 3, // 3 es apertura de caja y es un ingreso
          monto_movimiento: valor_inicial,
          monto_sin_efectivo:0,
          detalles_movimiento:"Habilitación y apertura de caja",
          fecha_movimiento:fecha,
        }
        
        let res =  await APICALLER.insert({table:`cajas_movimientos`,data:dataregistro,token:token_user})
        if(res.response==="ok") { 
          swal({timer:1500,icon:'success',text:'Agregado correctamente'}); 
          setOpenDialog(false); 
          setIdUser(""); 
          setNombreCaja(""); 
          setMontoInicial(0)
          //valor_inicial_input.current.value=0
          getlista(); 
          } else {console.log(res)}
        
      } else{ console.log(cajanew) }

      


    }
    else{
      setError(true)
      setErrorTxt("No puede ser menor a 0")
    }
    setCargando(false)
  };

  const cerrarDialog = () => {
    setOpenDialog(false);
    if (query && query.get("dialog") === "abrir") {
      history.push(`${BASEURL}/cajas`);
    } 
  };


  const getMonedas = useCallback(async()=>{
    //const res = await APICALLER.listar({table:`monedas`})
    //res.response==="ok" ? setMonedas(res.results) : console.log(res)
  },[])

  const getUsers = useCallback(async()=>{
    const res = await APICALLER.listausers({token:token_user})
    res.response==="ok" ? setListaUsers(res.results) : console.log(res)
  },[token_user])

  useEffect(() => {
    getUsers()
    getMonedas()
    setCargando(false)
  },[getMonedas,getUsers]);

  return (
    <>
      <Dialog open={openDialog} onClose={cerrar} fullWidth>
        <DialogTitle>
          <div className={classes.titulodialog}>
            HABILITAR CAJA
            <IconButton onClick={cerrar}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {
            cargando && <LinearProgress />
          }
          <TextField className={classes.textfield} 
            onChange={e=> setNombreCaja(e.target.value)}
            value={nombreCaja}
            variant="outlined"
            fullWidth
            autoFocus
            label="Nombre de caja"
            autoComplete="off"
          />
          <TextField
          className={classes.textfield}
          fullWidth
          label="Monto inicial"
          value={montoInicial}
          InputProps={{
            inputComponent: InputNumberFormat,inputProps: { min: 0 }
          }}
          onChange={(e) => {
            setMontoInicial(
              e.target.value === "" ? 0 : parseFloat(e.target.value)
            );
          }}
          error={error}
          helperText={errorTxt}
          variant="outlined"
        />
          <FormControl fullWidth>
            <InputLabel>Asignar a un usuario</InputLabel>
            <Select
              onChange={e=> setIdUser( e.target.value)}
              value={idUser}
              fullWidth
            >
              {
                listaUsers.map(d=>(
                  <MenuItem key={d.id_user} value={d.id_user} >{d.nombre_user}</MenuItem>
                ))
              }
            </Select>
            <FormHelperText>{idUser==="" && 'Asigne un usuario'}</FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined"
            color="primary"
            onClick={() => apertura_caja()}
            disabled={cargando}
          >
            Abrir caja
          </Button>
          <Button variant="outlined" onClick={() => cerrarDialog()}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogApertura;
