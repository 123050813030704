import {Component} from 'react'
import { Funciones } from '../../../Funciones/Funciones';

class FacturasViewTicket extends Component {

    redondearConComas(nro,valorMoneda){
        return ( (Math.round((parseFloat(nro)/ parseFloat( valorMoneda) )*100))/100 ).toLocaleString('de-DE');
    }
    render() {
        const {calculosIva, infoFactura, infoEmpresa, itemsFactura} = this.props
        const {iva5,iva10,exentas} = calculosIva;
        const width= "70mm";
      return (

        <table border="0" style={{width:`${width}`, borderCollapse:"collapse"}}  >
            <thead style={{fontSize:"11px",}}>
                <tr>
                    <td align="center" style={{fontSize:"15px",}} >{infoEmpresa.nombre_empresa}</td>
                </tr>
                <tr>
                    <td align="center"  > {infoEmpresa.propietario_empresa==="" ? `` : `De : ${infoEmpresa.propietario_empresa}`  }</td>
                </tr>
                <tr>
                    <td align="center">Ruc: {infoEmpresa.ruc_empresa}</td>
                </tr>
                <tr>
                    <td align="center">{infoEmpresa.direccion_empresa}</td>
                </tr>
                <tr>
                    <td align="center">Tel: {infoEmpresa.telefono_empresa}</td>
                </tr>
                <tr>
                    <td align="center">Timbrado nro: {infoEmpresa.timbrado_factura}</td>
                </tr>
                <tr>
                    <td align="center">Fecha Inicio: {infoEmpresa.inicio_timbrado}</td>
                </tr>
                <tr>
                    <td align="center">Fecha Fin: {infoEmpresa.fin_timbrado}</td>
                </tr>
                <tr>
                    <td align="center">FACTURA NRO: {infoEmpresa.nro_datos_factura}-{infoFactura.nro_factura}</td>
                </tr>
                <tr>
                    <td align="center">Moneda: {infoFactura.nombre_moneda}</td>
                </tr>
            </thead>
            <tbody>
                <tr><td><hr/></td></tr>
                <tr>
                    <td>
                        <table width="100%"  >
                            <tbody>
                                <tr style={{fontSize:"11px"}}>
                                    <td>CA</td>
                                    <td>DESCRIPCION</td>
                                    <td>PRE</td>
                                    <td>SUBT</td>
                                    <td>IVA</td>
                                </tr>
                                {
                                itemsFactura.map((item,i)=>(
                                    <tr key={i} style={{fontSize:"10px",textTransform:"lowercase"}} >
                                    <td valign="top" >{item.cantidad_producto.substr(0,3)}</td>
                                    <td valign="top">{item.nombre_producto}</td>
                                    <td valign="top">
                                    {( Math.round((item.precio_producto_factura)/(parseFloat(infoFactura.valor_moneda_factura))*100)/100 ).toLocaleString('de-DE')}
                                    </td>
                                    <td valign="top">
                                    {
                                    ( Math.round((item.precio_producto_factura*parseFloat(item.cantidad_producto))/(parseFloat(infoFactura.valor_moneda_factura))*100)/100 ).toLocaleString('de-DE')
                                    }
                                    </td>
                                    <td valign="top">{item.iva_producto}</td>
                                    </tr>
                                ))
                                }

                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr><td><hr /></td></tr>
                <tr>
                    <td>
                        <table width="100%" style={{fontSize:"11px",}} >
                            <tbody>
                            <tr>
                            <td align="left">SUBTOTALES</td>
                            <td align="left">LIQUIDACION</td>
                            <td align="left">IVA</td>
                            </tr>
                            <tr >
                            <td align="left">EXENTAS</td>
                            <td align="left">{this.redondearConComas(exentas,infoFactura.valor_moneda_factura)}</td>
                            <td align="left">0</td>
                            </tr>
                            <tr>
                            <td align="left">GRAVADAS 5%</td>
                            <td align="left">{ this.redondearConComas(iva5,infoFactura.valor_moneda_factura)}</td>
                            <td align="left">{ this.redondearConComas((iva5/21),infoFactura.valor_moneda_factura) }</td>
                            </tr>
                            <tr>
                            <td align="left">GRAVADAS 10%</td>
                            <td align="left">{ this.redondearConComas(iva10,infoFactura.valor_moneda_factura) }</td>
                            <td align="left">{ this.redondearConComas((iva10/11),infoFactura.valor_moneda_factura )  }</td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr><td><hr /></td></tr>
                <tr>
                    <td>
                        <table width="100%" style={{fontSize:"12px"}} >
                            <tbody>
                                <tr>
                                    <th>TOTAL: {this.redondearConComas(infoFactura.monto_total_factura,infoFactura.valor_moneda_factura)}</th>
                                </tr>
                                <tr>
                                    <th>SON: {Funciones.NumeroALetras((parseFloat(infoFactura.monto_total_factura)/parseFloat(infoFactura.valor_moneda_factura)), infoFactura.nombre_moneda )}</th>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr><td><hr /></td></tr>
            </tbody>
            <tfoot style={{fontSize:"11px"}} >
                <tr>
                    <td align="left" >CLIENTE: {infoFactura.nombre_cliente}</td>
                </tr>
                <tr>
                    <td align="left"  >DOC: {infoFactura.ruc_cliente}</td>
                </tr>
                <tr>
                    <td align="left">FECHA EMISION: {infoFactura.fecha_factura}</td>
                </tr>
                <tr>
                    <td align="left">FACTURA: {infoFactura.tipo_factura==="1" ? `CONTADO` : `CREDITO`}</td>
                </tr>
                <tr><td><hr /></td></tr>
                <tr>
                    <td align="center">Los datos impresos requieren un cuidado especial. Para ello evite exponer al calor y humedad en exceso, luz solar o lámparas</td>
                </tr>
                <tr>
                    <td align="center"> *{infoEmpresa.nombre_empresa} agradece su compra *</td>
                </tr>
            </tfoot>
        </table>
      );
    }
  }

  export default FacturasViewTicket