
import ProductosProvider from './ProductosProvider'
import ProductosLista from './ProductosLista'


const Productos = () => {
  return (
    <ProductosProvider>
      <ProductosLista />
    </ProductosProvider>
  )
}

export default Productos
