import {

  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Button,
} from "@material-ui/core";
import React, { useState } from "react";
import Tablas from "../../Componentes/Tablas";
import {Styles} from './Styles'
import { Funciones } from "../../Funciones/Funciones";
import { BASEURL } from "../../VariablesGlobales/globales";
import { useClientes } from "./ClientesProvider";
import { columns } from "./ClientesColumns";
import {Acciones} from './ClientesListaAcciones'

const ClientesLista = () => {
  const { lista, cargando,page, setPage,limite,buscarRegistro,countTotal } = useClientes();
  const classes = Styles()
  const [inputSearch, setInputSearch] = useState("");
  


  const FilterData = lista.filter(
    (item) =>
      item.nombre_cliente.toLowerCase().includes(inputSearch.toLowerCase()) ||
      item.ruc_cliente.toLowerCase().includes(inputSearch.toLowerCase())
  ); 

  const search = (
    <div className={classes.inputs}>
      {" "}
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={()=>{buscarRegistro(inputSearch)}}>
                <Icon>search</Icon>
              </IconButton>
            </InputAdornment>
          ),
        }}
        onKeyPress={e=>{if(e.key==='Enter'){buscarRegistro(inputSearch) } } }
        onChange={(e) => setInputSearch(e.target.value)}
        variant="outlined"
        label="Buscar"
      />
      <Button  color="primary" variant="outlined" size="large" 
        onClick={() => Funciones.goto(`${BASEURL}/clientes/new/`)}
      >
        AGREGAR
        </Button>
    </div>
  );

  const siguiente = ()=>{
    let i = parseInt(page)+parseInt(limite);
    setPage(i);
  }
  const atras = ()=>{
    if(page>0){
      let i = parseInt(page)-parseInt(limite);
      setPage(i);
    }
  }

  return (
    <>
      <Tablas
        nombretabla="Clientes"
        icono="people"
        bgicono="#3f51b5"
        namecolumnID={`id_cliente`}
        columnas={columns}
        filas={FilterData}
        Acciones={Acciones}
        extraprops={"nombre_cliente"}
        search={search}
        cargando={cargando}
      />
      <div className={classes.page}>
      {page>0 &&(<Button 
        onClick={atras}
        variant="outlined" >
          Atrás
        </Button>)}
        {
          ((countTotal>page) && ((page+limite)<countTotal) ) &&      
        <Button variant="outlined" 
        onClick={siguiente} >
          Siguiente
          </Button>
        }
      </div>
    </>
  );
};

export default ClientesLista;
