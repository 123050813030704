import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  Avatar,
  makeStyles,
  Typography,
  Grid,
} from "@material-ui/core";
import React from "react";
import { Funciones } from "../Funciones/Funciones";
import TablasSkeleton from "./TablasSkeleton";

const estilo = makeStyles((theme) => ({
  tableContainer: {
    position: "relative",
    overflowX: "unset",
    marginBottom: 25,
    padding: "2px",
  },
  tableheader: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  head: {
    marginBottom: "15px",
  },
  tablecell: {
    position: "sticky",
    top: "63px",
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  },
  tablelist: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      border: "1px solid silver",
      margin: "0 0 10px 0",
    },
  },
  tableitem: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  columname: {
    display: "none",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  bold: {
    fontWeight: "bold",
  },
}));

const Tablas = ({
  nombretabla,
  subtitle,
  columnas,
  filas,
  icono,
  bgicono,
  Acciones,
  extraprops,
  namecolumnID,
  extra,
  search,
  cargando,
}) => {
  const classes = estilo();

  if (!columnas) {
    return <h1>Falta el props "columnas"</h1>;
  }
  if (!Acciones) {
    return <h1>Falta el props "Acciones"</h1>;
  }
  if (!filas) {
    return <h1>Falta el props "Filas"</h1>;
  }

  return (
    <>
      <Grid
        container
        spacing={1}
        justify="space-evenly"
        className={classes.head}
      >
        <Grid item xs={12} md={1}>
          {icono && (
            <Avatar
              variant="rounded"
              style={{
                padding: 8,
                backgroundColor: bgicono ? bgicono : `inherit`,
              }}
            >
              <Icon fontSize="large">{icono}</Icon>
            </Avatar>
          )}
        </Grid>
        <Grid item  xs={12} md={11}>
          <Typography variant="button">{nombretabla}</Typography>
          <br />
          <Typography variant="caption">{subtitle}</Typography>
        </Grid>

        <Grid xs={12} item sm={12} md={6}>
          {extra && extra}
        </Grid>
        <Grid xs={12} item>
          {search && search}
        </Grid>
      </Grid>

      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead className={classes.tableheader}>
            <TableRow>
              {columnas.map((column, id) => (
                <TableCell key={id} className={classes.tablecell}>
                  <Typography variant="overline" className={classes.bold}>
                    {column.headerName}
                  </Typography>
                </TableCell>
              ))}
              <TableCell className={classes.tablecell}>
                <Typography variant="overline" className={classes.bold}>
                  Opciones
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          {cargando ? (
            <TablasSkeleton columnas={columnas} />
          ) : filas.length > 0 ? (
            <TableBody>
              {filas.map((fila) => (
                <TableRow
                  role="checkbox"
                  hover
                  key={fila[namecolumnID]}
                  className={classes.tablelist}
                >
                  {columnas.map((column, cid) => (
                    <TableCell className={classes.tableitem} key={cid}>
                      <span className={classes.columname}>
                        {column.headerName}:{" "}
                      </span>
                      <span>
                        {column.isNumber
                          ? Funciones.numberWithCommas(fila[column.field])
                          : column.extra
                          ? column.extra
                          : column.items
                          ? column.items[fila[column.comparaItem]]
                          : fila[column.field]}
                        {column.extraitem ? column.extraitem : ``}
                      </span>
                    </TableCell>
                  ))}
                  <TableCell>
                    <Acciones
                      filaProps={fila}
                      id={fila[namecolumnID]}
                      extraprops={fila[extraprops]}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={columnas.length + 1} align="center">
                  No hay registros
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default Tablas;
