export const columns = [
    {
      field: "id_user",
      headerName: "ID",
    },
    {
      field: "nombre_user",
      headerName: "Nombre",
    },
    {
      field: "username_user",
      headerName: "Username",
    },
    {
      field: "rol_user",
      headerName: "Rol",
      items:
        {
          1: "Administrador",
          2: "Gerente",
          3: "Caja",
          4: "Vendedor",
          5: "Entrega"
        }
      ,
      comparaItem: "rol_user",
    },
  ];