import React from 'react'
import ClientesLista from './ClientesLista'

const ClientesMain = () => {
  return (
    <>
     <ClientesLista /> 
    </>
  )
}

export default ClientesMain
