import CajaProvider from "./CajaContext"

import ListaCajas from './ListaCajas';
import DialogApertura from './DialogApertura'
import DialogAsignar from "./DialogAsignar";

const Caja = () => {

  return (
    <CajaProvider>
        <DialogApertura />
        <DialogAsignar />
        <ListaCajas />
    </CajaProvider>
  )
}

export default Caja
