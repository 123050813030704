import {makeStyles,} from "@material-ui/core";


export const Styles = makeStyles((theme) => ({

    page:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        "& > *": {
            margin: theme.spacing(0, 1),
          },
      },
      botones:{
        "& > *":{
          margin: theme.spacing(0,1)
        }
      },
      inputs:{
        display:"flex",
        alignItems:"center",
        "& > *":{
          marginRight:"25px",
        }
      },
}))