import {

    TableRow,
    TableCell,

    TableBody, CircularProgress
  } from "@material-ui/core";
//import {Skeleton} from '@material-ui/lab'
import React from 'react'

const TablasSkeleton = ({columnas}) => {

    if(!columnas){
        return <h1>Cargando...</h1>
    }

  return (

          <TableBody>
               <TableRow>
                   <TableCell colSpan={columnas.length+1} align="center">
                       <CircularProgress />
                   </TableCell>
               </TableRow>
          </TableBody>

  )
}

export default TablasSkeleton
