import React from 'react'
import { Funciones } from "../../../Funciones/Funciones";
import { Typography,Grid, TextField, } from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import { StylesAperturaCierre } from './StylesAperturaCierre';

const CierreResumen = ({datos,usuarios,arqueo}) => {
  const classes = StylesAperturaCierre()
  const moneda = "Gs.";

  const FALTANTE = (datos.saldoFinal - arqueo)>0 ? datos.saldoFinal - arqueo : 0  ;
  const SOBRANTE = (arqueo - datos.saldoFinal)> 0 ? arqueo - datos.saldoFinal : 0 ;

  

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Alert icon={false} severity="warning" >
          <Typography variant="button" >Monto inicial : {Funciones.numberWithCommas(datos.montoInicial)} {moneda}</Typography>
        </Alert>
      </Grid>
      <Grid item xs={6}>
        <Alert icon={false} severity="warning" >
          <Typography variant="button" >FECHA DE APERTURA: {datos.fechaApertura} </Typography>
        </Alert>
      </Grid>
      <Grid item xs={12} sm={6} >
        <Alert icon={false} severity="info" >
        <Typography variant="button" >Nombre caja: </Typography> <Typography variant="overline" > {datos.nombreCaja} </Typography>
        </Alert>
      </Grid>
      <Grid item xs={12} sm={6}>
      <Alert icon={false} severity="info" >
        <Typography variant="button" >Responsables: </Typography>
          {
            usuarios.map((item,index)=>(
              <Typography variant="overline" key={index} > {item.nombre_user} {'  '}</Typography>
            ))
          }
        </Alert>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Grid container spacing={1} className={classes.boxIngresos} >
            <Grid item xs={12}>
              <Alert  icon={false}>
                <Typography variant="button" >INGRESOS </Typography>
              </Alert>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="overline" >Ventas al contado:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline">
              { Funciones.numberWithCommas(datos.ventasContado)} {moneda}
              </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="overline" >Depósitos en caja:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline">
              { Funciones.numberWithCommas(datos.depositos)} {moneda}
              </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="overline" >Cobros de créditos:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline">
                { Funciones.numberWithCommas(datos.cobrosVentasCredito)} {moneda}
              </Typography>
            </Grid>

          
        </Grid>
      </Grid>



      <Grid item xs={12} sm={6}>
        <Grid container spacing={1} className={classes.boxEgresos}  >
          
            <Grid item xs={12}>
              <Alert severity="error" icon={false}>
                <Typography variant="button" >EGRESOS </Typography>
              </Alert>
            </Grid>

            <Grid item xs={6}>
                <Typography variant="overline" >Compras contado:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline">
              { Funciones.numberWithCommas(datos.comprasContado)} {moneda}
              </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="overline" >Retiros de caja:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline">
              { Funciones.numberWithCommas(datos.retiros)} {moneda}
              </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="overline" >Pagos de créditos:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline">
                { Funciones.numberWithCommas(datos.pagoComprasCredito)} {moneda}
              </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="overline" >Otros pagos:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline">
                { Funciones.numberWithCommas(datos.otrosPagos)} {moneda}
              </Typography>
            </Grid>
          
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Alert severity="error" variant="outlined" icon={false}>
          <Typography variant="button">FALTANTE: {Funciones.numberWithCommas(FALTANTE)} {moneda}</Typography>
        </Alert>
      </Grid>
      <Grid item xs={6}>
        <Alert severity="info" variant="outlined" icon={false}>
          <Typography variant="button">SOBRANTE: {Funciones.numberWithCommas(SOBRANTE)} {moneda}</Typography>
        </Alert>
      </Grid>
      {
        (SOBRANTE>0 || FALTANTE>0) &&
        <Grid item xs={12}>
          <TextField variant="outlined" fullWidth label="Declarar sobrante o faltante" />
        </Grid>
      }
      
      <Grid item xs={12} md={6}>
        <Alert severity="success" variant="outlined" icon={false}>
          <Typography variant="button">SALDO FINAL: {Funciones.numberWithCommas(datos.saldoFinal)} {moneda}</Typography>
        </Alert>
      </Grid>
      <Grid item xs={12} md={6}>
        <Alert severity="warning" variant="outlined" icon={false}>
          <Typography variant="button">ARQUEO DE CAJA: {Funciones.numberWithCommas(arqueo)} {moneda}</Typography>
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <Alert severity="info" icon={false}>
          <Typography variant="button">VENTAS SIN EFECTIVO: { Funciones.numberWithCommas(datos.montoSinEfectivo)} {moneda}</Typography>
        </Alert>
      </Grid>

    </Grid>
  )
}

export default CierreResumen
