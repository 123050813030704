
import { StylesGenerales } from '../../Styles/StylesGenerales'
import { Container, Typography,Icon,Fab} from '@material-ui/core'
import { Funciones } from '../../Funciones/Funciones'



const NotAutorized = () => {
  const classes = StylesGenerales()
  return (
    <Container className={classes.centrar}>
        <Icon className={classes.iconWarning}>warning</Icon>
        <Typography variant="body1" >No deberías de estar aquí</Typography>
        <Fab variant="extended" onClick={()=>{Funciones.goto(`/`)}} >
          <Icon>home</Icon>
          VOLVER A CASA
        </Fab>
        <Fab variant="extended" onClick={()=>{Funciones.goto(`/salir`)}} >
          <Icon>logout</Icon>
          SALIR
        </Fab>
    </Container>
  )
}

export default NotAutorized
