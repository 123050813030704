
import Tablas from "../../../Componentes/Tablas";
import { Icon, Fab,  Tooltip, Button, TextField, InputAdornment, IconButton } from "@material-ui/core";


import { Styles } from "./Styles";
import { useProductos } from "./ProductosProvider";
import Motion from "../../../Componentes/Motion";
import ProductosListaPager from "./ProductosListaPager";
import { Funciones } from "../../../Funciones/Funciones";


const ProductosLista = () => {
  
  const {inputSearch,setInputSearch,cargando,lista,buscarRegistro,
    borrarRegistro} = useProductos()
  const classes = Styles();

  const columns = [

    {
      field: "codigo_producto",
      headerName: "Codigo",
    },
    {
      field: "nombre_producto",
      headerName: "Nombre",
    },
    {
      field: "nombre_categoria",
      headerName: "Categoria",
    },
    {
      field: "precio_producto",
      headerName: "Precio",
      isNumber:true,
    },
    {
      field: "iva_producto",
      headerName: "IVA",
      extraitem:"%"
    },
    {
      field:"stock_producto",
      headerName:"Stock",
      isNumber:true,
    }
  ];


  const Acciones = ({id,extraprops}) => (
    <div className={classes.botones}>
      <Fab variant="round" color="primary" onClick={()=> Funciones.goto(`/productos/new/${id}`)}  size="small">
        <Icon>edit</Icon>
      </Fab>
      <Fab variant="round" color="secondary" onClick={()=>{borrarRegistro(id,extraprops)}} size="small">
        <Icon>delete</Icon>
      </Fab>
    </div>
  );

    
    const search = (
      <div className={classes.inputs}>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={()=>{buscarRegistro()}}>
                  <Icon>search</Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
          onKeyPress={e=>{if(e.key==='Enter'){buscarRegistro() } } }
          onChange={(e) => setInputSearch(e.target.value)}
          variant="outlined"
          label="Buscar"
        />
        <Tooltip title="AGREGAR NUEVO" arrow >
        <Button  color="primary" variant="outlined" size="large" 
          onClick={()=> Funciones.goto(`/productos/new`)}
        >
          AGREGAR
          </Button>
          </Tooltip>
      </div>
    );

    const FilterData =  lista.filter(
      (item) => item.nombre_producto.toLowerCase().includes(inputSearch.toLowerCase()) 
      || item.codigo_producto.toLowerCase().includes(inputSearch.toLowerCase())
    );

  return (
    <>
      <Motion>
        <Tablas
          nombretabla="Productos"
          icono="inventory"
          bgicono="#3f51b5"
          namecolumnID="id_producto"
          columnas={columns}
          filas={FilterData}
          Acciones={Acciones}
          extraprops={"nombre_producto"}
          search={search}
          cargando={cargando}
        />

        <ProductosListaPager />

      </Motion>

      <Tooltip title="AGREGAR NUEVO" arrow >
        <Fab
          color="primary"
          variant="extended"
          className={classes.absolute}
          onClick={()=>{Funciones.goto(`/productos/new`)}}
        >
          <Icon>add</Icon>
          Agregar
        </Fab>
      </Tooltip>

      
    </>
  );
};

export default ProductosLista;
