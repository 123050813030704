import React, { useEffect } from 'react'
import { Redirect } from 'react-router'
import { useLogin } from '../../Contextos/LoginProvider'
import { BASEURL } from '../../VariablesGlobales/globales'

const Salir = () => {

    const {DesLoguearse} = useLogin()

    useEffect(()=>{
        DesLoguearse();
    })

  return (
    <Redirect to={BASEURL+`/login`} />
  )
}

export default Salir
