import {makeStyles} from '@material-ui/core'


export const StylesAperturaCierre = makeStyles((theme) => ({
    boxIngresos:{
        border:"1px solid #edf7ed",
        borderRadius:"5px",
    },
    boxEgresos:{
        border:"1px solid #fdecea",
        borderRadius:"5px",
    },
    linear:{
        marginBottom: theme.spacing(1),
    }
}))

