import { Dialog, DialogContent, TextField, Button, DialogActions, DialogTitle } from '@material-ui/core'
import { useCompras } from './ComprasProvider'
import { APICALLER } from "../../../Api/ApiCaller";
import { Autocomplete } from "@material-ui/lab";
import {useState} from 'react'

const DialogSearchProduct = () => {


    const {openDialogSearch,setOpenDialogSearch,InsertarProductoTabla} = useCompras()
    const [listaBuscaProducto, setListaBuscaProducto] = useState([])
    
    // buscador con con input text field
  const buscarProductos = async (e) => {
    let txt = e.target.value;
    let config = {
      tables: "productos",
      keywords: txt,
      like: `nombre_producto`,
      linkTo:`tipo_producto`,
      equalTo:`1`,
      fields:"codigo_producto,nombre_producto,tipo_producto,stock_producto",
    };
    let res = await APICALLER.search(config);
    
    setListaBuscaProducto(res.results);
  };


  const insertaProducto = (e, value) => {
    //let txtcodigo = value.codigo_producto;
    //inputBuscaCodigo.current.value = txtcodigo;
    cerrar()
    InsertarProductoTabla(value)
  };

    const cerrar = ()=>{
        setOpenDialogSearch(false)
    }

  return (
    <Dialog fullWidth open={openDialogSearch} onClose={cerrar} >    
        <DialogTitle>
            Buscar producto
        </DialogTitle>
        <DialogContent dividers>
        <Autocomplete
          loadingText="Cargando..."
          noOptionsText="No hay productos con ese nombre"
          onChange={insertaProducto}
          disableClearable
          getOptionSelected={(option, value) =>
            option.codigo_producto === value.codigo_producto
          }
          options={listaBuscaProducto}
          getOptionLabel={(option) => option.nombre_producto}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus
              inputRef={()=>{}}
              onChange={buscarProductos}
              fullWidth
              placeholder="Escriba el nombre del producto"
              variant="outlined"
            />
          )}
        />
        </DialogContent>
        <DialogActions>
        <Button onClick={cerrar} variant="outlined" >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogSearchProduct
