import React from 'react'
import UsuariosMain from './UsuariosMain'
import UsuariosProvider from './UsuariosProvider'

const Usuarios = () => {
  return (
    <UsuariosProvider>
      <UsuariosMain />
    </UsuariosProvider>
  )
}

export default Usuarios
