import Axios from "axios";
import { APIURL } from "../VariablesGlobales/globales";

export const APICALLER = {
  loguearse: async (data) => {
    let formData = new FormData();
    formData.append("username_user", data.username_user);
    formData.append("password_user", data.password_user);
    try {
      const res = await Axios({
        url: `${APIURL}?method=login`,
        method: "POST",
        data: formData,
      });
      return await res.data;
    } catch (error) {
      const err = [{ results: `error`, response: `error` }];
      return err;
    }
  },
  register: async ({ data }) => {
    let datos = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      datos.append(key, value);
    });

    const res = await Axios({
      url: `${APIURL}?method=register`,
      method: "POST",
      data: datos,
    });
    return await res.data;
  },
  verifytoken: async (token_user) => {
    let formData = new FormData();
    formData.append("token_user", token_user);
    const res = await Axios({
      url: `${APIURL}?method=verifytoken`,
      method: "POST",
      data: formData,
    });
    return await res.data;
  },
  updateuser: async ({ token, data }) => {
    try {
      let datos = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        datos.append(key, value);
      });
      const res = await Axios({
        url: `${APIURL}?method=updateuser&token=${token}`,
        method: "POST",
        data: datos,
      });
      return await res.data;
    } catch (error) {
      if (Axios.isCancel(error)) {
      } else {
        throw error;
      }
    }
  },

  confirmPassword: async ({ token, password_user }) => {
    let formData = new FormData();
    formData.append("password_user", password_user);
    try {
      let configURL = `${APIURL}?method=confirmpassword&token=${token}`;
      const res = await Axios({
        url: configURL,
        method: "POST",
        data: formData,
      });
      return await res.data;
    } catch (error) {
      if (Axios.isCancel(error)) {
      } else {
        throw error;
      }
    }
  },

  count: async ({ tables, field, condicion = "1" }) => {
    let configURL = `${APIURL}?countables=${tables}&field=${field}&condicion=${condicion}`;
    try {
      const res = await Axios({
        url: configURL,
        method: "GET",
      });
      return await res.data;
    } catch (error) {
      if (Axios.isCancel(error)) {
      } else {
        throw error;
      }
    }
  },

  listar: async ({
    table,
    fields = "*",
    linkTo = "",
    equalTo = "",
    orderBy = "",
    orderMode = "",
    startAt = "",
    endAt = "",
    expresion = "=",
  }) => {
    let configURL = `${APIURL}?fields=${fields}&table=${table}&orderBy=${orderBy}&orderMode=${orderMode}&startAt=${startAt}&endAt=${endAt}&linkTo=${linkTo}&equalTo=${equalTo}&expresion=${expresion}`;
    //console.log(configURL)
    try {
      const res = await Axios({
        url: configURL,
        method: "GET",
      });
      return await res.data;
    } catch (error) {
      if (Axios.isCancel(error)) {
      } else {
        throw error;
      }
    }
  },
  listarbetween: async ({
    tables,
    between,
    valores,
    on = "",
    fields = "*",
    orderBy = "",
    orderMode = "",
    startAt = "",
    endAt = "",
    linkTo = "",
    equalTo = "",
    expresion = " = ",
  }) => {
    let url = `?tablesbetween=${tables}&on=${on}&between=${between}&valores=${valores}&fields=${fields}&startAt=${startAt}&endAt=${endAt}&orderMode=${orderMode}&orderBy=${orderBy}&linkTo=${linkTo}&equalTo=${equalTo}&expresion=${expresion}`;
    try {
      const res = await Axios({
        url: `${APIURL}${url}`,
        method: "GET",
      });
      return await res.data;
    } catch (error) {
      const err = [{ response: `error`, message: error }];
      return err;
    }
  },
  listartablas: async ({
    tables,
    on,
    fields = "*",
    orderBy = "",
    orderMode = "",
    startAt = "",
    endAt = "",
    linkTo = "",
    equalTo = "",
    expresion = " = ",
  }) => {
    let configURL = `?tables=${tables}&on=${on}&startAt=${startAt}&endAt=${endAt}&fields=${fields}&orderMode=${orderMode}&orderBy=${orderBy}&linkTo=${linkTo}&equalTo=${equalTo}&expresion=${expresion}`;
    try {
      const res = await Axios({
        url: `${APIURL}${configURL}`,
        method: "GET",
      });
      return await res.data;
    } catch (error) {
      const err = { response: "error", message: error };
      return err;
    }
  },
  listarcomparefields: async ({
    tables,
    fields,
    fieldscompare,
    orderBy = "",
    orderMode = "",
    startAt = "",
    endAt = "",
    linkTo = "",
    equalTo = "",
    expresion = "=",
  }) => {
    let configURL = `?tablescomparefields=${tables}&fieldscompare=${fieldscompare}&startAt=${startAt}&endAt=${endAt}&fields=${fields}&orderMode=${orderMode}&orderBy=${orderBy}&linkTo=${linkTo}&equalTo=${equalTo}&expresion=${expresion}`;

    try {
      const res = await Axios({
        url: `${APIURL}${configURL}`,
        method: "GET",
      });
      return await res.data;
    } catch (error) {
      const err = [{ response: `error`, message: error }];
      return err;
    }
  },
  insert: async ({ table, data, token }) => {
    let datos = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      datos.append(key, value);
    });

    datos.append("table", table);
    let configURL = `${APIURL}?token=${token}`;
    const res = await Axios({
      method: "POST",
      url: configURL,
      data: datos,
    });
    return res.data;
  },

  update: async ({ table, data, token, namecolumn, equalTo }) => {
    let formBody = [];
    for (var propiedad in data) {
      var encodedKey = encodeURIComponent(propiedad);
      var encodedValue = encodeURIComponent(data[propiedad]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    let configURL = `${APIURL}?table=${table}&token=${token}&namecolumn=${namecolumn}&equalTo=${equalTo}`;

    let res = await Axios({
      method: "PUT",
      url: configURL,
      data: formBody,
    });
    return await res.data;
  },

  delete: async ({ table, namecolumn, equalTo, token }) => {
    let formBody = [];

    var encodedKey = encodeURIComponent(`namecolumn`);
    var encodedValue = encodeURIComponent(namecolumn);
    formBody.push(encodedKey + "=" + encodedValue);
    encodedKey = encodeURIComponent(`equalTo`);
    encodedValue = encodeURIComponent(equalTo);
    formBody.push(encodedKey + "=" + encodedValue);
    formBody = formBody.join("&");
    let configURL = `${APIURL}?table=${table}&token=${token}`;
    const res = await Axios({
      method: "DELETE",
      url: configURL,
      data: formBody,
    });
    return await res.data;
  },
  search: async ({
    tables,
    fields = "*",
    like,
    keywords,
    startAt = "",
    endAt = "",
    on = "",
    linkTo = "",
    equalTo = "",
  }) => {
    let configURL = `${APIURL}?search=${tables}&fields=${fields}&like=${like}&keywords=${keywords}&startAt=${startAt}&endAt=${endAt}&on=${on}&linkTo=${linkTo}&equalTo=${equalTo}`;

    try {
      const res = await Axios({
        url: configURL,
        method: "GET",
      });
      return await res.data;
    } catch (error) {
      if (Axios.isCancel(error)) {
      } else {
        throw error;
      }
    }
  },
  listausers: async ({ token }) => {
    try {
      let configURL = `${APIURL}?tableprivate=users&token=${token}`;
      const res = await Axios({
        url: configURL,
        method: "GET",
      });
      return await res.data;
    } catch (error) {
      if (Axios.isCancel(error)) {
      } else {
        throw error;
      }
    }
  },
};
