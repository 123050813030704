export const columns = [
    {
      field: "id_cliente",
      headerName: "ID",
    },
    {
      field: "ruc_cliente",
      headerName: "Documento",
    },
    {
      field: "nombre_cliente",
      headerName: "Nombre",
    },
    
    { 
      field:"telefono_cliente",
      headerName:"Contacto",
    },
  ];
  