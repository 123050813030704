import React from 'react'
import VentasContext from './VentasProvider'
import VentasMain from './VentasMain'


const Ventas = () => {
  return (
    
    <VentasContext>
      <VentasMain />        
    </VentasContext>
  )
}

export default Ventas

