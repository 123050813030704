import React from "react";
import { Alert } from "@material-ui/lab";

import {
  Icon,
  IconButton,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Select,
  MenuItem, FormLabel,InputLabel
} from "@material-ui/core";
import { useUsuarios } from "./UsuariosProvider";

import { StylesGenerales } from "../../Styles/StylesGenerales";

const UsuarioFormEdit = () => {

const classes = StylesGenerales();

const {openDialogFormEdit,setOpenDialogFormEdit,formulario,setFormulario,setDialogConfirmacion,setPassword_confirm} = useUsuarios()


const handlerOnChange =  (e) => {
    const { name, value } = e.target;
    setFormulario({ ...formulario, [name]: value });
  };

const cerrar = ()=>{
  setOpenDialogFormEdit(false);
}

const enviarFormulario = (e)=>{
  e.preventDefault();
  setPassword_confirm("");
  setDialogConfirmacion(true);
}

  return (
    <Dialog fullWidth open={openDialogFormEdit} onClose={cerrar}>
      <form onSubmit={enviarFormulario}>
        <DialogTitle>
          <div className={classes.titulodialog}>
            <div>Editar usuario</div>
            <IconButton onClick={cerrar}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              className={classes.textfield}
              label="Nombre completo"
              autoComplete="off"
              name="nombre_user"
              onChange={handlerOnChange}
              value={formulario.nombre_user}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon color="disabled">account_circle</Icon>
                  </InputAdornment>
                ),
              }}
              fullWidth
              variant="outlined"
              error={false}
              helperText="Nombre del usuario"
            />
            <TextField
              className={classes.textfield}
              label="Login de usuario"
              autoComplete="off"
              name="username_user"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon color="disabled">person</Icon>
                  </InputAdornment>
                ),
              }}
              onChange={handlerOnChange}
              value={formulario.username_user}
              fullWidth
              variant="outlined"
              helperText="Username con el que se loguea"
            />
            <FormLabel>
              <InputLabel>
                Rol de usuario
              </InputLabel>
            <Select 
              value={formulario.rol_user}
              variant="outlined"
              label="Rol de usuario"
              fullWidth
              name="rol_user"
              onChange={handlerOnChange}
              className={classes.textfield}
            >
              <MenuItem value={1}>Administrador</MenuItem>
              <MenuItem value={2}>Gerente</MenuItem>
              <MenuItem value={3}>Cajero</MenuItem>
              <MenuItem value={4}>Vendedor</MenuItem>
              <MenuItem value={5}>Entrega</MenuItem>
            </Select>
            </FormLabel>
            <TextField
              className={classes.textfield}
              name="email_user"
              autoComplete="off"
              onChange={handlerOnChange}
              value={formulario.email_user}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon color="disabled">email</Icon>
                  </InputAdornment>
                ),
              }}
              label="Correo Electrónico"
              type="email"
              fullWidth
              variant="outlined"
            />
            <TextField
              className={classes.textfield}
              name="password_user"
              onChange={handlerOnChange}
              required
              value={formulario.password_user}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      {" "}
                      <Icon>visibility</Icon>{" "}
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon color="disabled">vpn_key</Icon>
                  </InputAdornment>
                ),
              }}
              label="Nueva contraseña"
              type="password"
              fullWidth
              variant="outlined"
            />
            

            <Alert severity="info">
              Recuerde elegir una contraseña segura. No ponga 123, fecha de
              nacimiento, o nombre de perro
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary" variant="outlined">
              Editar
            </Button>
            <Button
              onClick={()=>{cerrar()}}
              variant="outlined"
            >
              Cancelar
            </Button>
          </DialogActions>
        </form>
    </Dialog>
  );
};

export default UsuarioFormEdit;
