import { useEffect,useState,useCallback } from 'react'
import { APICALLER } from '../../../Api/ApiCaller'
import { StylesGenerales } from '../../../Styles/StylesGenerales'
import { useAperturaCierre } from './AperturaCierreProvider'
import { Dialog, DialogTitle,Icon,IconButton, DialogContent, DialogActions, Button, LinearProgress,} from '@material-ui/core'
import CierreResumen from './CierreResumen'
import swal from 'sweetalert'
import { useLogin } from '../../../Contextos/LoginProvider'


const DialogCierre = () => {
    const classes = StylesGenerales()
    const {token_user} = useLogin()
    const {dialogCierre,setDialogCierre, fecha_y_horario,getListaCajasAbiertas,todosDatosCaja,totalSumaMonedasArqueo,setOpenDialogArqueo,setTotalSumaMonedasArqueo} = useAperturaCierre()
    const [cargando,setCargando] = useState(true)
    

    const datosIniciales = {
      montoInicial: 0,
      saldoFinal:0,
      ventasContado:0,
      cobrosVentasCredito:0,
      depositos:0,
      retiros:0,
      comprasContado:0,
      pagoComprasCredito:0,
      otrosPagos:0,
      fechaApertura:"",
      montoSinEfectivo:0,
      nombreCaja:"",
    }

    const [datos,setDatos] = useState(datosIniciales);
    const [usuarios,setUsuarios] = useState([])
    //const [sumaArqueo,setSumaArqueo] = useState(0)
  
  
    const cerrar = ()=>{
      setDialogCierre(false)
      setDatos(datosIniciales)
      getListaCajasAbiertas()
    }




    const VolverArqueo = ()=>{
      setDialogCierre(false)
      setOpenDialogArqueo(true)
      setTotalSumaMonedasArqueo(0)
    }




    
    const PreguntarCierre = ()=>{
      swal({text:'Desea cerrar la caja?',buttons:['Cancelar','Cerrar caja']}).then(e =>{
        if(e){
          EfectuarCierre()
        }
      })





    }
    const EfectuarCierre = async()=>{
      setCargando(true)
      let data = {fecha_cierre:fecha_y_horario,estado_caja:"0"}
      let res = await APICALLER.update({table:'cajas',data,token:token_user,namecolumn:'id_caja',equalTo:todosDatosCaja.id_caja})
      res.response==="ok" ? (cerrar()) :  console.log(res)
      setCargando(false)
    }






    const getDatosCaja = useCallback(async()=>{
      if(dialogCierre){
        setCargando(true)
        let ID_CAJA = todosDatosCaja.id_caja

        let userslist = await APICALLER.listartablas({tables:'cajas_users,users',fields:'nombre_user',on:'id_user,id_user_caja',linkTo:'id_caja_caja',equalTo:ID_CAJA})
        

        userslist.response==="ok" ? userslist.results.length>0 && setUsuarios(userslist.results) : console.log(userslist)

        if(userslist.response==="ok"){
            let rescate_fecha_apertura = todosDatosCaja.fecha_apertura
            console.log(rescate_fecha_apertura)
            let res = await APICALLER.listartablas({tables:'cajas_movimientos,cajas_registros,cajas',on:'id_tipo_registro,id_cajas_registro,id_caja,id_caja_movimiento' 
          ,linkTo:'id_caja_movimiento,fecha_movimiento',equalTo:`${ID_CAJA},'${rescate_fecha_apertura}'`,expresion:'=,>='})
            


            if(res.response==="ok"){
                
                let nombre_caja = res.results[0].nombre_caja;
                let monto_inicial = parseFloat(todosDatosCaja.monto_inicial);
                let fechaAper = rescate_fecha_apertura;
                let Depositos = 0;
                let ventas_contado = 0;
                let saldo_final = 0 // parseFloat(ini.results[0].monto_caja);
                let monto_sin_efectivo = 0;
                let Retiros = 0;
                let cobras_ventas_credito = 0;
                let compras_contado = 0;
                let pagos_compras = 0;
                let otros_pagos = 0;
                if(res.results.length>0){
                  let array = res.results;
                  array.forEach(elem => {
                    saldo_final = elem.tipo_registro==="1" ? (saldo_final+(parseFloat(elem.monto_movimiento))) : elem.tipo_registro==="0" ? (saldo_final-(parseFloat(elem.monto_movimiento))) : 0;
                    monto_sin_efectivo+= parseFloat(elem.monto_sin_efectivo);
                    ventas_contado += elem.id_tipo_registro==="1" ? parseFloat(elem.monto_movimiento) : 0;
                    cobras_ventas_credito += elem.id_cajas_registro==="2" ? parseFloat(elem.monto_movimiento) : 0;
                    Depositos+= elem.id_cajas_registro==="4" ? parseFloat(elem.monto_movimiento) : 0;
                    Retiros += elem.id_cajas_registro==="5" ? parseFloat(elem.monto_movimiento) : 0;
                    compras_contado += elem.id_cajas_registro==="6" ? parseFloat(elem.monto_movimiento) : 0;
                    pagos_compras += elem.id_cajas_registro==="7" ? parseFloat(elem.monto_movimiento) : 0;
                    otros_pagos += elem.id_cajas_registro==="8" ? parseFloat(elem.monto_movimiento) : 0;
                  });
                }
                let obj = {
                  montoInicial: monto_inicial,
                  saldoFinal: saldo_final,
                  montoSinEfectivo: monto_sin_efectivo,
                  depositos: Depositos,
                  ventasContado: ventas_contado,
                  cobrosVentasCredito:cobras_ventas_credito,
                  retiros: Retiros,
                  comprasContado: compras_contado,
                  pagoComprasCredito:pagos_compras,
                  otrosPagos:otros_pagos,
                  fechaApertura:fechaAper,
                  nombreCaja: nombre_caja
                }
                setDatos(obj)
            }
            else{
              console.log(res)
            }
        }
        setCargando(false) 
      }
      
    },[todosDatosCaja,dialogCierre])

    useEffect(()=>{
      getDatosCaja()
    },[getDatosCaja])





  return (
    <Dialog fullScreen open={dialogCierre} onClose={cerrar} >
        <DialogTitle>
            <div className={classes.titulodialog}>
              Cierre de caja - Resumen
              <IconButton onClick={cerrar}>
                <Icon>close</Icon>
              </IconButton>
            </div>
        </DialogTitle>
        <DialogContent dividers>
          { cargando && <LinearProgress className={classes.linearProgress} />}
          <CierreResumen usuarios={usuarios} arqueo={totalSumaMonedasArqueo} datos={datos} />
        </DialogContent>
        <DialogActions>
        <Button variant="outlined" size="large" disabled={cargando} onClick={VolverArqueo}  >Volver a arqueo</Button>
          <Button variant="outlined" size="large"  disabled={cargando} onClick={PreguntarCierre} color="primary" >Confirmar cierre</Button>
          <Button variant="outlined" size="large"  onClick={cerrar} >Cancelar</Button>
        </DialogActions>
    </Dialog>
  )
}

export default DialogCierre
