import { createContext,useState,useEffect,useContext, useCallback } from "react"
import { APICALLER } from "../../../Api/ApiCaller";
import { useLogin } from "../../../Contextos/LoginProvider";


const Contexto = createContext()

const SettingsProvider = ({children}) => {

    const { token_user } = useLogin();
    const [cargando,setCargando] = useState(true)

    const [datosEmpresa, setDatosEmpresa] = useState({
        id_empresa: "",
        nombre_empresa: "",
        propietario_empresa: "",
        ruc_empresa: "",
        telefono_empresa: "",
        direccion_empresa: "",
    });
    
    const [snack, setSnack] = useState(false);

    const handleOnchange = (e) => {
      const { name, value } = e.target;
      setDatosEmpresa({ ...datosEmpresa, [name]: value });
    };


    const Guardar = async () => {
        let datas = {
          table: "empresas",
          data: datosEmpresa,
          token: token_user,
          namecolumn: "id_empresa",
          equalTo: datosEmpresa.id_empresa,
        };
        const res = await APICALLER.update(datas);
        res.response==="ok" ? setSnack(true) : console.log(res);
      }; 

    
  
    const getData = useCallback(async () => {
        let res = await APICALLER.listar({table:"empresas"})
        res.response==="ok" && res.founds>0 ? setDatosEmpresa(res.results[0]) : console.log(res)
    },[])

  


    useEffect(() => {
        getData();
      }, [getData]);


  return (
    <Contexto.Provider value={{cargando,setCargando,datosEmpresa, setDatosEmpresa,handleOnchange,Guardar,snack, setSnack,}}>
        {children}
    </Contexto.Provider>
  )
}

export const useSettings = ()=>{

    const {cargando,setCargando,datosEmpresa, setDatosEmpresa,handleOnchange,Guardar,snack, setSnack,} = useContext(Contexto)
    return {cargando,setCargando,datosEmpresa, setDatosEmpresa,handleOnchange,Guardar,snack, setSnack,}
}

export default SettingsProvider
