import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  Typography,
  Fab,
  Tooltip,
  TextField,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Styles } from "./Styles";

import { useVentas } from "./VentasProvider";


const columnas = [
  {
    field: "codigo_producto",
    headerName: "Código",
  },
  {
    field: "cantidad_producto",
    headerName: "Cantidad",
  },
  {
    field: "nombre_producto",
    headerName: "Descripción",
  },
  {
    field: "iva_producto",
    headerName: "IVA",
  },
  {
    field: "precio_producto",
    headerName: "Precio",
  },
  {
    field: "total_precio",
    headerName: "Subtotal",
  },
];



const VentasTablaFactura = () => {
  const classes = Styles();
  const {
    itemsFactura,
    RestarUno,
    AgregarUno,
    borrarItem,
    accionPrecioCambiar, cambiarCantidadInput,redondearNro,numberWithCommas,valorMoneda
  } = useVentas();

  const items_factura =  itemsFactura.slice(0).reverse()

  const Opciones = (codigo) => (
    <div className={classes.botones}>
      <Tooltip arrow title="Cambiar Precio" placement="top">
        <Fab
          size="small"
          color="inherit"
          onClick={() => {
            accionPrecioCambiar(codigo);
          }}
        >
          <Icon style={{ color: "#FE7E0D" }}>request_quote</Icon>
        </Fab>
      </Tooltip>

      <Tooltip arrow title="Agregar 1" placement="top">
        <Fab
          size="small"
          onClick={() => {
            AgregarUno(codigo);
          }}
        >
          <Icon style={{ color: "#288909" }}>add_shopping_cart</Icon>
        </Fab>
      </Tooltip>

      <Tooltip arrow title="Restar 1" placement="top">
        <Fab
          size="small"
          onClick={() => {
            RestarUno(codigo);
          }}
        >
          <Icon color="secondary">remove_shopping_cart</Icon>
        </Fab>
      </Tooltip>

      <Tooltip arrow title="Quitar de la lista" placement="top">
        <Fab
          size="small"
          onClick={() => {
            borrarItem(codigo);
          }}
        >
          <Icon color="primary">delete</Icon>
        </Fab>
      </Tooltip>
    </div>
  );
  return (
    <>
      <div className={classes.titulo}>
          <Typography variant="button" >FACTURA</Typography>
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead className={classes.tableheader}>
            <TableRow>
              {columnas.map((data, index) => (
                <TableCell
                  align="left"
                  key={index}
                  className={classes.tablecell}
                >
                  <Typography variant="overline">{data.headerName}</Typography>
                </TableCell>
              ))}
              <TableCell className={classes.tablecell}>
                <Typography variant="overline">OPCIONES</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items_factura.map((d, index) => (
              <TableRow key={index} className={classes.tablelist}>
                <TableCell className={classes.tableitem}>
                  <span className={classes.columname}>CÓDIGO:</span>
                  <span>{d.codigo_producto}</span>
                </TableCell>
                <TableCell className={classes.tableitem}>
                  <span className={classes.columname}>CANTIDAD:</span>
                  <span>
                    <TextField
                      type="number"
                      variant="standard"
                      size="small"
                      onChange={(e)=>{ cambiarCantidadInput(e.target.value,d.codigo_producto)}}
                      className={classes.inputcantidad}
                      value={d.cantidad_producto}
                    />
                  </span>
                </TableCell>
                <TableCell className={classes.tableitem}>
                  <span className={classes.columname}>DESCRIP:</span>
                  <span>{d.nombre_producto}</span>
                </TableCell>

                <TableCell className={classes.tableitem}>
                  <span className={classes.columname}>IVA:</span>
                  <span>{d.iva_producto}</span>
                </TableCell>


                <TableCell className={classes.tableitem}>
                  <span className={classes.columname}>PRECIO:</span>
                  <span>
                    {
                      numberWithCommas(redondearNro(d.precio_producto/valorMoneda) )
                    }
                  </span>
                </TableCell>
                
                
                <TableCell className={classes.tableitem}>
                  <span className={classes.columname}>SUBTOTAL:</span>
                  <span>
                    {
                      numberWithCommas(redondearNro(d.total_precio/valorMoneda))
                    }
                  </span>
                </TableCell>
                <TableCell className={classes.tableitem}>
                  <span className={classes.columname}>OPCIONES:</span>
                  <span>{Opciones(d.codigo_producto)}</span>
                </TableCell>
              </TableRow>
            ))}
            {
              itemsFactura.length===0 &&
              <>
                <TableRow>
                  <TableCell colSpan={7} align="center" >
                    <Alert severity="info" variant="outlined" icon={<Icon>shopping_basket</Icon>} >
                      <AlertTitle>FACTURA VACÍA</AlertTitle>
                    </Alert>
                  </TableCell>
                </TableRow>
              </>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default VentasTablaFactura;
