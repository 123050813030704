import {
  useEffect,
  useContext,
  createContext,
  useState,
  useCallback,
} from "react";
import { APICALLER } from "../../../Api/ApiCaller";

const Context = createContext();

const FacturasProvider = ({ children }) => {
  const [lista, setLista] = useState([]);
  const [total, setTotal] = useState(0);
  const [openDialogEstado, setOpenDialogEstado] = useState(false);
  let date = new Date();
  let fecha =
    date.getFullYear().toString() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, 0) +
    "-" +
    date.getDate().toString().padStart(2, 0);
  const [cargando, setCargando] = useState(true);
  const [desdeFecha, setDesdeFecha] = useState(fecha);
  const [hastaFecha, setHastaFecha] = useState(fecha);
  const [filtro, setFiltro] = useState("");
  const [formulario, setFormulario] = useState({
    estado_factura: "",
    fecha_factura: "",
    id_factura: "",
    monto_total_factura: "",
    nombre_cliente: "",
    nombre_user: "",
    nro_factura: "",
    ruc_cliente: "",
    tipo_factura: "",
  });




  const getLista = useCallback(async () => {
    setCargando(true);
    let linkTo = ""
    let equalTo = ""
    if(filtro==="1"){
        linkTo="tipo_factura"; equalTo="1"
    }else if(filtro==="2"){
        linkTo="tipo_factura"; equalTo="2"
    }else if(filtro==="3"){
        linkTo="estado_factura"; equalTo="1"
    }else if(filtro==="4"){
      linkTo="estado_factura"; equalTo="2"
    }


    const res = await APICALLER.listarbetween({
      tables: `facturas,clientes,users`,
      on: `id_cliente_factura,id_cliente,id_user,id_user_factura`,
      fields: `id_factura,nro_factura,nombre_user,nombre_cliente,ruc_cliente,monto_total_factura,tipo_factura,estado_factura,fecha_factura`,
      between: `fecha_factura`,
      valores: `'${desdeFecha} 00:00:00','${hastaFecha} 23:59:00'`,
      startAt: 0,
      linkTo,
      equalTo,
      endAt: 180,
      orderBy: `fecha_factura`,
      orderMode: `ASC`,
    });
    if (res.response === "ok") {
      setLista(res.results);
      let suma = 0;
      res.results.forEach((element) => {
        suma += parseFloat(element.monto_total_factura);
      });
      setTotal(suma);
    } else {
      console.log(res);
    }

    setCargando(false);
  }, [desdeFecha, hastaFecha,filtro]);

  useEffect(() => {
    getLista();
  }, [getLista]);

  return (
    <Context.Provider
      value={{
        lista,
        setLista,
        desdeFecha,
        setDesdeFecha,
        hastaFecha,
        setHastaFecha,
        cargando,
        setCargando,
        total,
        setTotal,
        openDialogEstado,
        setOpenDialogEstado,
        formulario,
        setFormulario,
        fecha,filtro,setFiltro
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useFacturas = () => {
  const {
    lista,
    setLista,
    desdeFecha,
    setDesdeFecha,
    hastaFecha,
    setHastaFecha,
    cargando,
    setCargando,
    total,
    setTotal,
    openDialogEstado,
    setOpenDialogEstado,
    formulario,
    setFormulario,
    fecha,filtro,setFiltro
  } = useContext(Context);

  return {
    lista,
    setLista,
    desdeFecha,
    setDesdeFecha,
    hastaFecha,
    setHastaFecha,
    cargando,
    setCargando,
    total,
    setTotal,
    openDialogEstado,
    setOpenDialogEstado,
    formulario,
    setFormulario,
    fecha,filtro,setFiltro
  };
};

export default FacturasProvider;
