import {useState} from 'react'
import {IconButton, Icon, Menu,  List, ListItem, ListItemIcon,ListItemText, } from '@material-ui/core'
import { BASEURL, history } from '../VariablesGlobales/globales'

const UserMenu = () => {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
  return (
    <>
        <IconButton onClick={handleClick}><Icon color="inherit" >more_vert</Icon></IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <List>
            <ListItem button >
                <ListItemIcon><Icon>manage_accounts</Icon></ListItemIcon>
                <ListItemText primary="Perfil" />
            </ListItem>
            <ListItem button onClick={()=> history.push(BASEURL+`/salir`)}>
                <ListItemIcon><Icon>logout</Icon></ListItemIcon>
                <ListItemText primary="Salir" />
            </ListItem>
        </List>
      </Menu>
    </>
  )
}

export default UserMenu
