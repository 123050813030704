import { createContext, useContext,useState,useEffect,useCallback } from "react"
import {APICALLER} from '../Api/ApiCaller'
import {useLogin} from './LoginProvider'
const Contexto = createContext()

const NotificationsProvider = ({children}) => {

    const [cantidad,setCantidad] = useState(0) // CANTIDAD DE NOTIFICACIONES
    const [lista,setLista] = useState([])
    const {permisos,logueado} = useLogin()
    const getLista = useCallback(async ()=>{
        if(logueado){
            let autorizacion =  permisos.findIndex(elem => elem.id_permiso_permiso==="37");
            if(autorizacion>(-1)){
                let res = await APICALLER.listarcomparefields({tables:`productos`,fieldscompare:`minimo_producto,stock_producto`,expresion:`>=`,
                fields:`id_producto,nombre_producto`,linkTo:"tipo_producto",equalTo:"1"})
                
                if(res.response==="ok"){
                    setCantidad(res.results.length);
                    
                    setLista(res.results)
                }
                else{
                console.log(res)
                }
            }
        }
    },[permisos,logueado] )

    useEffect(()=>{
        getLista()
    },[getLista])

  return (
    <Contexto.Provider value={{cantidad,setCantidad,lista,setLista}} >
        {children}
    </Contexto.Provider>
  )
}

export const useNotifications = ()=>{
    const {cantidad,setCantidad,lista,setLista} = useContext(Contexto)
    return {cantidad,setCantidad,lista,setLista}
}

export default NotificationsProvider
