import { createContext, useContext, useState, useEffect } from "react";
import { APICALLER } from "../../../Api/ApiCaller";
//import {useLogin} from '../../../Contextos/LoginProvider'

const CajaContext = createContext();

const CajaProvider = ({ children }) => {
  const swichOpenDialog = () => setOpenDialog(!openDialog);
  const [lista, setLista] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [cargando, setCargando] = useState(true);
  const [openModalAsignar, setOpenModalAsignar] = useState(false);
  const [CajaAsignacionID, setCajaAsignacionID] = useState(null);
  const [CajaAsignacionNombre, setCajaAsignacionNombre] = useState("");

  const getlista = async () => {
    let res = await APICALLER.listar({ table: "cajas" });
    res.response === "ok" ? setLista(res.results) : console.log(res);
    setCargando(false);
  };
  useEffect(() => {
    getlista();
  }, []);

  return (
    <CajaContext.Provider
      value={{
        openDialog,
        swichOpenDialog,
        setOpenDialog,
        lista,
        setLista,
        getlista,
        cargando,
        setCargando,
        CajaAsignacionID,
        setCajaAsignacionID,
        openModalAsignar,
        setOpenModalAsignar,
        CajaAsignacionNombre,setCajaAsignacionNombre
      }}
    >
      {children}
    </CajaContext.Provider>
  );
};
export const useCaja = () => {
  const {
    openDialog,
    swichOpenDialog,
    setOpenDialog,
    lista,
    setLista,
    getlista,
    cargando,
    setCargando,
    CajaAsignacionID,
    setCajaAsignacionID,
    openModalAsignar,
    setOpenModalAsignar,
    CajaAsignacionNombre,setCajaAsignacionNombre
  } = useContext(CajaContext);

  return {
    openDialog,
    swichOpenDialog,
    setOpenDialog,
    lista,
    setLista,
    getlista,
    cargando,
    setCargando,
    CajaAsignacionID,
    setCajaAsignacionID,
    openModalAsignar,
    setOpenModalAsignar,
    CajaAsignacionNombre,setCajaAsignacionNombre
  };
};

export default CajaProvider;
