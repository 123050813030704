import React from 'react'
import CategoriasMain from './CategoriasMain'
import CategoriasProvider from './CategoriasProvider'

const Categorias = () => {
  return (
    <CategoriasProvider>
      <CategoriasMain />
    </CategoriasProvider>
  )
}

export default Categorias
