import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import { useVentas } from "./VentasProvider";

const DialogCambioPrecio = () => {
  const {
    openEditaPrecio,
    setOpenEditaPrecio,
    moneda,
    indexPrecioCambiar,
    precioOriginal,
    itemsFactura,
    setItemsFactura,
    redondearNro,
    numberWithCommas,
    HACERTOTAL,
  } = useVentas();

  const [precioNuevo, setPrecioNuevo] = useState("");

  const [errorPrecio, setErrorPrecio] = useState(false);
  const [errorTxt, setErrorTxt] = useState("");
  const cerrar = () => {
    setOpenEditaPrecio(!openEditaPrecio);
    setPrecioNuevo("");
    setErrorPrecio(false);
    setErrorTxt("");
  };

  const cambiarPrecio = () => {
    const itA = [...itemsFactura];
    const i = indexPrecioCambiar;
    /* ESTA BASADO EN GUARANI */
    let precioNuevoComparar =
      Math.round(precioNuevo * moneda.valor_moneda * 100) / 100;

    let costoComparar = itA[i].costo_producto;

    if (precioNuevoComparar < costoComparar) {
      setErrorTxt(
        `El precio no puede ser menor al costo. ${
          moneda.abreviatura_moneda
        }. ${redondearNro(costoComparar)}`
      );
      setErrorPrecio(true);
    } else {
      setErrorPrecio(false);

      itA[i].total_precio = precioNuevoComparar * itA[i].cantidad_producto;
      itA[i].precio_producto = precioNuevoComparar;
      itA[i].iva_total_producto =
        (itA[i].total_precio * itA[i].iva_producto) /
        (100 + parseFloat(itA[i].iva_producto));

      let storage = JSON.parse(localStorage.getItem("factura"));
      storage.items[i].total_precio = itA[i].total_precio;
      storage.items[i].iva_total_producto = itA[i].iva_total_producto;
      storage.items[i].precio_producto = precioNuevoComparar;

      localStorage.setItem("factura", JSON.stringify(storage));
      setItemsFactura(itA);
      HACERTOTAL(itA);
      cerrar();
    }
  };
  const presionaEnter = (e) => {
    if (e.key === `Enter` && precioNuevo > 0) {
      cambiarPrecio();
    } else {
      setErrorPrecio(true);
      setErrorTxt("El precio debe ser mayor de 0");
    }
  };
  const onChange = (e) => {
    const { value } = e.target;

    if (parseFloat(value) > 0) {
      setErrorPrecio(false);
      setErrorTxt("");
      setPrecioNuevo(parseFloat(value));
    } else {
      if (value === "") {
        setPrecioNuevo("");
      } else {
        setErrorPrecio(true);
        setErrorTxt("El precio debe ser mayor de 0");
      }
    }
  };

  let PRECIO_MAYORISTA =
    itemsFactura.length > 0
      ? redondearNro(
          itemsFactura[indexPrecioCambiar].preciom_producto /
            moneda.valor_moneda
        )
      : 0;
  let PRECIO_ACTUAL =
    itemsFactura.length > 0
      ? redondearNro(
          itemsFactura[indexPrecioCambiar].precio_producto / moneda.valor_moneda
        )
      : 0;
  return (
    <Dialog fullWidth open={openEditaPrecio} onClose={cerrar}>
      <DialogTitle>Cambiar de precio</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Typography variant="body1">
              Precio original:{" "}
              {numberWithCommas(
                redondearNro(precioOriginal / moneda.valor_moneda)
              )}{" "}
              {moneda.abreviatura_moneda}
            </Typography>
          </Grid>
          <Grid item xs={12} >
            <Typography variant="body1">
              Precio mayorista: {numberWithCommas(PRECIO_MAYORISTA)}{" "}
              {moneda.abreviatura_moneda}
            </Typography>
          </Grid>
          <Grid item xs={12} >
            <Typography variant="body1">
              Precio actual: {numberWithCommas(PRECIO_ACTUAL)}{" "}
              {moneda.abreviatura_moneda}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nuevo precio de venta"
              type="number"
              autoFocus
              name="precioNuevo"
              value={precioNuevo}
              onChange={onChange}
              onKeyPress={presionaEnter}
              helperText={errorTxt}
              error={errorPrecio}
              InputProps={{
                startAdornment: `${moneda.abreviatura_moneda}`,
                inputProps: { min: 0 },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={cambiarPrecio}>
          OK
        </Button>
        <Button variant="outlined" onClick={cerrar}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCambioPrecio;

//<p> Precio original: {moneda}{ new Intl.NumberFormat('es-MX').format(precioOriginal)  }  </p>
