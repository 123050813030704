import {
  Dialog,
  DialogTitle,
  Icon,
  IconButton,
  DialogContent,
  TextField,
  DialogActions,
  LinearProgress, FormControl,InputLabel,Select,MenuItem,Button
} from "@material-ui/core";
import { useAperturaCierre } from "./AperturaCierreProvider";
import InputNumberFormat from "../../../Componentes/InputNumberFormat";
import { StylesGenerales } from "../../../Styles/StylesGenerales";
import { APICALLER } from "../../../Api/ApiCaller";
import { useLogin } from "../../../Contextos/LoginProvider";
import { useCallback, useEffect, useState } from "react";
import swal from "sweetalert";

const DialogApertura = () => {
  const classes = StylesGenerales();
  const { dialogApertura, setDialogApertura,fecha_y_horario,getListaCajasAbiertas } = useAperturaCierre();
  const [cargando, setCargando] = useState(true);
  const [listaCajas, setListaCajas] = useState([]);
  const { id_user, token_user } = useLogin();
  const [montoInicial, setMontoInicial] = useState(0);
  const [IDCajaAbrir,setIDCajaAbrir] = useState("")

  const cerrar = () => {
    setIDCajaAbrir("")
    setMontoInicial(0)
    setDialogApertura(false);
  };

  const EfectuarApertura = async()=>{
    if (IDCajaAbrir === "") {return false;}
    if(montoInicial<0){return false;}

    let datos = {monto_inicial: montoInicial,fecha_apertura:fecha_y_horario, fecha_cierre:'', monto_caja:montoInicial,estado_caja:1}
    let res1 = await APICALLER.update({table:'cajas',token:token_user,data:datos,namecolumn:'id_caja',equalTo:IDCajaAbrir})
    if(res1.response==="ok"){
      let data = {id_caja_movimiento:IDCajaAbrir,id_user_caja:id_user,id_tipo_registro:3, monto_movimiento:montoInicial,detalles_movimiento:`Apertura de caja.`,fecha_movimiento:fecha_y_horario}
      let res = await APICALLER.insert({table:'cajas_movimientos',data,token:token_user})
      if(res.response==="ok"){
        swal({icon:'success',text:'Apertura de caja con éxito',timer:1500})
        getListaCajasAbiertas()
        cerrar()
      }
    }else{
      console.log(res1)
    }

  }

  const getListaCajas = useCallback(async () => {
    if (dialogApertura) {
      let res1 = await APICALLER.listartablas({
        tables: "cajas,cajas_users",
        on: "id_caja_caja,id_caja",
        linkTo: "id_user_caja,estado_caja",
        equalTo: `${id_user},0`,
        fields: "nombre_caja,id_caja,monto_caja,estado_caja",
      });
      if(res1.response === "ok") { 
        setListaCajas(res1.results) 
      } else{
        console.log(res1);
      } 
      setCargando(false);
    }
  }, [dialogApertura, id_user]);

  useEffect(() => {
    getListaCajas();
  }, [getListaCajas]);

  return (
    <Dialog fullWidth open={dialogApertura} onClose={cerrar}>
      <DialogTitle>
        <div className={classes.titulodialog}>
          Apertura de caja
          <IconButton onClick={cerrar}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {cargando && <LinearProgress />}

        <FormControl className={classes.selects}>
          <InputLabel className={classes.labelSelect}>
            Seleccione la caja
          </InputLabel>
          <Select
            name="id_caja_movimiento"
            variant="outlined"
            value={IDCajaAbrir}
            onChange={(e)=> setIDCajaAbrir(e.target.value)}
          >
            {listaCajas.length<1 && <MenuItem disabled >No hay cajas disponibles</MenuItem>}
            {listaCajas.map((d, index) => (
              <MenuItem key={index} value={d.id_caja}>
                {d.nombre_caja}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          className={classes.textfield}
          fullWidth
          name="montoInicial"
          label="Monto inicial en caja"
          value={montoInicial}
          InputProps={{
            inputComponent: InputNumberFormat,inputProps: { min: 0 }
          }}
          onChange={(e) => {
            setMontoInicial(
              e.target.value === "" ? 0 : parseFloat(e.target.value)
            );
          }}
          variant="outlined"
        />

      </DialogContent>
      <DialogActions>

      <Button
          variant="outlined"
          disabled={cargando}
          onClick={EfectuarApertura}
          color="primary"
        >
          Registrar
        </Button>
        <Button variant="outlined" onClick={cerrar}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogApertura;
