import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import swal from "sweetalert";
import { APICALLER } from "../../../Api/ApiCaller";
import { useVentas } from "./VentasProvider";

const DialogBuscaProductos = () => {
  const {
    openBuscaProductos,
    switchBuscaProducto,
    listaBuscaProducto,
    setListaBuscaProducto,
    insertarProducto,inputBuscaCodigo,inputBuscaProducto
  } = useVentas();


  const insertaProducto = (e, value) => {
    let txtcodigo = value.codigo_producto;
    inputBuscaCodigo.current.value = txtcodigo;
    
    // verificamos si tiene stock 0

    let stock = parseInt(value.stock_producto); let tipo = parseInt(value.tipo_producto)
    let nombre = value.nombre_producto;
    if(stock<1 && tipo===1 ){
      swal({icon:"warning",title: `${nombre}`,text:` Sin stock. Desea agregar igualmente?`,buttons:["Cancelar","Agregar"]})
      .then( e=>{
        if(e){
          insertarProducto(value);
        }
      });
    }
    else{
      insertarProducto(value);
    }
    setListaBuscaProducto([]);
    switchBuscaProducto();
    inputBuscaCodigo.current.focus(); 
  };

  // buscador con con input text field
  const buscarProductos = async (e) => {
    let txt = e.target.value;
    let config = {
      tables: "productos",
      keywords: txt,
      like: `nombre_producto`,
      
    };
    let res = await APICALLER.search(config);
    
    setListaBuscaProducto(res.results);
  };

  

  return (
    <Dialog fullWidth open={openBuscaProductos} onClose={switchBuscaProducto}>
      <DialogTitle>Buscar producto</DialogTitle>
      <DialogContent dividers>
        <Autocomplete
          loadingText="Cargando..."
          noOptionsText="No hay productos con ese nombre"
          onChange={insertaProducto}
          disableClearable
          getOptionSelected={(option, value) =>
            option.codigo_producto === value.codigo_producto
          }
          options={listaBuscaProducto}
          getOptionLabel={(option) => option.nombre_producto}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus
              inputRef={inputBuscaProducto}
              onChange={buscarProductos}
              fullWidth
              placeholder="Escriba el nombre del producto"
              variant="outlined"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={switchBuscaProducto} variant="outlined" color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBuscaProductos;
