import React from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Icon, CircularProgress, InputAdornment,
  FormControlLabel,Radio, IconButton
} from "@material-ui/core";
import ComprasTabla from "./ComprasTabla";
import { useCompras } from "./ComprasProvider";
import { Funciones } from "../../../Funciones/Funciones";

const ComprasFacturar = () => {

  const { inputCodigo,ConsultarProducto,datosCompra,setDatosCompra,cargandoItem,EfectuarCompra,setOpenDialogSearch } = useCompras();

  const PresionaEnter = e=>{ (e.key==="Enter") && openDialogCompra()}

  const CambioDatos = (e)=>{
    setDatosCompra({...datosCompra,[e.target.name]:e.target.value})
  }
  
  const openDialogCompra = () => {
    var codigo = inputCodigo.current.value
    if(codigo===""){
        console.log("SALTAR ALERTA QUE NO HAY NADA")
    }
    else{
        ConsultarProducto()
    }
  };



  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={9}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <TextField autoFocus fullWidth label="Código" onKeyPress={PresionaEnter} inputRef={inputCodigo} 
              InputProps={{
                startAdornment:(
                  <InputAdornment>
                    <IconButton onClick={()=>{setOpenDialogSearch(true)}} > <Icon>search</Icon> </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment>
                    {cargandoItem && <CircularProgress size={24} />}
                  </InputAdornment>
                ),
              }}
              helperText="Ingrese el código del producto o busquelo con la lupa"
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Button
              size="large"
              color="primary"
              variant="outlined"
              onClick={openDialogCompra}
              startIcon={<Icon color="primary">check_circle_outline</Icon>}
            >
              Consultar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ComprasTabla />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}><Typography variant="h6">DATOS DE COMPRA</Typography></Grid>  
          <Grid item xs={12}><TextField fullWidth type="date" name="fecha_compra" label="Fecha" value={datosCompra.fecha_compra} onChange={CambioDatos}  /></Grid>
          <Grid item xs={12}><TextField fullWidth name="nro_factura" onChange={CambioDatos} label="Nro factura" /></Grid>
          <Grid item xs={12}>
            <FormControlLabel
                  value="1"
                  control={
                    <Radio
                      checked={datosCompra.tipo_factura === "1"}
                      onChange={(e)=>{ setDatosCompra({...datosCompra,tipo_factura:"1"} )}} 
                      color="primary"
                    />
                  }
                  label="Factura contado"
                  labelPlacement="start"
                />
            <FormControlLabel
              value="2"
              control={
                <Radio
                  checked={datosCompra.tipo_factura === "2"} 
                  color="primary"
                  onChange={(e)=>{ setDatosCompra({...datosCompra,tipo_factura:"2"} )}} 
                    />
                }
              label="Factura crédito"
              labelPlacement="start"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField type="date" 
              disabled={datosCompra.tipo_factura === "1"}
            name="fecha_pago" label="Fecha de pago" helperText="En caso de que sea factura a crédito" value={datosCompra.fecha_pago} onChange={CambioDatos}  />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="button">TOTAL: { Funciones.numberWithCommas( datosCompra.total_factura)} </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button variant="outlined" size="large" color="secondary" onClick={EfectuarCompra} >Procesar compra</Button>
          </Grid>
          
        </Grid>
      </Grid>

      
    </Grid>
  );
};

export default ComprasFacturar;
