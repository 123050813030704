import {
  Button,
  Grid,
  Typography,
  Icon,
  Avatar,
  TextField,
  Container,
  CircularProgress,
  InputAdornment
} from "@material-ui/core";
import {useState} from "react";
import { useEntregas } from "./EntregasProvider";
import EntregasView from "./EntregasView";
//import Tablas from '../../../Componentes/Tablas'
//import {StylesGenerales} from '../../../Styles/StylesGenerales'

const EntregasLista = () => {
  //const classes = StylesGenerales()
  const { nroFactura, getFactura, lista,cargando } = useEntregas();

    const [error,setError] = useState(false)
    const [errorMsj,setErrorMsj] = useState("Ingrese el número de factura")
  const PressEnter = (e)=>{ e.key==="Enter" && Consultar() }

  const Consultar = ()=>{
    let nro = nroFactura.current.value

    if(nro===""){
        setError(true)
        setErrorMsj("Ingrese correctamente el número de factura")
        nroFactura.current.focus()
        return false;
    }
    setError(false)
    setErrorMsj("Ingrese el número de factura")
    getFactura()

  }

  return (
    <Container disableGutters>
      <Grid container spacing={2} justify="flex-start" alignItems="flex-start" >
        <Grid item xs={12} md={1}>
          <Avatar
            variant="rounded"
            style={{
              padding: 8,
              backgroundColor: `#3f51b5`,
            }}
          >
            <Icon fontSize="large">delivery_dining</Icon>
          </Avatar>
        </Grid>
        <Grid item xs={12} md={11}>
          <Typography variant="button">Entregas</Typography>
          <br />
          <Typography variant="caption">
            En el módulo de ENTREGAS se dan salida a los productos vendidos del
            stock del sistema.
            Ingresando el número de factura se visualiza los productos pendientes de entregas.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            size="small"
            type="number"
            onKeyPress={PressEnter}
            inputRef={nroFactura}
            label="Número de factura"
            helperText={errorMsj}
            autoFocus
            variant="outlined"
            fullWidth
            error={error}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  {cargando && <CircularProgress size={24} />}
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Button
            size="large"
            variant="outlined"
            onClick={Consultar}
            color="primary"
          >
            Consultar
          </Button>
        </Grid>


        <Grid item xs={12}>
            <EntregasView datos={lista} />
        </Grid>
      </Grid>
    </Container>
  );

}

export default EntregasLista;
