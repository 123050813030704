import { Dialog,DialogActions, DialogTitle, DialogContent, DialogContentText,Button,Icon,IconButton,TextField } from '@material-ui/core'
import React from 'react'
import { StylesGenerales } from '../../../Styles/StylesGenerales'
import { useMarcas } from './MarcasProvider'

const MarcasForm = () => {

    const {formulario,setFormulario,openDialog,setOpenDialog,cargando,enviarFormulario} = useMarcas()
    const classes = StylesGenerales()
    const cerrar = ()=>{ setFormulario({nombre_marca:""}); setOpenDialog(false) }
    const onChange = (e)=>{
        const {name,value} = e.target
        setFormulario({...formulario,[name]:value})
    }

  return (
    <Dialog fullWidth open={openDialog} onClose={cerrar}>
        <form onSubmit={enviarFormulario}>
        <DialogTitle>
          <div className={classes.titulodialog}>
            <div>
            Marcas
            </div>
            <IconButton onClick={()=>cerrar()}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
            <DialogContentText>
                { formulario.id_marca ? `Editar el nombre de la marca` : `Ingrese el nombre de la marca`}
            </DialogContentText>
            
            <TextField
                autoFocus
                autoComplete="off"
                value={formulario.nombre_marca}
                name="nombre_marca"
                onChange={onChange}
                fullWidth
                variant="outlined"
                helperText="Nombre de la marca"
                disabled={cargando}
                required
            />
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" type="onsubmit" color="primary" > { formulario.id_marca ? `EDITAR` : `AGREGAR`}</Button>
            <Button variant="outlined" color="default" onClick={()=>cerrar()}>CERRAR</Button>
            
        </DialogActions>
        </form>
    </Dialog>
    )
}

export default MarcasForm

