import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Icon,
  InputAdornment,
} from "@material-ui/core";
import { useState,useRef } from "react";
import swal from "sweetalert";
import { APICALLER } from "../../../Api/ApiCaller";
import Loading from "../../../Componentes/Loading";
import { useLogin } from "../../../Contextos/LoginProvider";
import { useVentas } from "./VentasProvider";




const DialogRegistroCliente = () => {

  const { openRegistrarCliente, setOpenRegistrarCliente,nroDocumentoInput,setDatosCliente } = useVentas();
  const {token_user} = useLogin();
  const initial = {
    ruc_cliente: "",
    nombre_cliente: "",
    direccion_cliente: "",
    telefono_cliente: "",
    email_cliente:""
  };
  const initialErros = {
    ruc_cliente:false,
    nombre_cliente:false,
    ruc_error: "",
    nombre_error:"",
  };
  const ruc_cliente = useRef(null)
  const nombre_cliente = useRef(null)
  const [formCliente, setFormCliente] = useState(initial);
  const [formError,setFormError] = useState(initialErros)
  const [cargando,setCargando] = useState(false)

  const VerificarRegistro = async() => {
    setFormError(initialErros)

    if(formCliente.ruc_cliente.length<3){
      setFormError({...formError, ruc_cliente:true,ruc_error:"Complete este dato"})
      ruc_cliente.current.focus();
      return false
    }
    if(formCliente.nombre_cliente.length<4){
      setFormError({...formError, nombre_cliente:true,nombre_error:"El nombre debe tener al menos 5 letras"})
      nombre_cliente.current.focus();
      return false
    }

    let ruc = ruc_cliente.current.value;
    
    const res = await APICALLER.listar({table:"clientes",fields:"id_cliente",linkTo:"ruc_cliente",equalTo:ruc});

    if(res.results.length===0){
      nroDocumentoInput.current.value= ruc_cliente.current.value;
      nroDocumentoInput.current.focus();
      setCargando(true);
      
      try {
        const ins = await APICALLER.insert({table:"clientes",data:formCliente,token:token_user})
        if(ins.response==="ok"){ 
          setCargando(false);
          let ID_CLIENTE = ins.results[0].last_id;
          setDatosCliente({id_cliente: ID_CLIENTE,nombre_cliente: nombre_cliente.current.value,ruc_cliente: ruc_cliente.current.value})
          setOpenRegistrarCliente(false);
          setFormCliente(initial);
        }
      } catch (error) {
        setCargando(false)
      }
      
    }
    else{
      swal({icon:"error",text:"Cliente existente, no puede registrar"});
    }

  };


  const cambiarValor = (e) => {
    const { name, value } = e.target;
    setFormCliente({ ...formCliente, [name]: value });
  };

  const switchRegistraCliente = () => {
    setOpenRegistrarCliente(!openRegistrarCliente);
    setFormCliente(initial);
  };
  return (

    <form onSubmit={VerificarRegistro}>
      <Dialog
        open={openRegistrarCliente}
        onClose={switchRegistraCliente}
        fullWidth
      >
        <DialogTitle>Registrar cliente</DialogTitle>
        <DialogContent dividers>
          {
            cargando ? <Loading /> :
          
          <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                required
                inputRef={ruc_cliente}
                onChange={cambiarValor}
                label="Nro Documento"
                autoFocus
                fullWidth
                name="ruc_cliente"
                value={formCliente.ruc_cliente}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon color="disabled">description</Icon>
                    </InputAdornment>
                  ),
                }}
                error={formError.ruc_cliente}
                helperText={formError.ruc_error}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                inputRef={nombre_cliente}
                autoComplete="off"
                label="Nombre completo"
                fullWidth
                name="nombre_cliente"
                variant="outlined"
                onChange={cambiarValor}
                value={formCliente.nombre_cliente}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon color="disabled">person</Icon>
                    </InputAdornment>
                  ),
                }}
                error={formError.nombre_cliente}
                helperText={formError.nombre_error}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Teléfono"
                fullWidth
                autoComplete="off"
                onChange={cambiarValor}
                name="telefono_cliente"
                variant="outlined"
                value={formCliente.telefono_cliente}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon color="disabled">phone</Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Dirección"
                fullWidth
                autoComplete="off"
                onChange={cambiarValor}
                name="direccion_cliente"
                variant="outlined"
                value={formCliente.direccion_cliente}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon color="disabled">location_city</Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                fullWidth
                autoComplete="off"
                onChange={cambiarValor}
                name="email_cliente"
                variant="outlined"
                value={formCliente.email_cliente}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon color="disabled">alternate_email</Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          </>
          }
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              VerificarRegistro();
            }}
            type="submit"
          >
            Registrar
          </Button>
          <Button
            variant="outlined"
            onClick={() => switchRegistraCliente()}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </form>

  );
};

export default DialogRegistroCliente;
