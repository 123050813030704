import { createContext, useState,useContext, useEffect } from 'react'
import swal from "sweetalert";
import { APICALLER } from "../../../Api/ApiCaller";
import { useLogin } from "../../../Contextos/LoginProvider";

const Contexto = createContext()

const ProveedoresProvider = ({children}) => {

  const {token_user} = useLogin()
  const [lista, setLista] = useState([]);
  const [cargando,setCargando] = useState(true)
  const initial = {nombre_proveedor:"",telefono_proveedor:"",ruc_proveedor:""}
  const [formulario,setFormulario] = useState(initial)
  const [openDialog,setOpenDialog]= useState(false)


  const enviarFormulario = async(e)=>{
    setCargando(true)
    e.preventDefault()
    let msj = formulario.id_proveedor ? `Actualizado` : `Agregado` ;
    const table = "proveedors";
    const res = formulario.id_proveedor ? await APICALLER.update({table,data:formulario,token:token_user,namecolumn:`id_proveedor`,equalTo:formulario.id_proveedor}) 
    : await APICALLER.insert({table,data:formulario, token: token_user})
    res.response==="ok" ? swal({icon:"success",text:`${msj} correctamente`}) : console.log(res)
    getLista()
    setOpenDialog(false)
    setFormulario(initial)
    setCargando(false)
  }

  const borrarRegistro = async(id,nombre="")=>{
    swal({icon:"info",text:`Desear borrar "${nombre}" ?`, title:`Borrar?`, buttons:['Cancelar','OK']}).then(
      async(e)=>{
        if(e){
          let res = await APICALLER.listar({table:`productos`,linkTo:`id_proveedor_producto`,equalTo:id})
          res.response==="ok" ? 
            res.results.lenght>0 ? swal({icon:`error`, text:`No se puede borrar porque hay productos que tiene este proveedor`}) : console.log(`proceder`)
          : console.log(res)
          
        }
      }
    )
  }

  const getLista = async()=>{
    let res = await APICALLER.listar({table:`proveedors`})
    res.response==="ok" ? setLista(res.results) : console.log(res);
    setCargando(false)
  }
  useEffect(() => {
    getLista()
  }, []);
  return (
    <Contexto.Provider value={{lista,setLista,cargando,formulario,setFormulario,openDialog,setOpenDialog,enviarFormulario,borrarRegistro}}>
      {children}
    </Contexto.Provider>
  )
}

export const useProveedores = ()=>{

  const {lista,setLista,cargando,formulario,setFormulario,openDialog,setOpenDialog,enviarFormulario,borrarRegistro} = useContext(Contexto);
  return {lista,setLista,cargando,formulario,setFormulario,openDialog,setOpenDialog,enviarFormulario,borrarRegistro}
}

export default ProveedoresProvider
