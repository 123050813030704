import {
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Icon,
    Typography,
    TextField,
    IconButton,
    Tooltip,
  } from "@material-ui/core";
import { green } from '@material-ui/core/colors';
import {Alert,AlertTitle} from '@material-ui/lab'
import { StylesTabla } from "./StylesTabla";
import {Funciones} from '../../../Funciones/Funciones'
import { useCompras } from "./ComprasProvider";

const columnas = [
  {
    field: "codigo_producto",
    headerName: "Código",
  },
  {
    field: "cantidad_producto",
    headerName: "Cantidad",
  },
  {
    field: "nombre_producto",
    headerName: "Descripción",
  },
  {
    field:"precio_producto",
    headerName:"Precio",
  },
  {
    field:"subtotal",
    headerName:"Subtotal",
  },
]

const ComprasTabla = () => {

  const {itemsFactura,Borrar} = useCompras()
  const items_factura =  itemsFactura.slice(0).reverse()
  const Opciones = (codigo)=>(
    <div>
      <Tooltip title="Información del producto" arrow >
        <IconButton onClick={()=>{}} ><Icon style={{ color: green[500] }}>info</Icon></IconButton>
        </Tooltip>
      <Tooltip title="Remover" arrow ><IconButton onClick={()=>{Borrar(codigo)}} >
        <Icon color="secondary">delete</Icon></IconButton>
      </Tooltip>
    </div>
  )
  const classes = StylesTabla()
  return (
    <>
        <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead className={classes.tableheader}>
            <TableRow>
              {columnas.map((data, index) => (
                <TableCell
                  align="left"
                  key={index}
                  className={classes.tablecell}
                >
                  <Typography variant="overline">{data.headerName}</Typography>
                </TableCell>
              ))}
              <TableCell className={classes.tablecell}>
                <Typography variant="overline">OPCIONES</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items_factura.map((d, index) => (
              <TableRow key={index} className={classes.tablelist}>
                <TableCell className={classes.tableitem}>
                  <span className={classes.columname}>CÓDIGO:</span>
                  <span>{d.codigo_producto}</span>
                </TableCell>
                <TableCell className={classes.tableitem}>
                  <span className={classes.columname}>CANTIDAD:</span>
                  <span>
                    <TextField
                      type="number"
                      variant="standard"
                      size="small"
                      onChange={(e)=>{ console.log(e.target.value,d.codigo_producto)}}
                      className={classes.inputcantidad}
                      value={d.stock_nuevo}
                    />
                  </span>
                </TableCell>
                <TableCell className={classes.tableitem}>
                  <span className={classes.columname}>DESCRIP:</span>
                  <span><Typography variant="overline"> {d.nombre_producto}</Typography></span>
                </TableCell>

                <TableCell className={classes.tableitem}>
                  <span className={classes.columname}>PRECIO:</span>
                  <span>
                    {
                      Funciones.numberWithCommas( parseFloat(d.costo_producto) )
                    }
                  </span>
                </TableCell>
                
                
                <TableCell className={classes.tableitem}>
                  <span className={classes.columname}>SUBTOTAL:</span>
                  <span>
                    {
                      Funciones.numberWithCommas(parseFloat(d.costo_producto)*parseFloat(d.stock_nuevo))
                    }
                  </span>
                </TableCell>
                <TableCell className={classes.tableitem}>
                  <span className={classes.columname}>OPCIONES:</span>
                  <span>{Opciones(d.codigo_producto)}</span>
                </TableCell>
              </TableRow>
            ))}
            {
              itemsFactura.length===0 &&
              <>
                <TableRow>
                  <TableCell colSpan={7} align="center" >
                    <Alert severity="info" variant="outlined" icon={<Icon>shopping_basket</Icon>} >
                      <AlertTitle>FACTURA VACÍA</AlertTitle>
                    </Alert>
                  </TableCell>
                </TableRow>
              </>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ComprasTabla
