import React from 'react'
import Proximante from '../../Componentes/Proximante'

const Medidas = () => {
  return (
    <>
      <Proximante title="Medidas" />
    </>
  )
}

export default Medidas
