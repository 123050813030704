import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Radio,
  FormControlLabel,
  Typography,
  Grid,
  IconButton,
  Icon,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import  { useState,useRef } from "react";
import { APICALLER } from "../../../Api/ApiCaller";
import { useLogin } from "../../../Contextos/LoginProvider";
import { history } from "../../../VariablesGlobales/globales";
import { useVentas } from "./VentasProvider";
import swal from "sweetalert";
import {Funciones} from '../../../Funciones/Funciones'
import NumberFormat from "react-number-format";
import { StylesGenerales } from "../../../Styles/StylesGenerales";


function NumberFormatCustom(props) {
  const { inputRef, onChange, ...rest } = props;
  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(e) => {
        onChange({
          target: {
            name: props.name,
            value: e.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
    />
  );
}

const VentasFinalizar = () => {
  const {
    terminarVentaDialog,
    setTerminarVentaDialog,
    totalPrecioFactura,
    moneda,
    itemsFactura,
    nroFactura,
    datosCliente,
    setDatosCliente,
    nroDocumentoInput,
    cantidadRecibida,
    setCantidadRecibida,
    limpiarTodo,
    openRegistrarCliente,
    setOpenRegistrarCliente,
    formasPago,
    numberWithCommas,
    redondearNro,fecha,totalIva,idCaja,idFactura,ordenDeCompra
  } = useVentas();

  const { token_user, id_user } = useLogin();
  const cerrar = () => setTerminarVentaDialog(false);

  const classes = StylesGenerales()

  const [errorTxt, setErrorTxt] = useState("");
  const [error, setError] = useState(false);
  const [existClient, setExistClient] = useState(true);
  const [cargando,setCargando] = useState(false)
  const [cargandoCliente,setCargandoCliente] = useState(false);

  // FORMAS DE PAGO UNICO EL OTRO ES EL ARRAY CON LAS FORMAS DE PAGO
  const [formaPago, setFormaPago] = useState("1");
  const handleChange = (event) => {
    setFormaPago(event.target.value);
  };

  const obsDetallesPago = useRef(null)

  // tipo de factura contado=1 o credido=2
  const [tipoFactura, setTipoFactura] = useState("1"); // 1 contado 2 credito
  const changeTipoFactura = (e) => setTipoFactura(e.target.value);

  /***TIPO DE CLIENTE RETENTOR O NORMAL */
  const [tipoCliente, setTipoCliente] = useState("1");
  const changeTipoCliente = (e) => {
  tipoCliente==="2" && setValorRetencion(0);
  setTipoCliente(e.target.value);
  }

  const [valorRetencion,setValorRetencion] = useState("");
  const changeValorRetencion = e => { 
      let nro = parseFloat( e.target.value) ;
      if (nro >= 0 && nro <= 100 && !isNaN(nro) ) {
        setValorRetencion(nro)
      }
  }

  //*****la fecha del dia de cobro del credio */
  const [fechaCredito, setFechaCredito] = useState(fecha);
  const changeFechaCredito = (e) => setFechaCredito(e.target.value);
  const [cambiodia,setCambiodia] = useState("")

  const cambiofecha = (e)=>{
    const {value} = e.target
    var date = new Date();
    date.setDate(date.getDate() + parseInt(value));
    let fechita =date.getFullYear().toString() +"-" +(date.getMonth() + 1).toString().padStart(2, 0) +"-" +date.getDate().toString().padStart(2, 0);
    setCambiodia(value)
    setFechaCredito(fechita)
  }

  /***********AQUI BUSCAMOS EL NRO DE DOC DEL CLIENTE */
  const BuscarPorNro = async (e) => {
    let nroDocumento = nroDocumentoInput.current.value;
    if (e.key === `Enter` && nroDocumento.length > 0) {
      setCargandoCliente(true)
      let config = {
        tables: "clientes",
        keywords: nroDocumento,
        like: `ruc_cliente`,
        fields: "id_cliente,nombre_cliente,ruc_cliente",
      };
      let res = await APICALLER.search(config);

      if (res.results.length === 1) {
        setDatosCliente(res.results[0]);
        setExistClient(true);
      } else {
        setExistClient(false);
      }
      setCargandoCliente(false)
    }
    else{
      e.key === `Enter` && setDatosCliente({id_cliente: 0,nombre_cliente: "Sin nombre",ruc_cliente: 0})
    }
  };

  /****AQUI SE CAMBIA TIPO DE MONEDA */



  /**AQUI INSERTA */
  const ProcesarFactura = async()=>{

      //console.log(`cantidad recibida ${cantidadRecibida}, moneda=> ${moneda.id_moneda}, retencion=>${(totalIva*valorRetencion)/100}`)
      setCargando(true)
      let f = new Date();
      let fecha_actual = `${fecha} ${f.getHours()}:${f.getMinutes()}:${f.getSeconds()}`;
      let OBS_PAGO = obsDetallesPago.current ? obsDetallesPago.current?.value : '' ;


      // ALTERAMOS LA FACTURA DE EMPRESAS_FACTURAS
      let factura = await APICALLER.update({table:'empresa_facturas',token:token_user,data:{last_nro_factura: (nroFactura+1)},namecolumn:`id_empresa_factura`,equalTo:idFactura })
      factura.response!=="ok" && console.log(factura);

      let dataFactura=
         {
          id_cliente_factura: datosCliente.id_cliente,
          fecha_factura: fecha_actual,
          nro_factura: nroFactura,
          recibido_factura: cantidadRecibida==="" ? 0 : cantidadRecibida* moneda.valor_moneda ,
          monto_total_factura: totalPrecioFactura,
          id_forma_pago_factura: formaPago,
          estado_factura: tipoFactura==="1" ? 1 : 2,
          id_moneda_factura: moneda.id_moneda,
          valor_moneda_factura: moneda.valor_moneda,
          id_user_factura: id_user,
          id_caja_factura: idCaja,
          tipo_factura: tipoFactura,
          fecha_cobro_factura: tipoFactura==="1" ? fecha_actual : fechaCredito, // 
          iva_factura: totalIva,
          retencion_iva_factura:(totalIva*valorRetencion)/100,
          obs_factura: OBS_PAGO,
          orden_compra: ordenDeCompra,
        }
    
      
        
      // GENERAMOS UNA NUEVA FACTURA
      const res = await APICALLER.insert({
        table: "facturas",
        data: dataFactura,
        token: token_user,
      });
      // recuperamos el id de la factura
      let id_factura = await res.results[0].last_id;
      


      // INGRESAMOS AL REGISTRO DE CAJA SI ES CONTADO
      if(tipoFactura==="1"){
        
        await APICALLER.insert({
          table: "cajas_movimientos",
          token: token_user,
          data: {
            id_user_caja: id_user,
            id_caja_movimiento: idCaja,
            id_tipo_registro: 1, // 1 VENTA CONTADO REVISAR TABLA 
            monto_movimiento: formaPago==="1" ? totalPrecioFactura : 0, // forma de pago efectivo  es 1
            monto_sin_efectivo: formaPago==="1" ? 0 : totalPrecioFactura,
            detalles_movimiento: "Venta contado. Factura nro:" + nroFactura,
            fecha_movimiento: fecha_actual,
          },
        });

        if(formaPago==="1"){
          let call_monto = await APICALLER.listar({table:`cajas`,fields:`monto_caja`,linkTo:`id_caja`,equalTo:idCaja});
          let nuevo_monto = totalPrecioFactura + parseFloat(call_monto.results[0].monto_caja);
          await APICALLER.update({table:`cajas`,token:token_user,data:{monto_caja:nuevo_monto},namecolumn:`id_caja`,equalTo:idCaja})
        }
      }
      
    
      // INSERTAMOS LOS ITEMS A LOS ITEMS DE FACTURA
      itemsFactura?.forEach(async (e) => {
          //ID DEL PRODUCTO
          let id = e.id_producto;
          // SI ES FISICO SE RESTA SINO SI ES SERVICIO NO TOCA
          

          let dataFactura = {
            id_producto_factura: id,
            id_items_factura: id_factura,
            cantidad_producto: e.cantidad_producto,
            precio_producto_factura: e.precio_producto,
            entregado_item:e.tipo_producto===1 ? 0 : 2 , // si el tipo es 1 fisico el entregado es 0 sino el producto es servicio entonces es 2
          } 
          
          let insert = await APICALLER.insert({
            table: "facturas_items",
            data: dataFactura,
            token: token_user,
          });

         !insert.response==="ok" && console.log(insert);

          await APICALLER.insert({
            table: "productos_vendidos",
            token: token_user,
            data: {
              id_producto_vendido: id,
              id_factura_vendido: id_factura,
              id_cliente_vendido: datosCliente.id_cliente,
              precio_vendido: e.precio_producto,
              cantidad_vendido: e.cantidad_producto,
            },
          });

          //let stock_nuevo = parseFloat(e.stock_producto) - e.cantidad_producto;
          // SI EL PRODUCTO ES FISICO ENTONCES SE SACA DEL STOCK
          // AQUI SI ES PEQUEÑO EL NEGOCIO O ES UN SUPER O SOLO TIENE UN DEPOSITO
          
          /*e.tipo_producto===1 &&
          await APICALLER.update({
            table: "productos",
            data: { stock_producto: stock_nuevo },
            token: token_user,
            namecolumn: "id_producto",
            equalTo: id,
          }) */


      });// INSERTAMOS LOS ITEMS A LOS ITEMS DE FACTURA

      

      if (res.response === "ok") {
        swal({
          title: "Desea visualizar la factura?",
          icon: "info",
          buttons: ["Cancelar", "OK"],
        }).then((e) => {
          if (e) {
            limpiarTodo();
            history.push(`/facturas/${id_factura}`);
          } else {
            limpiarTodo();
            cerrar();
          }
        });
      }
      setCargando(false) 
  }

  /* AQUI INSERTA  */





  /**============================================= 
   * AQUI INSERTA EN LA TABLA 
   ===============================================
  */
  const Procesar = async () => {
    // si esta pagando contado
    if (cantidadRecibida > 0 && tipoFactura === "1") {
      setErrorTxt("");
      setError(false);
      ProcesarFactura()
    } else {
      if (tipoFactura === "2") {
        if(datosCliente.id_cliente===0 || datosCliente.ruc_cliente===0){
          swal({text:`Para vender a crédito debe tener datos del cliente`,title:`Atención`,icon:`warning`})
          .then(()=>nroDocumentoInput.current.focus() )
          return false;
        }
        else{
          ProcesarFactura()
        }
      } else {
        setError(true);
        setErrorTxt("El pago debe ser mayor a 0");
      }
    }
  };
  /**============================================= 
   * AQUI INSERTA EN LA TABLA 
   ===============================================
  */


  const TOTAL_IVA = totalIva/moneda.valor_moneda;
  
  const TOTAL_FACTURA_CON_IVA = redondearNro(totalPrecioFactura/moneda.valor_moneda)


  const RETENCION = (totalIva*valorRetencion)/100
  

  const TOTAL_A_COBRAR = redondearNro((totalPrecioFactura - RETENCION)/moneda.valor_moneda)

  const PAGO = cantidadRecibida 

  
  const cambio =  cantidadRecibida -TOTAL_A_COBRAR;

  return (
    <>
      <Dialog open={terminarVentaDialog} fullScreen onClose={cerrar}>
        <DialogTitle>
          <div className={classes.titulodialog}>
            Finalizar Factura
            <IconButton onClick={cerrar}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>


        <DialogContent dividers>
          { cargando ? 
          <div className={classes.cargando} >
            <CircularProgress size={45} />
          </div>
          :
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Documento de cliente"
                placeholder="Documento de cliente"
                variant="outlined"
                margin="dense"
                autoFocus
                inputRef={nroDocumentoInput}
                onKeyPress={BuscarPorNro}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon color="disabled">description</Icon>
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton
                onClick={() => {
                  setOpenRegistrarCliente(!openRegistrarCliente);
                  nroDocumentoInput.current.value = "";
                }}
              >
                <Icon>person_add_alt_1</Icon>
              </IconButton>
            </Grid>

            <Grid item xs={12} sm={4}>
              {existClient ? (
                <Alert severity="info" icon={false} variant="outlined">
                  {
                    cargandoCliente ? <CircularProgress size={24} /> :
                    <AlertTitle>
                      DOC: {datosCliente.ruc_cliente} <br />
                      NOMBRE: {datosCliente.nombre_cliente}
                    </AlertTitle>
                  }
                </Alert>
              ) : (
                <Alert severity="error" variant="outlined">
                  <AlertTitle>Cliente no existe en el registro</AlertTitle>
                </Alert>
              )}
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="h6">Tipo de factura:</Typography>
              <FormControlLabel
                value="1"
                control={
                  <Radio
                    checked={tipoFactura === "1"}
                    color="primary"
                    onChange={changeTipoFactura}
                  />
                }
                label="Contado"
                labelPlacement="end"
              />
              <FormControlLabel
                value="2"
                control={
                  <Radio
                    checked={tipoFactura === "2"}
                    color="primary"
                    onChange={changeTipoFactura}
                  />
                }
                label="Crédito"
                labelPlacement="end"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              {
                /** SI TIPO DE FACTURA ES CREDITO */
                tipoFactura === "2" && (
                  <>
                    <Typography variant="h6">Fecha de pago:</Typography>
                    <TextField type="number" value={cambiodia} label="Días de crédito" onChange={cambiofecha} />
                    {` `}
                    <TextField
                      label="Fecha de pago"
                      disabled
                      type="date"
                      value={fechaCredito}
                      onChange={changeFechaCredito}
                    />
                  </>
                )
              }
              
            </Grid>
            <Grid item xs={12} md={4}>
            <Typography variant="h6">Tipo de cliente:</Typography>
            <FormControlLabel
                value="1"
                control={
                  <Radio
                    checked={tipoCliente === "1"}
                    color="primary"
                    onChange={changeTipoCliente}
                  />
                }
                label={`Normal`}
                labelPlacement="end"
              />
              <FormControlLabel
                value="2"
                control={
                  <Radio
                    checked={tipoCliente === "2"}
                    color="primary"
                    onChange={changeTipoCliente}
                    disabled={datosCliente.ruc_cliente===0}
                  />
                }
                label={`Retentor`}
                labelPlacement="end"
              />
              {
                tipoCliente === "2" && <TextField
                type="number"
                value={valorRetencion}
                onChange={changeValorRetencion}
                InputProps={{inputProps: { min: 0,max:100 },endAdornment:(`%`)
                }}
                />
              }
            </Grid>  
            <Grid item xs={12} md={8}>
              {tipoFactura === "1" && (
                <>
                  <Typography variant="h6">Forma de pago:</Typography>
                  {formasPago.map((data) => (
                    <FormControlLabel
                      key={data.id_facturas_formas_pago}
                      value={data.id_facturas_formas_pago}
                      control={
                        <Radio
                          checked={formaPago === data.id_facturas_formas_pago}
                          onChange={handleChange}
                          color="primary"
                        />
                      }
                      label={`${data.descripcion_forma_pago}`}
                      labelPlacement="end"
                    />
                  ))}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              {
                formaPago!=="1" && 
                <TextField inputRef={obsDetallesPago} fullWidth variant="outlined" label="Observaciones de pago" helperText="Detalle todo lo necesario de la forma de pago" />
              }
            </Grid>

            <Grid item xs={12} sm={6}>
              {tipoFactura === "1" && (
                <TextField
                  label="Cantidad recibida"
                  value={cantidadRecibida}
                  InputProps={{
                    startAdornment: `${moneda.abreviatura_moneda}`,
                    inputComponent: NumberFormatCustom,
                    inputProps: {min:0}
                  }}
                  onChange={(e) => {
                    setCantidadRecibida(e.target.value==="" ? 0 : parseFloat(e.target.value));
                  }}
                  variant="outlined"
                  error={error}
                  helperText={errorTxt}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                  Total IVA : { numberWithCommas( redondearNro(TOTAL_IVA) )}{" "}
                  {moneda.abreviatura_moneda}
              </Typography>
              <Typography variant="h5">
                Total: {numberWithCommas(TOTAL_FACTURA_CON_IVA)}{" "}
                {moneda.abreviatura_moneda}
              </Typography>
              
              <Typography variant="h5">
                Total a cobrar: {numberWithCommas(TOTAL_A_COBRAR)}{" "}
                {moneda.abreviatura_moneda}
              </Typography>

              <Typography variant="h5">
                Pago: {numberWithCommas(PAGO)}{" "}
                {moneda.abreviatura_moneda}
              </Typography>

              <Typography variant="h5">
                {cambio >= 0 &&
                  `Cambio: ${  numberWithCommas(cambio)} ${
                    moneda.abreviatura_moneda
                  }`}
                {/*falta > 0 && `Falta: ${(falta)} `*/}
              </Typography>
            </Grid>
            <Grid item xs={12}>
            <Typography variant="button">
              SON: {
                Funciones.NumeroALetras(TOTAL_FACTURA_CON_IVA,moneda.nombre_moneda)
              }
            </Typography>
            </Grid>
          </Grid>
        }
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={Procesar} disabled={cargando} variant="outlined">
            FINALIZAR VENTA
          </Button>
          <Button onClick={cerrar} disabled={cargando} variant="outlined">
            CANCELAR
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VentasFinalizar;
