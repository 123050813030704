import {
    makeStyles,
  } from "@material-ui/core";
export const Styles = makeStyles((theme) => ({
    absolute: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(3),
    },
    botones:{
      "& > *":{
        margin: theme.spacing(0,1),
      }
    },
    inputs:{
        display:"flex",
        alignItems:"center",
        "& > *":{
          marginRight:"25px",
        }
      },
  }));
