import { useEffect, useState, useRef } from "react";
import { CircularProgress, Tabs, Tab, Button,Icon } from "@material-ui/core";
import {APICALLER} from '../../../Api/ApiCaller'
import FacturasViewTicket from './FacturasViewTicket'
import { useReactToPrint } from "react-to-print";
import FacturasViewFactura from "./FacturasViewFactura";

const FacturasView = (props) => {
  const { id } = props.match.params;
  const [cargando, setCargando] = useState(true);
  const [infoEmpresa, setInfoEmpresa] = useState({});
  const [infoFactura, setInfoFactura] = useState({});
  const [itemsFactura, setItemsFactura] = useState([]);
  const [calculosIva, setCalculosIva] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const componentRef = useRef();

  useEffect(() => {
    const getFactura = async () => {
      const emp = await APICALLER.listartablas({
        tables: `empresas,empresa_facturas`,
        on:`id_empresa,id_empresa_factura`,
        fields:`nombre_empresa,propietario_empresa,ruc_empresa,direccion_empresa,telefono_empresa,inicio_timbrado,fin_timbrado,timbrado_factura,nro_datos_factura`
      });
      emp.response === "ok" ? setInfoEmpresa(emp.results[0]) : console.log(emp);

      const res = await APICALLER.listartablas({
        tables: `facturas,clientes,users,monedas`,
        on: `id_cliente,id_cliente_factura,id_user_factura,id_user,id_moneda,id_moneda_factura`,
        linkTo: `id_factura`,
        fields: `ruc_cliente,nombre_cliente,fecha_factura,monto_total_factura,tipo_factura,nro_factura,nombre_moneda,valor_moneda_factura,recibido_factura`,
        equalTo: id,
      });
      let EXENTAS = 0;
      let IVA10 = 0;
      let IVA5 = 0;
      if (res.response === "ok") {
        setInfoFactura(res.results.length > 0 ? res.results[0] : []);
        let res2 = await APICALLER.listartablas({
          tables: `facturas_items,productos`,
          on: `id_producto_factura,id_producto`,
          fields: `cantidad_producto,precio_producto_factura,nombre_producto,iva_producto`,
          linkTo: `id_items_factura`,
          equalTo: id,
        });
        res2.response === "ok"
          ? setItemsFactura(res2.results)
          : console.log(res2);

        res2.results.forEach((item) => {
          let iva =
            item.precio_producto_factura * parseFloat(item.cantidad_producto);
          if (item.iva_producto === "10") {
            IVA10 += iva;
          } else if (item.iva_producto === "5") {
            IVA5 += iva;
          } else {
            EXENTAS += iva;
          }
        });

        setCalculosIva({
          exentas: EXENTAS,
          iva10: IVA10,
          iva5: IVA5,
        });
      } else {
        console.log(res);
      }
      setCargando(false);
    };
    getFactura();
  }, [id]);

  const TabPanel = ({ children, index }) => {
    return tabValue === index && <>{children}</>;
  };

  const handlePrintTicket = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      {cargando ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={tabValue}
            onChange={(e, value) => {
              setTabValue(value);
            }}
          >
            <Tab label="Ticket" />
            <Tab label="Factura" />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <br />
          <Button variant="contained" onClick={handlePrintTicket} startIcon={<Icon>print</Icon>} >Imprimir </Button>
          <div style={{marginTop:"10px", border:"1px solid silver", width:"70mm"}}>
            <FacturasViewTicket
                ref={componentRef}
                calculosIva={calculosIva}
                infoFactura={infoFactura}
                infoEmpresa={infoEmpresa}
                itemsFactura={itemsFactura}
                />
          </div>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <FacturasViewFactura />
          </TabPanel>
        </>
      )}
    </>
  );
};

export default FacturasView;
