import {createContext, useCallback, useContext, useEffect,useState} from 'react'
import { APICALLER } from '../Api/ApiCaller'
import { useLogin } from './LoginProvider'
const Contexto = createContext()

const DatosEmpresaProvider = ({children}) => {

    const [MONEDA_PRINCIPAL,SET_MONEDA_PRINCIPAL] = useState({})
    const {logueado} = useLogin()
    const getDatos = useCallback(async()=>{
        if(logueado){
        let res = await APICALLER.listar({table:'monedas',linkTo:'activo_moneda',equalTo:'1'})
        res.response==="ok" ? SET_MONEDA_PRINCIPAL(res.results[0]) : console.log(res)
        }
    },[logueado])

    useEffect(()=>{
        getDatos()
    },[getDatos])

  return (
    <Contexto.Provider
        value={{MONEDA_PRINCIPAL,SET_MONEDA_PRINCIPAL}}
    >
        {children}
    </Contexto.Provider>
  )
}

export const useDatosEmpresa = ()=>{
    const {MONEDA_PRINCIPAL,SET_MONEDA_PRINCIPAL} = useContext(Contexto)
    return {MONEDA_PRINCIPAL,SET_MONEDA_PRINCIPAL}
}

export default DatosEmpresaProvider
