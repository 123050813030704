import { useState,useEffect,useContext,createContext,useCallback } from "react";
import { APICALLER } from "../../../Api/ApiCaller";


const Contexto = createContext()

const ProductosApartadosProvider = ({children})=>{

    const [cargando,setCargando] = useState(false)
    const [lista,setLista] = useState([])

    const getLista = useCallback(async()=>{
        let res = await APICALLER.listartablas({tables:'productos,productos_apartados,clientes',on:'id_producto_apartado,id_producto,id_cliente,id_cliente_apartado',
        fields:`id_producto,id_producto_apartado,nombre_cliente,cantidad_apartado,codigo_producto,nombre_producto`})
        if(res.response==="ok"){
            setLista(res.results)
        }
        else{
            console.log(res)
        }
    },[])

   useEffect(()=>{
        getLista()
    },[getLista])
    return (
        <Contexto.Provider
            value={{cargando,setCargando,lista,setLista}}
        >
            {children}
        </Contexto.Provider>
    )
}

export const useProductosApartados = ()=>{
    const {cargando,setCargando,lista,setLista} = useContext(Contexto)
    return {cargando,setCargando,lista,setLista}
}

export default ProductosApartadosProvider
