import React from 'react'
import VentasFactura from './VentasFactura'
import DialogBuscaProductos from './DialogBuscaProductos'
import DialogCambioPrecio from './DialogCambioPrecio'
import DialogInsertaPrecio from './DialogInsertaPrecio'
import VentasFinalizar from './VentasFinalizar'
import DialogRegistroCliente from './DialogRegistroCliente'
import Cotizacion from './Cotizacion'
import { useVentas } from './VentasProvider'
import Loading from '../../../Componentes/Loading'

const VentasMain = () => {

  const {cargando} = useVentas();

  if(cargando) return <Loading />

  return (
    <>
        <DialogBuscaProductos />
        <DialogCambioPrecio />
        <DialogInsertaPrecio />
        <DialogRegistroCliente />
        <VentasFinalizar />
        <Cotizacion />
        <VentasFactura />
    </>
  )
}

export default VentasMain
