import {useState} from 'react'
import {Dialog,DialogActions,DialogTitle, TextField,Button, DialogContent, Typography} from '@material-ui/core'
import { useVentas } from './VentasProvider';


const DialogInsertaPrecio = () => {

    const {openInsertaPrecio,setOpenInsertaPrecio, itemsFactura,setItemsFactura,HACERTOTAL,moneda} = useVentas()

    const index = (itemsFactura.length>0 && itemsFactura.length-1)
    const [precioNuevo, setPrecioNuevo] = useState("");
    const [errorPrecio, setErrorPrecio] = useState(false);
    const [errorTxt, setErrorTxt] = useState("");
    const cerrar = ()=>{
        setOpenInsertaPrecio(false)
        setPrecioNuevo("")
        setErrorPrecio(false);
        setErrorTxt("");
    }
    const onChange = e => { 
        const { value } = e.target;
        if (parseFloat(value) > 0) {
            setErrorPrecio(false);
            setErrorTxt("");
            setPrecioNuevo(parseFloat(value));
        } else {
            if (value === "") {
                setPrecioNuevo("");
            } else {
                setErrorPrecio(true);
                setErrorTxt("El precio debe ser mayor de 0");
            }
        } 
    }
    const presionaEnter = (e)=>{
        if (e.key === `Enter` && precioNuevo > 0) {
            insertarPrecio();
          } else {
            setErrorPrecio(true);
            setErrorTxt("El precio debe ser mayor de 0");
          }
    }
    const insertarPrecio = ()=>{
        const itA = [...itemsFactura];
        let i = index;
        let precioNuevoComparar = Math.round((precioNuevo * moneda.valor_moneda) * 100) / 100;
        
        itA[i].total_precio = precioNuevoComparar * itA[i].cantidad_producto;
        itA[i].precio_producto = (precioNuevoComparar);
        itA[i].iva_total_producto = (itA[i].total_precio * itA[i].iva_producto)/(100+parseFloat(itA[i].iva_producto))
        

        let storage = JSON.parse(localStorage.getItem("factura"));
        storage.items[i].total_precio = itA[i].total_precio
        storage.items[i].iva_total_producto = itA[i].iva_total_producto;
        storage.items[i].precio_producto = precioNuevoComparar
        
        localStorage.setItem("factura", JSON.stringify(storage));
        setItemsFactura(itA);
        HACERTOTAL(itA);
        cerrar()
    }

  return (
    <Dialog fullWidth open={openInsertaPrecio} onClose={cerrar}>
      <DialogTitle>Insertar Precio en {moneda.abreviatura_moneda} </DialogTitle>
      <DialogContent>
        <Typography variant="body2">{ itemsFactura.length>0 && itemsFactura[index].nombre_producto }</Typography>
        <br />
        <TextField
          fullWidth
          label="Insertar precio"
          type="number"
          autoFocus
          value={precioNuevo}
          onChange={onChange}
          onKeyPress={presionaEnter}
          helperText={errorTxt}
          error={errorPrecio}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={insertarPrecio}>
          OK
        </Button>
        <Button variant="outlined" onClick={cerrar}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogInsertaPrecio
