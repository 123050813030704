import { makeStyles } from "@material-ui/core";

export const StylesTabla = makeStyles((theme) => ({
    absolute: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(3),
    },
    botones: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    contenedor: {
      
      margin: theme.spacing(2),
    },
    tableContainer: {
        position: "relative",
        overflowX: "unset",
        marginBottom: 25,
      },
      titulo:{
        textAlign:"center",
        width:"100%",
        [theme.breakpoints.up("md")]:
        {
          display:"none"
        }
      },
      tableheader: {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      head: {
        marginBottom: "15px",
      },
      tablecell: {
        position: "sticky",
        top: "63px",
        zIndex: 1,
        backgroundColor: theme.palette.background.default,
      },
      tablelist: {
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          flexDirection: "column",
          border: "1px solid silver",
          margin: "0 0 10px 0",
        },
      },
      tableitem: {
        [theme.breakpoints.down("sm")]: {
          display: "flex",
          justifyContent: "space-between",
        },
      },
      columname: {
        display: "none",
        textTransform: "uppercase",
        fontWeight: "bold",
        [theme.breakpoints.down("sm")]: {
          display: "block",
        },
      },
      inputcantidad: {
        maxWidth: "80px",
      },
      infoFactura:{
        display:`flex`,
        justifyContent:`flex-start`,
        alignItems:`center`,
        height:"100%",
      },
      cargando:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        height:"100%",
      },
      selectsLabels:{
        minWidth:"280px",
      }
  }));


 