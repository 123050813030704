import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const Proximante = ({title}) => {
  return (
    <>
      <Alert severity="info">
        <Typography variant="overline">Próximamente  {title && title} </Typography>
      </Alert>
    </>
  );
};

export default Proximante;
