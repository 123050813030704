import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  DialogContent,DialogActions,DialogTitle,
  Dialog,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Breadcrumbs,
  Icon,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { APICALLER } from "../../Api/ApiCaller";
import Loading from "../../Componentes/Loading";
import { Funciones } from "../../Funciones/Funciones";
import { useLogin } from "../../Contextos/LoginProvider";
import { Styles } from "./Styles";


//************************* */
const ClientesForm = (props) => {
  const { id } = props.match.params;
  
  const {token_user} = useLogin()


  const classes = Styles();
  let initialState = {
    nombre_cliente: "",
    telefono_cliente: "",
    ruc_cliente: "",
    email_cliente: "",
    direccion_cliente: "",
    tipo_cliente: "3",
  };
  const [formulario, setFormulario] = useState(initialState);
  const [cargando, setCargando] = useState(true);

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormulario({ ...formulario, [name]: value });
  };

  const enviarNuevoCLiente = async (e) => {
    e.preventDefault();
    let call = null;
    let textsucces = "";
    if (id) {
      call = await APICALLER.update({
        table: `clientes`,
        data: formulario,
        token: token_user,
        namecolumn: "id_cliente",
        equalTo: id,
      });
      textsucces = "Actualizado";
    } else {
      call = await APICALLER.insert({
        table: `clientes`,
        data: formulario,
        token: token_user,
      });
      textsucces = "Agregado";
    }
    
    if (call.response === "ok") {
      swal({
        icon: "success",
        title: textsucces,
      }).then(() => Funciones.goto(`/clientes`));
    }
  };

  useEffect(() => {
    const consultar = async () => {
      if (id) {
        let config = { table: "clientes", linkTo: "id_cliente", equalTo: id };
        let res = await APICALLER.listar(config);
        setFormulario({ ...res.results[0] });
      }
      setCargando(false);
    };
    consultar();
  }, [id]);

  if (cargando) {
    return <Loading />;
  }
  
  return (
    <Dialog fullWidth open={true} maxWidth='md' onClose={ () => Funciones.goto(`/clientes`) }  >
      <form onSubmit={enviarNuevoCLiente}>
        <DialogTitle>
            {id ? `Editar` : `Nuevo`} cliente
        </DialogTitle>
          <DialogContent dividers >
          <Breadcrumbs separator="›">
              <Typography variant="overline">Clientes</Typography>
              <Typography variant="overline">Nuevo</Typography>
            </Breadcrumbs>

            <TextField
            className={classes.textfield}
              autoFocus
              onChange={onChange}
              name="nombre_cliente"
              required
              value={formulario.nombre_cliente && formulario.nombre_cliente}
              fullWidth
              label="Nombre completo"
              variant="outlined"
            />
            <TextField
            className={classes.textfield}
              fullWidth
              required
              onChange={onChange}
              name="ruc_cliente"
              value={formulario.ruc_cliente}
              label="Ruc o CI"
              variant="outlined"
            />
            <TextField
            className={classes.textfield}
              name="telefono_cliente"
              label="Número de teléfono"
              onChange={onChange}
              value={formulario.telefono_cliente!==null ? formulario.telefono_cliente : ``}
              fullWidth
              variant="outlined"
            />

            <TextField
            className={classes.textfield}
              fullWidth
              label="Dirección"
              onChange={onChange}
              name="direccion_cliente"
              value={formulario.direccion_cliente!==null ? formulario.direccion_cliente : ``}
              variant="outlined"
            />

            <TextField
            className={classes.textfield}
              fullWidth
              onChange={onChange}
              name="email_cliente"
              value={formulario.email_cliente!==null ? formulario.email_cliente : ``}
              label="Correo Electrónico"
              variant="outlined"
            />

            <FormControl component="fieldset">
              <FormLabel component="legend">Tipo:</FormLabel>
              <RadioGroup
                name="tipo_cliente"
                value={formulario.tipo_cliente}
                onChange={onChange}
              >
                <FormControlLabel
                  value={3}
                  control={<Radio checked={formulario.tipo_cliente === "3"} />}
                  label="Casual"
                />
                <FormControlLabel
                  value={2}
                  control={<Radio checked={formulario.tipo_cliente === "2"} />}
                  label="Minorista"
                />
                <FormControlLabel
                  value={1}
                  control={<Radio checked={formulario.tipo_cliente === "1"} />}
                  label="Mayorista"
                />
              </RadioGroup>
            </FormControl>
            
          </DialogContent>
          <DialogActions>
          <div className={classes.botones}>
              <Button
                size="large"
                startIcon={<Icon>save</Icon>}
                variant="outlined"
                color="primary"
                type="submit"
              >
                GUARDAR
              </Button>
              <Button onClick={() => Funciones.goto(`/clientes`)} size="large" variant="outlined" color="default">
                CANCELAR
              </Button>
            </div>
          </DialogActions>
      </form>
    </Dialog>
  );
};

export default ClientesForm;
