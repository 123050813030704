import { BASEURL } from "../../VariablesGlobales/globales";

export const ListaDashboard = [
    {
        id_permiso:"1",
        text:"Realizar venta",
        icon:"sell",
        bgcolor:"success.main",
        color:"#FFF",
        url:`${BASEURL}/ventas`
    },
    {
        id_permiso:"27",
        text:"Cotización",
        icon:"monetization_on",
        bgcolor:"#45007d",
        color:"#FFF",
        url:`${BASEURL}/monedas`
    },
    {
        id_permiso:"2",
        text:"Productos",
        icon:"inventory_2",
        bgcolor:"error.main",
        color:"error.contrastText",
        url:`${BASEURL}/productos`
    },
    {
        id_permiso:"13",
        text:"Notas",
        icon:"receipt",
        bgcolor:"text.disabled",
        color:"#FFF",
        url:`${BASEURL}/notas`
    },
    {
        id_permiso:"10",
        text:"Facturas",
        icon:"assignment",
        bgcolor:"#7db346",
        color:"#FFF",
        url:`${BASEURL}/facturas`
        
    },
    {
        id_permiso:"14",
        text:"Entregas",
        icon:"delivery_dining",
        bgcolor:"#f27474",
        color:"primary.contrastText",
        url:`${BASEURL}/entregas`
    },
    {
        id_permiso:"12",
        text:"Presupuestos",
        icon:"receipt_long",
        bgcolor:"primary.main",
        color:"primary.contrastText",
        url:`${BASEURL}/ventas`
    },
    {
        id_permiso:"18",
        text:"Movimientos",
        icon:"leaderboard",
        bgcolor:"#4caf50",
        color:"#FFF",
        url:`${BASEURL}/movimientos`
    },
    {
        id_permiso:"17",
        text:"Cajas",
        icon:"savings",
        bgcolor:"#ffa500",
        color:"#FFF",
        url:`${BASEURL}/cajas`
    },
    {
        id_permiso:"21",
        text:"Clientes",
        icon:"people",
        bgcolor:"secondary.main",
        color:"secondary.contrastText",
        url:`${BASEURL}/clientes`
    },
    {
        id_permiso:"26",
        text:"Inventario",
        icon:"handyman",
        bgcolor:"info.main",
        color:"info.contrastText",
        url:`${BASEURL}/clientes`
    },
    
    {
        id_permiso:"27",
        text:"Usuarios",
        icon:"manage_accounts",
        bgcolor:"#009688",
        color:"#FFF",
        url:`${BASEURL}/usuarios`
    },

    
    
]