import React from "react";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Icon,
  Hidden,
  Tooltip,
} from "@material-ui/core/";
import { useTheme } from "../Contextos/ThemeProviderContext";
import UserMenu from "./UserMenu";
import Notifications from "./Notifications";



export default function NavBar({ AbrirMenu, AbrirMenuGrande,open }) {



const { themeMode, changeTheme, drawerWidth } = useTheme();
  const estilos = makeStyles((theme) => ({

    appBar: {
      [theme.breakpoints.up("md")]:{
        width: "100%",
      },
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    // cuando está abierto
    appBarShift: {
      [theme.breakpoints.down("sm")]:{
        width: "100%",
      },
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },

    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {},
    },

    titulos:{
      display:"flex",
      width:"100%",
      justifyContent:"flex-end",
      alignItems:"center",
      
    },
    //NOMBRE DE USUARIO
    nombreUsuario:{
      [theme.breakpoints.down("sm")]: {
        display:"none",
      },
    },


  }));
  const classes = estilos();

  return (
    <AppBar position="fixed" color="inherit" className={ open ? classes.appBarShift : classes.appBar}>
      <Toolbar>
        <Hidden smDown>
          <IconButton className={classes.menuButton} onClick={AbrirMenuGrande}>
            <Icon>menu</Icon>
          </IconButton>
        </Hidden>

        <Hidden mdUp>
          <IconButton className={classes.menuButton} onClick={AbrirMenu}>
            <Icon>menu</Icon>
          </IconButton>
        </Hidden>

        <div className={classes.titulos}>

          <div>
            <Notifications />
            <Tooltip title="Cambiar de tema" >
              <IconButton onClick={changeTheme} >
                <Icon color="action" >
                  { themeMode==="dark" ? `nights_stay` : `tungsten` }
                </Icon>
              </IconButton>
              </Tooltip>
            <UserMenu />
          </div>
        </div>

      </Toolbar>
      
    </AppBar>


  );
}
