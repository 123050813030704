import { Dialog, DialogContent, Icon,IconButton,Grid,TextField, DialogActions,Button, DialogTitle, Typography } from "@material-ui/core";
//import InputNumberFormat from "../../../Componentes/InputNumberFormat";

import { StylesGenerales } from "../../../Styles/StylesGenerales";
import { useCompras } from "./ComprasProvider";

const DialogCompra = () => {
  const classes = StylesGenerales();
  const {dialogCompra,setDialogCompra,formulario, setFormulario, itemsFactura,setItemsFactura,inputCodigo,Hacer_total} = useCompras()

  const cerrar = () => {
    inputCodigo.current.value=""
    setFormulario({codigo_producto:"",
    nombre_producto:"",
    preciom_producto:"",
    precio_producto:"",
    costo_producto:"",
    stock_nuevo:"",
    stock_producto:"",
  })
    setDialogCompra(false)
    inputCodigo.current.focus()
  };

  const Validar = ()=>{
    if(formulario.preciom_producto===""){
      return false
    }
    if(formulario.precio_producto===""){
      return false
    }
    if(formulario.costo_producto===""){
      return false
    }
    if(formulario.stock_producto===""){
      return false
    }
    return true;
  }

  const AgregarAFactura = ()=>{
    if(Validar()){
      let array = [...itemsFactura]
      array.push(formulario)
      Hacer_total(array)
      setItemsFactura(array)
      cerrar()
    }
  }

  const onchange = (e)=>{
    const {name,value} = e.target
    setFormulario({...formulario,[name]:value})
  }

  return (
    <Dialog fullWidth  open={dialogCompra} onClose={cerrar}>
      <DialogTitle>
        <div className={classes.titulodialog}>
          Datos de compra
          <IconButton onClick={cerrar}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="overline">Nombre y código</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField 
            fullWidth  disabled value={formulario.codigo_producto} name="codigo_producto" label="Código"  />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField fullWidth  disabled value={(formulario.nombre_producto).toUpperCase()} name="nombre_producto" label="Descripción del producto"  />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline">Información del producto</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField 
            autoFocus fullWidth variant="outlined" type="number" onChange={onchange}  value={formulario.stock_nuevo} name="stock_nuevo" label="Stock o cantidad comprada" 
            InputProps={{inputProps: { min: 0}}}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth variant="outlined" type="number" onChange={onchange} name="costo_producto" value={formulario.costo_producto}  label="Precio de compra o costo"  
              InputProps={{inputProps: { min: 0}}}
            />
          </Grid> 
          <Grid item xs={12} md={6}>
            <TextField fullWidth variant="outlined" type="number" onChange={onchange}  value={formulario.precio_producto} name="precio_producto" label="Precio de venta" 
              InputProps={{inputProps: { min: 0}}}
              helperText="Precio con iva incluido"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth variant="outlined" type="number" onChange={onchange}  value={formulario.preciom_producto} name="preciom_producto" label="Precio de venta mayorista" 
              InputProps={{inputProps: { min: 0}}}
              helperText="Precio con iva incluido"
            />
          </Grid> 
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={AgregarAFactura}>Agregar</Button>
        <Button variant="outlined" onClick={cerrar}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCompra;
