import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { APICALLER } from "../../../Api/ApiCaller";
import { useLogin } from "../../../Contextos/LoginProvider";

const Contexto = createContext();

const AperturaCierreProvider = ({ children }) => {
  const [lista, setLista] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [dialogCierre, setDialogCierre] = useState(false);
  const [dialogApertura, setDialogApertura] = useState(false);
  const { permisos, id_user } = useLogin();
  const [IDCajaCierre, setIDCajaCierre] = useState(null);
  const [IDCajaArqueo, setIDCajaArqueo] = useState(null);
  const [fechaAperturaCaja, setFechaAperturaCaja] = useState("");
  const [todosDatosCaja, setTodosDatosCaja] = useState({});
  const [arqueo, setArqueo] = useState([]);
  const [totalSumaMonedasArqueo, setTotalSumaMonedasArqueo] = useState(0);

  const [openDialogArqueo, setOpenDialogArqueo] = useState(false);
  const date = new Date();
  const fecha_y_horario =
    date.getFullYear().toString() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, 0) +
    "-" +
    date.getDate().toString().padStart(2, 0) +
    " " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds();

  const getListaCajasAbiertas = useCallback(async () => {
    setCargando(true)
    // EL ID DEL PERMISO DE ESTA SECCION ES 36
    let autorizacion = permisos.findIndex(
      (elem) => elem.id_permiso_permiso === "36"
    );
    var res;
    if (autorizacion < 0) {
      res = await APICALLER.listartablas({
        tables: "cajas,cajas_users",
        on: "id_caja,id_caja_caja",
        linkTo: "estado_caja,id_user_caja",
        equalTo: `1,${id_user}`,
      });
    } else {
      res = await APICALLER.listar({
        table: "cajas",
        linkTo: "estado_caja",
        equalTo: "1",
      });
    }
    res.response === "ok" ? setLista(res.results) : console.log(res);
    setCargando(false)
  }, [permisos, id_user]);

  useEffect(() => {
    setCargando(false);
    getListaCajasAbiertas();
  }, [getListaCajasAbiertas]);
  return (
    <Contexto.Provider
      value={{
        lista,
        setLista,
        cargando,
        setCargando,
        dialogCierre,
        setDialogCierre,
        dialogApertura,
        setDialogApertura,
        getListaCajasAbiertas,
        IDCajaCierre,
        setIDCajaCierre,
        fecha_y_horario,
        openDialogArqueo,
        setOpenDialogArqueo,
        IDCajaArqueo,
        setIDCajaArqueo,
        fechaAperturaCaja,
        setFechaAperturaCaja,
        todosDatosCaja,
        setTodosDatosCaja,
        arqueo,
        setArqueo,
        totalSumaMonedasArqueo,
        setTotalSumaMonedasArqueo,
      }}
    >
      {children}
    </Contexto.Provider>
  );
};

export const useAperturaCierre = () => {
  const {
    lista,
    setLista,
    cargando,
    setCargando,
    dialogCierre,
    setDialogCierre,
    dialogApertura,
    setDialogApertura,
    getListaCajasAbiertas,
    IDCajaCierre,
    setIDCajaCierre,
    fecha_y_horario,
    openDialogArqueo,
    setOpenDialogArqueo,
    IDCajaArqueo,
    setIDCajaArqueo,
    fechaAperturaCaja,
    setFechaAperturaCaja,
    todosDatosCaja,
    setTodosDatosCaja,
    arqueo,
    setArqueo,
    totalSumaMonedasArqueo,
    setTotalSumaMonedasArqueo,
  } = useContext(Contexto);
  return {
    lista,
    setLista,
    cargando,
    setCargando,
    dialogCierre,
    setDialogCierre,
    dialogApertura,
    setDialogApertura,
    getListaCajasAbiertas,
    IDCajaCierre,
    setIDCajaCierre,
    fecha_y_horario,
    openDialogArqueo,
    setOpenDialogArqueo,
    IDCajaArqueo,
    setIDCajaArqueo,
    fechaAperturaCaja,
    setFechaAperturaCaja,
    todosDatosCaja,
    setTodosDatosCaja,
    arqueo,
    setArqueo,
    totalSumaMonedasArqueo,
    setTotalSumaMonedasArqueo,
  };
};

export default AperturaCierreProvider;
