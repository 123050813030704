import {createContext,useContext, useState} from 'react'

const Contexto = createContext()


const PresupuestosProvider = ({children})=>{

    const [lista,setLista] = useState([])
    const [cargando,setCargando] = useState(true)
    return (
        <Contexto.Provider
            value={{lista,setLista,cargando,setCargando}}
        >
            {children}
        </Contexto.Provider>
    )
}

export const usePresupuestos = ()=>{

    const {lista,setLista,cargando,setCargando} = useContext(Contexto)
    return {lista,setLista,cargando,setCargando}
}

export default PresupuestosProvider