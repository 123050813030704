import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, List, ListItem,Checkbox, FormControlLabel,
  IconButton,Icon,LinearProgress
} from "@material-ui/core";
import {useState,useEffect, useCallback} from 'react'
import { APICALLER } from "../../../Api/ApiCaller";
import { useCaja } from "./CajaContext";
import { useLogin } from "../../../Contextos/LoginProvider";
import { StylesGenerales } from "../../../Styles/StylesGenerales";

const DialogAsignar = () => {
  const classes = StylesGenerales()
  const {token_user} = useLogin()
  const {openModalAsignar,setOpenModalAsignar,CajaAsignacionID, CajaAsignacionNombre} = useCaja()
  const [cargando,setCargando] = useState(true)

  const [listaUsuariosAsignados,setListaUsuariosAsignados] = useState([])

  const cerrar = ()=> setOpenModalAsignar(false)

  const Enviar = async(check,id,index)=>{
    setCargando(true)
    let array = [...listaUsuariosAsignados]
    array[index].checked = !check;
    setListaUsuariosAsignados(array)
    if(check===false){
      let data = {id_user_caja:id, id_caja_caja:CajaAsignacionID}
      let res = await APICALLER.insert({table:`cajas_users`,data,token:token_user});
      res.response!=="ok" && console.log(res)
    }
    else{
        let res = await APICALLER.delete({token:token_user,table:`cajas_users`,namecolumn:`id_user_caja,id_caja_caja`,equalTo:`${id},${CajaAsignacionID}`})
        res.response!=="ok" && console.log(res)
    }
    setCargando(false)
  }


  const getListaUser = useCallback( async()=>{
    let res = await APICALLER.listausers({token:token_user})
    let asignados =  await APICALLER.listartablas({
      tables:'cajas_users,users',
      fields:"nombre_user,id_user_caja",
      on:'id_user_caja,id_user', linkTo:"id_caja_caja",equalTo:CajaAsignacionID})
    
    if(res.response==="ok" && asignados.response==="ok"){
      let array = [];
      let usuarios = res.results
      let asig = asignados.results
      usuarios.forEach(element => {
          let exist = asig.findIndex(item=> item.id_user_caja===element.id_user)
          array.push({
            checked: exist<0 ? false : true,
            id_user : element.id_user,
            nombre_user: element.nombre_user
          })
      });
      setListaUsuariosAsignados(array)
    }
    setCargando(false)
  },[token_user,CajaAsignacionID])

  

  
  useEffect(()=>{
    getListaUser()
  },[getListaUser])



  return (
    <Dialog fullWidth open={openModalAsignar} onClose={cerrar}>

      <DialogTitle>
        <div className={classes.titulodialog}>
          <div>Permiso a usuarios para : {CajaAsignacionNombre}</div>
          <IconButton onClick={cerrar}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
          {
              cargando && <LinearProgress />
          }
      <List>
          { listaUsuariosAsignados.map((item,index) => (
            <ListItem key={item.id_user}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={()=>{Enviar(item.checked,item.id_user,index)}}
                    checked={item.checked}
                  />
                }
                label={item.nombre_user}
              /> 
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={cerrar}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogAsignar
