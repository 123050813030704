import React from 'react'
import ProductosApartadosLista from './ProductosApartadosLista'
import ProductosApartadosProvider from './ProductosApartadosProvider'

const ProductosApartados = () => {
  return (
    <ProductosApartadosProvider>
      <ProductosApartadosLista />
    </ProductosApartadosProvider>
  )
}

export default ProductosApartados
