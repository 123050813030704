import React, { createContext, useContext, useState, useEffect,useCallback } from "react";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
import { APICALLER } from "../../../Api/ApiCaller";
import { useLogin } from "../../../Contextos/LoginProvider";
import { Funciones } from "../../../Funciones/Funciones";

const ProductosContext = createContext();

const ProductosProvider = ({ children }) => {
  const { token_user } = useLogin();
  const location = useLocation();
  const query = location.search ? new URLSearchParams(location.search) : 0;
  const [page, setPage] = useState(
    query && query.get("p") && !isNaN(query.get("p"))
      ? parseInt(query.get("p"))
      : 0
  );

  const [limite, setLimite] = useState(60);
  const [countTotal,setCountTotal] = useState(0); 
  const [cargando, setCargando] = useState(true);
  const [listaCategorias, setListaCategorias] = useState([]);
  const [inputSearch, setInputSearch] = useState("");
  const [lista, setLista] = useState([]);



  const buscarRegistro = async () => {
    setCargando(true);
    let config = {
      tables: "productos,categorias",
      keywords: inputSearch,
      like: `codigo_producto,nombre_producto`,
      fields:
        "id_producto,nombre_producto,nombre_categoria,codigo_producto,precio_producto,costo_producto,preciom_producto,stock_producto,iva_producto",
      on: "id_categoria_producto,id_categoria",
      startAt: page,
      endAt: limite,
    };
    let res = await APICALLER.search(config);
    if (res.results.length > 0 && res.response === "ok") {
      setLista(res.results);
      Funciones.goto(`/productos`);
    }
    setCargando(false);
  };

  const borrarRegistro = (id, nombre) => {
    //console.log(id,nombre);
    swal({
      icon: "warning",
      title: "Borrar?",
      text: `${nombre}`,
      buttons: [`Cancelar`, `Ok`],
      dangerMode: true,
    }).then(async (e) => {
      if (e) {
        const res = await APICALLER.delete({
          table: `productos`,
          namecolumn: `id_producto`,
          equalTo: id,
          token: token_user,
        });
        if (res.response === "ok") {
          let array = [...lista];
          let index = array.findIndex((e) => e.id_producto === id);
          array.splice(index, 1);
          setLista(array);
        } else {
          console.log(res);
        }
      }
    });
  };
  const getCountTotal =  useCallback(
    async() => {
      let res = await APICALLER.count({tables:`productos`,field:`id_producto`});
      res.response==="ok" ? setCountTotal(res.count) : console.log(res)
    },[setCountTotal])

  useEffect(() => {
    setCargando(true);
    const getLista = async () => {
      

        let data = {
          tables: "productos,categorias",
          fields:"id_producto,nombre_producto,nombre_categoria,codigo_producto,precio_producto,costo_producto,preciom_producto,stock_producto,iva_producto",
          on: "id_categoria_producto,id_categoria",
          startAt: page,
          endAt: limite,
        };
        const res = await APICALLER.listartablas(data);
        if(res.response==="ok"){
          if (res.results.length > 0) {
            setLista(res.results);
            page === 0
              ? Funciones.goto(`/productos`)
              : Funciones.goto(`/productos?p=${page}`);
          } else {
            //page>0 && setPage(parseInt(page) - parseInt(limite));
            
          }
        }
        else{
          console.log(res)
        }
        setCargando(false);
      
    };
    getLista();
    getCountTotal();
  }, [limite, page,getCountTotal]);

  return (
    <ProductosContext.Provider
      value={{
        cargando,
        setCargando,
        inputSearch,
        setInputSearch,
        lista,
        setLista,
        page,
        setPage,
        limite,
        setLimite,
        buscarRegistro,
        borrarRegistro,
        listaCategorias,
        setListaCategorias,countTotal,setCountTotal
      }}
    >
      {children}
    </ProductosContext.Provider>
  );
};

export const useProductos = () => {
  const {
    cargando,
    setCargando,
    inputSearch,
    setInputSearch,
    lista,
    setLista,
    page,
    setPage,
    limite,
    setLimite,
    buscarRegistro,
    borrarRegistro,
    listaCategorias,
    setListaCategorias,countTotal,setCountTotal
  } = useContext(ProductosContext);

  return {
    cargando,
    setCargando,
    inputSearch,
    setInputSearch,
    lista,
    setLista,
    page,
    setPage,
    limite,
    setLimite,
    buscarRegistro,
    borrarRegistro,
    listaCategorias,
    setListaCategorias,countTotal,setCountTotal
  };
};

export default ProductosProvider;
