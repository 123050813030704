import {  makeStyles } from "@material-ui/core";
export const Styles = makeStyles((theme) => ({
    absolute: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(3),
    },
    botones: {
      "& > *": {
        margin: theme.spacing(0, 1),
      },
    },
    textfield: {
      marginTop: theme.spacing(1),
      marginBottom:theme.spacing(1),
      marginRight:"3px",
      marginLeft:"3px",
    },
    
    option: {
      fontSize: 15,
      "& > span": {
        marginRight: 10,
        fontSize: 18,
      },
    },
    titulodialog:{
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center"
    },
    inputs:{
      display:"flex",
      alignItems:"center",
      "& > *":{
        marginRight:"25px",
      }
    },
    loaderSend:{
      margin:theme.spacing(1,0,2,0),
    },
    page:{
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      "& > *": {
          margin: theme.spacing(0, 1),
        },
    },
    tipoProducto:{
      display:`flex`,
      width:"100%",
      marginLeft:theme.spacing(2),
    },
    inputsProducto:{
      minWidth:300,
      marginTop:theme.spacing(2),
      marginRight:theme.spacing(2),
    },
    labelSelect :{
      marginLeft:"10px",
    },
    inputsNumeros:{
      marginTop:theme.spacing(2),
    }
  }));

