import Tablas from "../../../Componentes/Tablas"
import { useAperturaCierre } from "./AperturaCierreProvider"
import { Button } from "@material-ui/core"
import {StylesGenerales} from '../../../Styles/StylesGenerales'

const AperturaCierreLista = () => {

    const classes = StylesGenerales()
    const {lista,setDialogApertura,setOpenDialogArqueo,setIDCajaArqueo,setFechaAperturaCaja,setTodosDatosCaja,cargando} = useAperturaCierre()
    

    const aperturaDialog = ()=>{ setDialogApertura(true)}

    const openDialogArqueo = (id,filaProps)=>{ 
        setOpenDialogArqueo(true); 
        setIDCajaArqueo(id) 
        setFechaAperturaCaja(filaProps.fecha_apertura)
        setTodosDatosCaja(filaProps);
    } 

    const columns = [
        {
            field:"id_caja",
            headerName:"ID"
        },
        {
            field:"nombre_caja",
            headerName:"Caja"
        },
        {
            field:"monto_caja",
            headerName:"Monto",
            isNumber:true,
        },
        {
            field:"fecha_apertura",
            headerName:"Fecha Apertura"

        }
    ]

    const Acciones = ({filaProps,id})=>(
    <div className={classes.botones}>
        <Button variant="outlined" onClick={()=>{openDialogArqueo(id,filaProps)}} color="primary">
            Arqueo y cierre
        </Button>
    </div>
    )

    const search = (<Button variant="outlined" color="primary" onClick={ ()=>{ aperturaDialog ()}} >Apertura</Button>)

  return (
    <>

        <Tablas 
            nombretabla="Cierre de caja"
            icono="save"
            bgicono="#303f9f"
            search={search}
            namecolumnID="id_caja"
            columnas={columns}
            filas={lista}
            Acciones={Acciones}
            cargando={cargando}
        />
    </>
  )
}

export default AperturaCierreLista
