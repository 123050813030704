import Proximante from "../../../Componentes/Proximante"
import PresupuestosProvider from "./PresupuestosProvider"


const Presupuestos = () => {
  return (
    <PresupuestosProvider>
      <Proximante />
    </PresupuestosProvider>
  )
}

export default Presupuestos
