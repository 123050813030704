import {useContext, createContext,useState,useEffect} from 'react'


const Contexto = createContext()

const MovimientosProvider = ({children}) => {


    const [cargando,setCargando] = useState(true)
    let date = new Date()
    let fecha = date.getFullYear().toString()+"-" +(date.getMonth() + 1).toString().padStart(2, 0) +"-" +date.getDate().toString().padStart(2, 0);

    const [desdeFecha,setDesdeFecha] = useState(fecha)
    const [hastaFecha,setHastaFecha] = useState(fecha)
    const [openDialog,setOpenDialog] = useState(false)
    const [lista,setLista] = useState([])


    useEffect(()=>{
        setCargando(false)
    },[])


  return (
    <Contexto.Provider value={{cargando,setCargando,desdeFecha,setDesdeFecha,hastaFecha,setHastaFecha,lista,setLista,openDialog,setOpenDialog,fecha}}>
        {children}
    </Contexto.Provider>
  )
}

export const useMovimientos = ()=>{

    const {cargando,setCargando,desdeFecha,setDesdeFecha,hastaFecha,setHastaFecha,lista,setLista,openDialog,setOpenDialog,fecha} = useContext(Contexto)
    return {cargando,setCargando,desdeFecha,setDesdeFecha,hastaFecha,setHastaFecha,lista,setLista,openDialog,setOpenDialog,fecha}
}

export default MovimientosProvider
