import { useProductos } from "./ProductosProvider";
import { Styles } from "./Styles";
import { Button } from "@material-ui/core";

const ProductosListaPager = () => {
  const { page, setPage, limite, countTotal } = useProductos();
  const classes = Styles();
  const siguiente = () => {
    let i = parseInt(page) + parseInt(limite);
    setPage(i);
  };
  const atras = () => {
    if (page > 0) {
      let i = parseInt(page) - parseInt(limite);
      setPage(i);
    }
  };

  return (
    
    <div className={classes.page}>
        {page>0 &&(<Button 
        onClick={atras}
        variant="outlined" >
          Atrás
        </Button>)}
        {
          ((countTotal>page) && ((page+limite)<countTotal) ) &&      
        <Button variant="outlined" 
        onClick={siguiente} >
          Siguiente
          </Button>
        }
      </div>
  );
};

export default ProductosListaPager;
