import {Alert} from '@material-ui/lab'

import { useState} from 'react'
import {Button, Icon, Tooltip, CircularProgress, Snackbar, TextField} from '@material-ui/core'
import Tablas from "../../../Componentes/Tablas";
import { StylesGenerales } from '../../../Styles/StylesGenerales';
import { green } from '@material-ui/core/colors';
import { useEntregas } from './EntregasProvider';
import { useLogin } from '../../../Contextos/LoginProvider';
import { APICALLER } from '../../../Api/ApiCaller';




const EntregasView = () => {

  const classes = StylesGenerales()

  const [cargandoCheck,setCargandoCheck] = useState(false)
  const [openSnack,setOpenSnack] = useState(false)
  const {lista,setLista} = useEntregas()
  const {token_user} = useLogin()
  const FilterData = [...lista];

  if (lista.length < 1) {
    return(
    <Alert icon={<Icon>mood_bad</Icon>}>
      No hay facturas
    </Alert>)
  }

  
  let INFO = `FACTURA NRO: ${lista[0].nro_factura}  -  CLIENTE: ${lista[0].nombre_cliente}  - DOC: ${lista[0].ruc_cliente}`;

  const columnas = [
    {
      field: "codigo_producto",
      headerName: "CODIGO",
    },
    {
      field: "nombre_producto",
      headerName: "PRODUCTO",
    },
    {
      field:"entregado_item",
      headerName:"Entregado",
      items: {0:"No",1:"ENTREGADO"},
      comparaItem: "entregado_item",
    },
    {
      field: "cantidad_producto",
      headerName: "CANTIDAD",
    },
  ];


  const closeSnack = ()=>{ setOpenSnack(false)}

  const Chequear = async(fila)=>{
    setCargandoCheck(true)
    setOpenSnack(true)
    let arr = [...lista];
    let id = fila.id_producto
    let index = arr.findIndex((e) => e.id_producto === id);
    let res = await APICALLER.update({table:'facturas_items',data:{entregado_item:"1"},token:token_user,namecolumn:'id_producto_factura',equalTo:id})
    if(res.response==="ok"){
      setCargandoCheck(false)
      arr.splice(index, 1);
      setLista(arr)
    }
    
  }

  const Acciones = ({ filaProps }) => 
  (<div className={classes.botones}>
    <TextField label="Codigo" />
    {
      cargandoCheck ? <CircularProgress size={18} /> :
      <Tooltip title="Chequear" arrow><Button color="primary" variant="outlined" startIcon={<Icon style={{color: green[500]}}>check_circle</Icon>} onClick={()=>{Chequear(filaProps)}} >Chequear</Button></Tooltip>
    }
  </div>)

  return (
    <>
    <Snackbar open={openSnack} autoHideDuration={1500} onClose={closeSnack} anchorOrigin={{horizontal:'right',vertical:'bottom'}} >
      <Alert onClose={closeSnack} severity="success">
        PRODUCTO CHEQUEADO
      </Alert>
    </Snackbar>
    <Tablas
      columnas={columnas}
      filas={FilterData}
      nombretabla={INFO}
      subtitle="Verifique bien los productos para entregar, y presione el botón de check"
      namecolumnID="codigo_producto"
      Acciones={Acciones}
    />
    </>
  );
};

export default EntregasView;
