import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  CircularProgress,
  Typography,
  InputAdornment,
  Icon,
  makeStyles,
  IconButton,
  Box,
} from "@material-ui/core";
import { motion } from "framer-motion";
import { Alert } from "@material-ui/lab";
import Loading from "../../Componentes/Loading";
import { useLogin } from "../../Contextos/LoginProvider";
import { Redirect } from "react-router";
import { APPNAME, BASEURL } from "../../VariablesGlobales/globales";
//import Headertitles from "../../../Componentes/Headertitles";

const estilos = makeStyles((theme) => ({

  formulario: {
    padding: 20,
    border: "1px solid whitesmoke",
    borderRadius: "10px",
  },
  contenedor: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems:"center",
    minHeight:`calc(100vh - 20px)`,
  },
  avatar:{
    display:"flex",
    justifyContent:"center",
    marginTop:-theme.spacing(6),
    width:"100%"
  },
  largeIcon: {
    width: "100%",
    height: "100%",
    textAlign:"center",
    marginTop:"-35px",
  },
  ingreso:{
    margin:"10px auto",
    textAlign:"center"
  },
  icono:{
    fontSize:100,
    color:"#ff4e00",

  }
}));

export default function LoginForm() {
  const { Loguearse, logueado, mensajeAlerta, cargandoMensaje, cargando } = useLogin();

  const classes = estilos();

  /*const fecha = date.getFullYear().toString() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, 0) +
    "-" +
    date.getDate().toString().padStart(2, 0); */
  const inicialValues = () => ({ username_user: "", password_user: "" });
  
  const [values, setValues] = useState(inicialValues);

  const [TypePassInput,setTypePassInput] = useState("password")

  const changeInputType = ()=> setTypePassInput( TypePassInput==="text" ? "password" : "text" );

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const enviarDatos = (e) => {
    e.preventDefault();
    Loguearse(values);
  };

  if (cargando) {
    return <Loading />;
  }

  if (logueado) {
    return <Redirect to={BASEURL + "/dashboard"} />;
  }

  return (

    <motion.div
      initial={{ opacity: 0, y: "-50px" }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      className={classes.contenedor}
    >
      <Container  maxWidth="xs"  >
        <Box boxShadow={3} className={classes.formulario}>
        <div className={classes.avatar}>
          <div className={classes.largeIcon}>
            <Icon className={classes.icono}>local_fire_department</Icon>
          </div>
        </div>
        <Typography variant="h6" className={classes.ingreso} >{APPNAME}</Typography>
        {mensajeAlerta && <Alert severity="error" icon={false} variant="outlined" > {mensajeAlerta}</Alert>}
        <form onSubmit={enviarDatos}>

          <TextField
            value={values.username_user}
            onChange={handleOnchange}
            margin="normal"
            variant="outlined"
            required
            autoComplete="off"
            id="username_user"
            name="username_user"
            autoFocus
            label="Usuario"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon color="disabled">perm_identity</Icon>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            value={values.password_user}
            onChange={handleOnchange}
            margin="normal"
            variant="outlined"
            required
            id="password_user"
            name="password_user"
            label="Contraseña"
            fullWidth
            type={TypePassInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon color="disabled">lock</Icon>
                </InputAdornment>
              ),endAdornment:(
                <InputAdornment position="end">
                  <IconButton onClick={changeInputType}><Icon>{TypePassInput==="text" ? `visibility_off` : `visibility`}</Icon></IconButton>
                </InputAdornment>
              )
            }}
          />
          {cargandoMensaje ? (
            <CircularProgress />
          ) : (
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              size="large"
            >
              Ingresar
            </Button>
          )}
        </form>
        </Box>
      </Container>
    </motion.div>

  );
}
