import {useState} from 'react'
import Tablas from '../../../Componentes/Tablas'
import { useFacturas } from './FacturasProvider'
import {Button, Icon, TextField, Select,FormControl, InputLabel, MenuItem, Grid} from '@material-ui/core'
import { Funciones } from '../../../Funciones/Funciones'
import { StylesGenerales } from '../../../Styles/StylesGenerales'
import { useDatosEmpresa } from '../../../Contextos/DatosEmpresaProvider'
const FacturasLista = () => {
  const classes = StylesGenerales()
  const {lista,setDesdeFecha,setHastaFecha,cargando,total,setOpenDialogEstado,setFormulario,fecha,setFiltro} = useFacturas()
  const {MONEDA_PRINCIPAL} = useDatosEmpresa()

  const [desde,setDesde] = useState(fecha)
  const [hasta,setHasta] = useState(fecha)
  const [tipoFiltro,setTipoFiltro] = useState("")
  

  const changeDatadesde= e=> setDesde( e.target.value)
  const changeDatahasta= e=> setHasta( e.target.value)
  
  const Filtrar = ()=>{
    setHastaFecha(hasta)
    setDesdeFecha(desde)
    setFiltro(tipoFiltro)
  }
 
  const columnas = [
   {
     field:"nro_factura",
     headerName: "Nro"
   },
   {
    field:"fecha_factura",
    headerName: "Fecha y hora"
    },
   {
    field:"nombre_cliente",
    headerName: "Cliente"
  },
  {
    field:"ruc_cliente",
    headerName: "Doc."
  },
  {
    field:"nombre_user",
    headerName: "Usuario"
  },
  {
    field:"estado_factura",
    headerName: "Estado",
    items:{0:"Anulado",1:"Pagado",2:"No pagado"},
    comparaItem: "estado_factura",
  },
  {
   field:"tipo_factura",
   headerName: "Tipo",
   items:{1:"Contado",2:"Crédito"},
   comparaItem: "tipo_factura",
 },
 {
   field:"monto_total_factura",
   headerName: "Monto",
   isNumber:true,
   extraitem : MONEDA_PRINCIPAL.abreviatura_moneda,
 },
 ]

 const abrirDetallesFactura = (datos)=>{
  setOpenDialogEstado(true)
  setFormulario(datos)
 }

 const Acciones = ({id,filaProps})=>(
  <div className={classes.botones}>
    <Button variant="outlined" color="default" onClick={()=>{abrirDetallesFactura(filaProps)}} >Ver</Button>
    <Button variant="outlined" color="primary" startIcon={<Icon>print</Icon>}  onClick={()=> Funciones.goto(`/facturas/${id}`) } >Imp</Button>
  </div>
 )

 const search = (
  <Grid container spacing={1}>
    <Grid item xs={12} sm={6} md={2}>
      <TextField fullWidth label="Desde" variant="outlined" type="date" defaultValue={desde} onChange={changeDatadesde} name="desdeFecha" />
    </Grid>
    <Grid item xs={12} sm={6} md={2}>
      <TextField fullWidth label="Hasta" variant="outlined" type="date" defaultValue={hasta} onChange={changeDatahasta} name="hastaFecha" />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
    <FormControl fullWidth>
      <InputLabel>Seleccione opciones</InputLabel>
      <Select
        name="tipo_filtro"
        value={tipoFiltro}
        onChange={(e)=>{setTipoFiltro(e.target.value)}}

      >
        <MenuItem value="0">Todos</MenuItem>  
        <MenuItem value="1">Tipo contado</MenuItem>        
        <MenuItem value="2">Tipo crédito</MenuItem>
        <MenuItem value="3">Pagados</MenuItem>
        <MenuItem value="4">No pagados</MenuItem>
      </Select>
    </FormControl>
    </Grid>
    <Grid item xs={12} sm={6} md={5}>
      <Button onClick={Filtrar} variant="outlined" color="primary" size="large" startIcon={<Icon>filter_list</Icon>} >Filtrar</Button>
    </Grid>
  </Grid>
 )

  return (
    <>
      <Tablas
        nombretabla="Facturas"
        subtitle="Todos los movimientos de facturas filtradas por fecha y tipo"
        columnas={columnas}
        Acciones={Acciones}
        bgicono="#3f51b5"
        icono="assignment"
        filas={lista}
        namecolumnID="id_factura"
        search={search}
        cargando={cargando}
      />
      <h3>Total: { Funciones.numberWithCommas( total)  } {MONEDA_PRINCIPAL.abreviatura_moneda} </h3>
    </>
  )
}

export default FacturasLista
