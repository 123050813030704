import { Button, Dialog, DialogActions, DialogTitle,Icon,IconButton,DialogContent, Grid, TextField, Typography, LinearProgress } from "@material-ui/core"
import { StylesGenerales } from "../../../Styles/StylesGenerales"
import { useAperturaCierre } from "./AperturaCierreProvider"
import {useState,useEffect,useCallback,Fragment} from 'react'
import { APICALLER } from "../../../Api/ApiCaller"
import {Funciones} from '../../../Funciones/Funciones'
//import { useLogin } from "../../../Contextos/LoginProvider"
import { useDatosEmpresa } from "../../../Contextos/DatosEmpresaProvider"

const DialogArqueo = () => {
    const classes = StylesGenerales()
    const {openDialogArqueo,setOpenDialogArqueo,IDCajaArqueo,fechaAperturaCaja,setDialogCierre,setTotalSumaMonedasArqueo,totalSumaMonedasArqueo,setArqueo} = useAperturaCierre()
    const {MONEDA_PRINCIPAL} = useDatosEmpresa() 
    const [listaMonedas,setListaMonedas] = useState([])
    const [cargando,setCargando] = useState(true)
    const cerrar = ()=>{
        setOpenDialogArqueo(false)
        //setTotalSumaMonedasArqueo(0)
    }
    const [cantidadMoneda,setCantidadMoneda] = useState({})
    
    const getListaMonedas = useCallback( async()=>{
        if(openDialogArqueo){
            let res = await APICALLER.listartablas({tables:'monedas_registros,monedas',on:'id_moneda_registro,id_moneda',
            fields:'descripcion_registro_moneda,abreviatura_moneda,id_monedas_registro,valor_moneda_registro,valor_moneda'})
            
            let val = await APICALLER.listar({table:'monedas_registros'})
            //console.log(res.results)
            if(res.response==="ok"){
                if(res.results.length>0){
                    let arr = val.results
                    let obj = arr.reduce((ac,a) => ({...ac,[a.id_monedas_registro]:0}),{});
                    setCantidadMoneda(obj)
                    //console.log(obj)
                    setListaMonedas(res.results)
                }
            }
            else{
                console.log(res)
            }
             
            setCargando(false)
        }
    },[openDialogArqueo])


    const onChange = (e)=>{
        const {value,name} = e.target
        let valor_cantidad = parseInt(value);
        if(value===""){
            valor_cantidad = 0;
        }
        let obj = {...cantidadMoneda}
        obj[name] = valor_cantidad;
        setCantidadMoneda({...cantidadMoneda,[name]:valor_cantidad})
        let suma = 0;
        
        for (const i in obj) {
            let found = listaMonedas.find(elem => parseInt(elem.id_monedas_registro)===parseInt(i));
            let valor = parseInt(found.valor_moneda_registro)
            let cotizacion = parseInt(found.valor_moneda);
            suma += (obj[i] * (valor * cotizacion ) )
          }
        //console.log(suma)
          setTotalSumaMonedasArqueo(suma)
    }

    const GuardarArqueo = async()=>{
        setCargando(true)
        let obj = {...cantidadMoneda};
        var data = {}
        var array = []
        for (const i in obj) {
            if(obj[i]>0){
                data = {id_arqueo_moneda:i,cantidad_moneda:obj[i],fecha_arqueo:fechaAperturaCaja,id_caja:IDCajaArqueo};
                array.push(data)
                //let res = await APICALLER.insert({table:'cajas_arqueos',token:token_user,data})
                //res.response!=="ok" && console.log(res);
            }
        }
        //console.log(totalSumaMonedasArqueo)
        setArqueo(array)
        setCargando(false)
        cerrar()
        setDialogCierre(true)
    }

    useEffect(() => {
        getListaMonedas()
    }, [getListaMonedas])
    

  return (
    <Dialog fullScreen open={openDialogArqueo} onClose={cerrar} >
        <DialogTitle>
            <div className={classes.titulodialog}>
              Arqueo de caja
              <IconButton onClick={cerrar}>
                <Icon>close</Icon>
              </IconButton>
            </div>
        </DialogTitle>
        <DialogContent dividers>
            {
                cargando ? <LinearProgress /> :
            
            <Grid container direction="row" justify="flex-end" spacing={2}  >
                <Grid item xs={12} >
                   <Typography variant="button">COMPLETE CON CANTIDAD DE BILLETES Y MONEDAS PARA CERRAR CAJA</Typography>
                </Grid>
                {
                    listaMonedas.map((item,index)=>{                     
                        return (
                        <Fragment key={index}>
                            <Grid item xs={6} md={3} >
                                <TextField  
                                helperText={`${item.descripcion_registro_moneda} ${item.abreviatura_moneda}`} 
                                type="number" variant="outlined" 
                                name={item.id_monedas_registro}
                                value={cantidadMoneda[item.id_monedas_registro]}
                                onChange={onChange}
                                InputProps={{
                                    inputProps: {min:0}
                                  }}
                                />
                            </Grid>
                        </Fragment>
                        )
                    })
                }
            </Grid>
            }
            <Grid item xs={12} >
                   <Typography variant="h6">TOTAL EN EFECTIVO: {Funciones.numberWithCommas(totalSumaMonedasArqueo)}  {MONEDA_PRINCIPAL.nombre_moneda} </Typography>
                </Grid>
        </DialogContent>
        <DialogActions>
            <Button color="primary"  variant="outlined" disabled={cargando} onClick={GuardarArqueo} >Confirmar cierre</Button>
            <Button variant="outlined" onClick={cerrar} >Cancelar</Button>
        </DialogActions>
    </Dialog>
  )
}

export default DialogArqueo
