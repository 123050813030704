import { useState, useEffect, useRef, useCallback } from "react";
import { Alert } from "@material-ui/lab";
import {
  Icon,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Snackbar,
  DialogTitle,
  IconButton,
  LinearProgress,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  Grid, Typography, 
} from "@material-ui/core";
import { APICALLER } from "../../../Api/ApiCaller";

import { useLogin } from "../../../Contextos/LoginProvider";
import { Styles } from "./Styles";
import { Funciones } from "../../../Funciones/Funciones";

const ProductosForm = (props) => {
  const COMPRAS_CODIGO = props.location.search;
  var CODIGO_PRODUCTO_COMPRA = "";
  if (COMPRAS_CODIGO !== ""){
    let query = new URLSearchParams(COMPRAS_CODIGO);
    CODIGO_PRODUCTO_COMPRA =
      query.get("codigo") === "" ? "" : query.get("codigo");
  }

  const classes = Styles();
  const { token_user } = useLogin();
  const [cargando, setCargando] = useState(false);

  const [listaMedidas, setListaMedidas] = useState([]);
  const [listaCategorias, setListaCategorias] = useState([]);
  const [listaMarcas, setListaMarcas] = useState([]);
  const [listaProveedor, setListaProveedor] = useState([]);

  const initialForm = {
    id_categoria_producto: "",
    id_proveedor_producto: "",
    id_marca_producto: "",
    id_unidad_medida_producto: "",
    codigo_producto: CODIGO_PRODUCTO_COMPRA,
    nombre_producto: "",
    costo_producto: "0",
    precio_producto: "0",
    preciom_producto: "0",
    minimo_producto: "0",
    stock_producto: "0",
    iva_producto: "0",
    tipo_producto: "1",
  };
  const [formulario, setFormulario] = useState(initialForm);

  const [existeProducto, setExisteProducto] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msjSnack, setMsjSnack] = useState("");

  const initialErrors = {
    codigo_producto: false,
    nombre_producto: false,
    error: false,
    errorMsj: "Complete todos los campos correctamente",
  };

  const [formErrores, setFormErrores] = useState(initialErrors);

  const inputCodigo = useRef(null);

  const reiniciarTodo = () => {
    setFormulario(initialForm);
    setFormErrores(initialErrors);
    setExisteProducto(false);
    inputCodigo.current.focus();
  };

  const verificarProducto = async (e) => {
    const valorCodigo = e.target.value;
    if (valorCodigo.length > 0) {
      const res = await APICALLER.listar({
        table: "productos",
        linkTo: "codigo_producto",
        equalTo: valorCodigo,
      });
      if (res.results.length > 0) {
        setExisteProducto(true);
      } else {
        setExisteProducto(false);
        //limpiarFormulario(valorCodigo);
      }
    }
  };

  const handlerOnChange = (e) => {
    setFormErrores(initialErrors);
    const { name, value } = e.target;
    setFormulario({ ...formulario, [name]: value });
  };

  const enviarFormulario = async (e) => {
    e.preventDefault();
    if (formulario.codigo_producto.length < 1) {
      setFormErrores({ ...formErrores, codigo_producto: true, error: true });
      return false;
    }
    setFormErrores({ ...formErrores, codigo_producto: false });
    if (formulario.nombre_producto.length < 2) {
      setFormErrores({ ...formErrores, nombre_producto: true, error: true });
      return false;
    }
    if (existeProducto) {
      return false;
    }
    /**************************************/
    try {
      setFormErrores(initialErrors);
      setCargando(true);
      let res = await APICALLER.insert({
        table: "productos",
        data: formulario,
        token: token_user,
      });

      if (res.response === "ok") {
        inputCodigo.current.focus();
        setMsjSnack("Agregado correctamente");
        setOpenSnack(true);
        setExisteProducto(false);
        reiniciarTodo();
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      setCargando(false);
    }
    setCargando(false);
    if (COMPRAS_CODIGO !== ""){
      cerrar_dialog()
    }
    /**************************************/
  };

  const cerrar_dialog = () => {
    if (COMPRAS_CODIGO === "") {
      Funciones.goto(`/productos`);
    } else {
      Funciones.goto(`/compras`);
    }
  };
  const traerDatos = useCallback(async () => {
    let cat = await APICALLER.listar({
      table: `categorias`,
      fields: `id_categoria,nombre_categoria`,
      startAt: 0,
      endAt: 60,
    });
    setListaCategorias(cat.results);
    let pro = await APICALLER.listar({
      table: "proveedors",
      fields: "id_proveedor,nombre_proveedor",
      startAt: 0,
      endAt: 60,
    });
    setListaProveedor(pro.results);
    let mar = await APICALLER.listar({
      table: `marcas`,
      fields: `id_marca,nombre_marca`,
      startAt: 0,
      endAt: 60,
      orderBy: `id_marca`,
      orderMode: `DESC`,
    });
    setListaMarcas(mar.results);
    let un = await APICALLER.listar({
      table: `unidad_medidas`,
    });
    setListaMedidas(un.results);
  }, []);
  useEffect(() => {
    traerDatos();
  }, [traerDatos]);

  return (
    <>
      <Snackbar
        open={openSnack}
        onClose={() => setOpenSnack(!openSnack)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert severity="success" variant="filled">
          {msjSnack}
        </Alert>
      </Snackbar>

      <Dialog fullScreen open={true} onClose={cerrar_dialog}>
        <form onSubmit={enviarFormulario}>
          <DialogTitle>
            <div className={classes.titulodialog}>
              AGREGAR PRODUCTO
              <IconButton onClick={cerrar_dialog}>
                <Icon>close</Icon>
              </IconButton>
            </div>
          </DialogTitle>

          <DialogContent dividers>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {cargando && <LinearProgress className={classes.loaderSend} />}
              </Grid>
              <Grid item xs={12}>
                {existeProducto && (
                  <Alert severity="warning">
                    Atención: Ese código de producto ya existe
                  </Alert>
                )}
                {formErrores.error && (
                  <Alert severity="error">{formErrores.errorMsj}</Alert>
                )}
              </Grid>


              <Grid item xs={12}>
                <Typography variant="overline">Nombre y código</Typography>
              </Grid>


              <Grid item xs={12} sm={4}>
                <TextField
                  autoFocus
                  inputRef={inputCodigo}
                  onChange={handlerOnChange}
                  onBlur={verificarProducto}
                  className={classes.textfield}
                  label="Código"
                  autoComplete="off"
                  name="codigo_producto"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="primary">qr_code</Icon>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  value={formulario.codigo_producto}
                  error={formErrores.codigo_producto}
                  variant="outlined"
                  helperText="Código de barra del producto"
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  className={classes.textfield}
                  onChange={handlerOnChange}
                  label="Nombre"
                  autoComplete="off"
                  name="nombre_producto"
                  value={formulario.nombre_producto}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="action">inventory_2</Icon>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  variant="outlined"
                  error={formErrores.nombre_producto}
                  helperText="Descripción del producto"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="overline">TIPO DE PRODUCTO</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  value="1"
                  control={
                    <Radio
                      name="tipo_producto"
                      checked={formulario.tipo_producto === "1"}
                      onChange={handlerOnChange}
                      color="primary"
                    />
                  }
                  label="Producto"
                  labelPlacement="end"
                />

                <FormControlLabel
                  value="2"
                  control={
                    <Radio
                      name="tipo_producto"
                      disabled={COMPRAS_CODIGO === "" ? false : true}
                      checked={formulario.tipo_producto === "2"}
                      onChange={handlerOnChange}
                      color="primary"
                    />
                  }
                  label="Servicio"
                  labelPlacement="end"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="overline">CARACTERISTICAS</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Unidad de medida</InputLabel>
                  <Select
                    name="id_unidad_medida_producto"
                    value={formulario.id_unidad_medida_producto}
                    onChange={handlerOnChange}
                    variant="standard"
                  >
                    {listaMedidas.map((d) => (
                      <MenuItem
                        key={d.id_unidad_medida}
                        value={d.id_unidad_medida}
                      >
                        {d.descripcion_medida}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <InputLabel className={classes.labelSelect}>
                    Selecccionar categoría
                  </InputLabel>
                  <Select
                    name="id_categoria_producto"
                    value={formulario.id_categoria_producto}
                    onChange={handlerOnChange}
                    variant="standard"
                  >
                    {listaCategorias.map((d) => (
                      <MenuItem key={d.id_categoria} value={d.id_categoria}>
                        {d.nombre_categoria}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <InputLabel className={classes.labelSelect}>
                    Seleccionar proveedor
                  </InputLabel>
                  <Select
                    name="id_proveedor_producto"
                    value={formulario.id_proveedor_producto}
                    onChange={handlerOnChange}
                    variant="standard"
                  >
                    {listaProveedor.map((d) => (
                      <MenuItem key={d.id_proveedor} value={d.id_proveedor}>
                        {d.nombre_proveedor}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <InputLabel className={classes.labelSelect}>Marca</InputLabel>
                  <Select
                    name="id_marca_producto"
                    value={formulario.id_marca_producto}
                    onChange={handlerOnChange}
                    variant="standard"
                    fullWidth
                  >
                    {listaMarcas.map((d) => (
                      <MenuItem key={d.id_marca} value={d.id_marca}>
                        {d.nombre_marca}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>



              <Grid item xs={12}>
                <Typography variant="overline">PRECIOS Y COSTOS</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  className={classes.textfield}
                  onChange={handlerOnChange}
                  fullWidth
                  label="Costo"
                  type="number"
                  autoComplete="off"
                  name="costo_producto"
                  value={formulario.costo_producto}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon style={{ color: "#ffa501" }}>attach_money</Icon>
                      </InputAdornment>
                    ),
                    inputProps: { min: 0 },
                  }}
                  variant="outlined"
                  error={false}
                  helperText="Costo del producto"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  fullWidth
                  className={classes.textfield}
                  onChange={handlerOnChange}
                  label="Precio"
                  type="number"
                  autoComplete="off"
                  name="precio_producto"
                  value={formulario.precio_producto}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="primary">point_of_sale</Icon>
                      </InputAdornment>
                    ),
                    inputProps: { min: 0 },
                  }}
                  variant="outlined"
                  error={false}
                  helperText="Precio del producto"
                />
              </Grid>

              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  fullWidth
                  className={classes.textfield}
                  onChange={handlerOnChange}
                  label="Precio Mayorista"
                  type="number"
                  autoComplete="off"
                  name="preciom_producto"
                  value={formulario.preciom_producto}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="primary">point_of_sale</Icon>
                      </InputAdornment>
                    ),
                    inputProps: { min: 0 },
                  }}
                  variant="outlined"
                  error={false}
                  helperText="Precio para mayoristas"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  fullWidth
                  disabled
                  className={classes.textfield}
                  onChange={handlerOnChange}
                  label="Stock"
                  type="number"
                  autoComplete="off"
                  name="stock_producto"
                  value={formulario.stock_producto}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="secondary">inventory_2</Icon>
                      </InputAdornment>
                    ),
                    inputProps: { min: 0 },
                  }}
                  variant="outlined"
                  error={false}
                  helperText="Cantidad en stock"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  fullWidth
                  className={classes.textfield}
                  onChange={handlerOnChange}
                  label="Stock mínimo"
                  type="number"
                  autoComplete="off"
                  name="minimo_producto"
                  value={formulario.minimo_producto}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="primary">inventory_2</Icon>
                      </InputAdornment>
                    ),
                    inputProps: { min: 0 },
                  }}
                  variant="outlined"
                  error={false}
                  helperText="Cantidad mínima de existencia"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  fullWidth
                  className={classes.textfield}
                  onChange={handlerOnChange}
                  label="IVA"
                  color="secondary"
                  type="number"
                  autoComplete="off"
                  name="iva_producto"
                  value={formulario.iva_producto}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="error">monetization_on</Icon>
                      </InputAdornment>
                    ),
                    inputProps: { min: 0 },
                  }}
                  variant="outlined"
                  error={false}
                  helperText="Impuesto %"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="overline">IMAGEN DEL PRODUCTO</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField type="file" />
              </Grid>

            </Grid>

          </DialogContent>


          <DialogActions>
            <Button
              onClick={reiniciarTodo}
              variant="outlined"
              color="default"
              disabled={cargando}
            >
              Limpiar
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              disabled={cargando}
            >
              AGREGAR
            </Button>
            <Button
              color="default"
              onClick={cerrar_dialog}
              variant="outlined"
              disabled={cargando}
            >
              Cerrar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ProductosForm;
