import React from "react";
import { useLogin } from "./Contextos/LoginProvider";
//import LoginForm from "./Pages/Formularios/Login/LoginForm";

import Loading from "./Componentes/Loading";
import AllRutas from "./Rutas/AllRutas";
import {  Switch } from "react-router";

export default function Inicio() {
  const { cargando } = useLogin();

  if(cargando){
    return <Loading />
  }

  return (
    <Switch>
      <AllRutas />
    </Switch>
  );
}
