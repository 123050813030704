import React from 'react';
import { LoginProvider } from './Contextos/LoginProvider';
import {ThemeProviderContext} from './Contextos/ThemeProviderContext';
import NotificationsProvider from './Contextos/NotificationsProvider';
import Main from './Main';
import {Router} from 'react-router-dom'
import {history} from './VariablesGlobales/globales'
import DatosEmpresaProvider from './Contextos/DatosEmpresaProvider';

function App() {
  return (
    <LoginProvider>
      <ThemeProviderContext>
        <NotificationsProvider>
          <DatosEmpresaProvider>
            <Router history={history}>
              <Main />
            </Router>
          </DatosEmpresaProvider>
        </NotificationsProvider>
      </ThemeProviderContext>
    </LoginProvider>
  );
}

export default App;
