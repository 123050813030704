import {Dialog,DialogContent,DialogContentText, DialogActions,DialogTitle,IconButton,Icon,TextField,Button,LinearProgress} from '@material-ui/core'
import React from 'react'
import { StylesGenerales } from '../../../Styles/StylesGenerales'
import { useProveedores } from './ProveedoresProvider'

const ProveedoresForm = () => {
    const classes = StylesGenerales()
    const {openDialog,setOpenDialog,formulario,enviarFormulario,setFormulario,cargando} = useProveedores()
    const cerrar = ()=>{
      setOpenDialog(false)
      setFormulario({nombre_proveedor:"",telefono_proveedor:"",ruc_proveedor:""})
    }
    const onChange = (e)=>{
      const {name,value} = e.target
      setFormulario({...formulario,[name]:value})
    }

  return (
    <Dialog open={openDialog} fullWidth onClose={cerrar}>
      <form onSubmit={enviarFormulario}>
        <DialogTitle>
          <div className={classes.titulodialog}>
            <div>
            Proveedor
            </div>
            <IconButton onClick={()=>cerrar()}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
        {cargando && <LinearProgress className={classes.loaderSend} />}
            <DialogContentText></DialogContentText>
            <TextField
              autoFocus
              className={classes.textfield} 
              name="nombre_proveedor"
              value={formulario.nombre_proveedor}
              onChange={onChange}
              fullWidth
              label="Nombre de proveedor"
              variant="outlined"
              required
              disabled={cargando}
            />
            <TextField
              className={classes.textfield}
              name="ruc_proveedor"
              value={formulario.ruc_proveedor}
              onChange={onChange}
              fullWidth
              label="Documento del proveedor"
              variant="outlined"
              required
              disabled={cargando}
            />
            <TextField
              className={classes.textfield}
              name="telefono_proveedor"
              value={formulario.telefono_proveedor}
              onChange={onChange}
              fullWidth
              label="Teléfono de contacto"
              variant="outlined"
              disabled={cargando}
            />
        </DialogContent>
        <DialogActions>
          <Button disabled={cargando} variant="outlined" type="onsubmit" color="primary" > { formulario.id_proveedor ? `EDITAR` : `AGREGAR`}</Button>
          <Button variant="outlined" color="default" onClick={()=>cerrar()}>CERRAR</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ProveedoresForm
