import { createBrowserHistory} from 'history';

export const history = createBrowserHistory();
export const BASEURL = ''; // aqui debe ir al final sin la barra ej: /carpeta/otracaperta
export const APIURL =  process.env.REACT_APP_API_URL;
//export const APIURL = `http://localhost/api/v1/index.php`
//export const APIURL =`https://derlisruizdiaz.com/api/saeta/index.php`

//.Zaz^;8Mj(mZ
export const APPNAME = 'SAETA STORE';


export const POSIBLES_ERRORES = {
    "conexion": "Error de conexión.",
}

