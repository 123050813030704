import React from 'react';
import {makeStyles, Drawer, Divider, Typography, Icon} from '@material-ui/core'
import ListaMenu from './ListaMenu';
import { APPNAME } from '../VariablesGlobales/globales';
import { useTheme } from '../Contextos/ThemeProviderContext';





export default function DrawerMenu(props) {
    const {drawerWidth} = useTheme()
    const estilos = makeStyles(theme=>({
        drawer:{
            width: drawerWidth,
            flexShrink:0,
        },
        drawerPaper:{
            width: drawerWidth
        },
        toolbar:theme.mixins.toolbar,
        appname:{
            display:"flex",
            height:"100%",
            justifyContent:"center",
            alignItems:"center",
        },
        fire:{
            color:"#ff4e00",
            
        }
        
    }))
    const classes = estilos();
  return (
    <Drawer
        variant={props.variant}
        className={classes.drawer}
        classes={{paper:classes.drawerPaper}}
        anchor="left"
        open={props.open}
        onClose={props.onClose ? props.onClose : null}
    >
        <div className={classes.toolbar}>
            <Typography className={classes.appname} variant="button" >
                {APPNAME}
                <Icon className={classes.fire}>local_fire_department</Icon>
            </Typography>
        </div>
        <Divider />
        <ListaMenu onClose={props.onClose}  />
    </Drawer>
  );
}
