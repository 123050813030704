import { Dialog, DialogContent, DialogTitle, Icon, IconButton, 
    DialogActions,Button, TextField, InputAdornment, LinearProgress } from "@material-ui/core"
import { Autocomplete, Alert } from "@material-ui/lab";
import { useEffect, useState, useRef} from "react"
import swal from "sweetalert";
import { APICALLER } from "../../../Api/ApiCaller";
import { useLogin } from "../../../Contextos/LoginProvider";
import { Funciones } from "../../../Funciones/Funciones";
import {Styles} from './Styles'

const CategoriasForm = (props) => {

    const {id} = props.match.params;

   
    const inputNombreCategoria = useRef(null)
    const classes = Styles();
    
    const {token_user} = useLogin()
    const [listaCategorias,setListaCategorias] = useState([]);
    const [cargando,setCargando] = useState(true);

    const initial = {
      id_categoria:"",
      nombre_categoria:"",
      id_padre_categoria:"0",
    }
    const [formulario,setFormulario] = useState(initial);
    const initialError = {
        nombre_categoria: false,
        error: false,
        errorMsj: "Complete el campo correctamente",
    }
    const [formErrores,setFormErrores] = useState(initialError)
    const cerrar = ()=>Funciones.goto(`/categorias`);
    const [inputTxtPadre,setInputTxtPadre] = useState('');

    const handlerOnChange = (e)=>{
        const {name,value} = e.target;
        setFormulario({...formulario,[name]:value});
    }

    const handlerOnChangeCategoria = (e, value) => {
        if (value === null) {
          setFormulario({ ...formulario, id_padre_categoria: 0 });
        } else {
          setFormulario({
            ...formulario,
            id_padre_categoria: value.id_categoria,
          });
        }
      };

      const enviarForm = async(e)=>{
        e.preventDefault();
        
        if (formulario.nombre_categoria.length < 2) {
            setFormErrores({ ...formErrores, nombre_categoria: true, error: true });
            return false;
          }
        setCargando(true)
        setFormErrores({ ...formErrores, nombre_categoria: false, error: false });
        if(id){
          
          const res = await APICALLER.update({table:`categorias`,data:formulario,token:token_user,namecolumn:`id_categoria`,equalTo:id})
          res.response==="ok" && swal({icon:"success",title:"Actualizado"}).then(
            ()=>{
                setFormulario(initial)
                cerrar();
            }    
          )  
        }else{
          delete formulario.id_categoria;
          const res = await APICALLER.insert({table:"categorias",data:formulario,token:token_user});
          if(res.response==="ok"){
              swal({icon:"success",title:"Agregado"}).then(
                  ()=>{
                      setFormulario(initial)
                      cerrar();
                  }    
              )   
          }
        }
        //getLista() VOLVER A LA PARTE PRINCIPAL
      }



      useEffect(() => {
        
        const cargar = async()=>{
          
          let resTodes = await APICALLER.listar({table:`categorias`,fields:`id_categoria,nombre_categoria,id_padre_categoria`});
          setListaCategorias(resTodes.results);
          
          setCargando(false)
          if(id){
            let res = await APICALLER.listar({table:`categorias`,linkTo:`id_categoria`,equalTo:`${id}`})
            setFormulario(res.results[0]);
            if(res.results[0].id_padre_categoria===`0`){
              setInputTxtPadre(``);
            }
            else{
              let padre = await APICALLER.listar({table:`categorias`,fields:`nombre_categoria`,
              linkTo:`id_categoria`,equalTo:res.results[0].id_padre_categoria})
              setInputTxtPadre(padre.results[0].nombre_categoria);
            }
            
          }
          inputNombreCategoria.current.focus()
        }
        cargar()
      }, [id]);
      
  return (
    <Dialog fullWidth open={true} onClose={cerrar}>
            <form onSubmit={enviarForm}>
            <DialogTitle>
              <div className={classes.titulodialog}>
                <div>CATEGORIAS</div>
                <IconButton onClick={cerrar}>
                  <Icon>close</Icon>
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent dividers>
            {cargando && <LinearProgress className={classes.loaderSend} /> }
            {formErrores.error && (
                <Alert severity="error">{formErrores.errorMsj}</Alert>
              )}
            <TextField
                autoFocus
                inputRef={inputNombreCategoria}
                className={classes.textfield}
                onChange={handlerOnChange}
                label="Nombre"
                autoComplete="off"
                name="nombre_categoria"
                value={formulario.nombre_categoria}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon color="action">inventory_2</Icon>
                    </InputAdornment>
                  )
                }}
                fullWidth
                required
                variant="outlined"
                error={formErrores.nombre_categoria}
                helperText="Nombre de categoría"
                disabled={cargando}
              />
              <Autocomplete
                loadingText="Cargando..."
                className={classes.textfield}
                disabled={cargando}
                noOptionsText="No hay categorías"
                onChange={handlerOnChangeCategoria}              
                getOptionSelected={(option, value) =>
                  option.id_categoria === value.id_categoria
                }
                options={listaCategorias}
                getOptionLabel={(option) => option.nombre_categoria}
                fullWidth
                inputValue={inputTxtPadre}
                onInputChange={(e, v) => setInputTxtPadre(v)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Categoría Padre"
                    variant="outlined"
                  />
                )}
              />
            </DialogContent>
            <DialogActions>
                <Button type="submit" color="primary" variant="outlined" disabled={cargando} >
                    GUARDAR
                </Button>
                <Button
                    onClick={cerrar}
                    variant="outlined"
                    disabled={cargando}
                >
                    CERRAR
                </Button>
            </DialogActions>
            </form>
    </Dialog>
  )
}

export default CategoriasForm
