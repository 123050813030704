import AperturaCierreProvider from "./AperturaCierreProvider"
import AperturaCierreLista from "./AperturaCierreLista"
import DialogCierre from "./DialogCierre"
import DialogApertura from "./DialogApertura"
import DialogArqueo from "./DialogArqueo"

const AperturaCierreCaja = () => {

  return (
    <AperturaCierreProvider>
        <AperturaCierreLista />
        <DialogArqueo />
        <DialogCierre />
        <DialogApertura />
    </AperturaCierreProvider>
  )
}

export default AperturaCierreCaja
