import {Dialog, DialogContent,DialogTitle, Grid, Icon, IconButton, Typography} from '@material-ui/core'
import { StylesGenerales } from '../../../Styles/StylesGenerales'
import { useFacturas } from './FacturasProvider'


const FacturasDialogEstado = () => {

    const {openDialogEstado,setOpenDialogEstado,formulario} = useFacturas()
    const classes = StylesGenerales()
    const cerrar = ()=>{
        setOpenDialogEstado(false)
    }


  return (
    <Dialog fullWidth open={openDialogEstado} onClose={cerrar}>
        <DialogTitle>
          <div className={classes.titulodialog}>
            <div>
            Estado de factura
            </div>
            <IconButton onClick={()=>cerrar()}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1">{formulario.id_factura}</Typography>
          <Grid container>
            <Grid item xs={12}>
              Pago
            </Grid>
          </Grid>
        </DialogContent>
        
    </Dialog>
  )
}

export default FacturasDialogEstado
