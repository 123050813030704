import {
  Icon,
  Tooltip,
  Fab,
  TextField, IconButton, InputAdornment, Button
} from "@material-ui/core";


import Tablas from "../../Componentes/Tablas";

import { motion } from "framer-motion";
import { useUsuarios } from "./UsuariosProvider";
import { useState } from "react";
import {Styles} from './Styles';
import { columns } from "./UsuariosColumns";



// Componente principal 
const UsuariosLista = () => {
  const classes = Styles();
  const { setIdUserPermiso, setOpenDialog,cargando, openDialog, lista, borrarUser,setFormulario,setOpenDialogFormEdit,initialForm,setOpenDialogPermisos,setNombreUserPermiso } = useUsuarios()
  const [inputSearch,setInputSearch] = useState("");
  const setearFormulario = (id)=>{
    let array = [...lista];
    let index = array.findIndex((e)=> e.id_user === id)    
    array[index].password_user = "";
    setFormulario(array[index]);
    setOpenDialogFormEdit(true);
  }

  const Acciones = ({ id,extraprops }) => (
    <div className={classes.botones}>
      <Fab size="small" color="default" variant="round" onClick={() =>{ setOpenDialogPermisos(true); setIdUserPermiso(id); setNombreUserPermiso(extraprops) }}>
      <Icon>manage_accounts</Icon>
    </Fab>
    <Fab size="small" color="primary" variant="round" onClick={() =>{ setearFormulario(id)}}>
      <Icon>edit</Icon>
    </Fab>
    <Fab size="small" color="secondary" variant="round" onClick={() =>{borrarUser(id,extraprops)}} >
      <Icon>delete</Icon>
    </Fab>
    </div>
  );



  const FilterDataUsers = lista.filter( item =>{
    return item.nombre_user.toLowerCase().includes( inputSearch.toLowerCase())
  })

 const searchInput = (
   <div className={classes.inputs}>
     <TextField
       InputProps={{
         endAdornment: (
           <InputAdornment position="end">
             <IconButton>
               <Icon>search</Icon>
             </IconButton>
           </InputAdornment>
         ),
       }}
       onChange={(e) => setInputSearch(e.target.value)}
       variant="outlined"
       label="Buscar"
     />
     <Button
       color="primary"
       variant="outlined"
       size="large"
       onClick={() => {
        opencloseDialog();
        setFormulario(initialForm)
      }}
     >
       AGREGAR
     </Button>
   </div>
 );

  const opencloseDialog = () => {setOpenDialog(!openDialog)};

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: "-50px" }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4 }}
      >
        <Tablas
          nombretabla="Usuarios"
          namecolumnID="id_user"
          columnas={columns}
          filas={FilterDataUsers}
          icono="person"
          bgicono="#303f9f"
          Acciones={Acciones}
          extraprops={`nombre_user`}
          search={searchInput}
          cargando={cargando}
        />
      </motion.div>
      <Tooltip title="AGREGAR NUEVO" arrow aria-label="add">
        <Fab
          color="primary"
          variant="extended"
          className={classes.absolute}
          onClick={() => {
            opencloseDialog();
            setFormulario(initialForm)
          }}
        >
          <Icon>add</Icon>
          Agregar
        </Fab>
      </Tooltip>

          
    </>
  );
};

export default UsuariosLista;
