import { Container, Typography,Icon,Grid, makeStyles,Box } from "@material-ui/core"
import {Link} from 'react-router-dom'
import { useLogin } from "../../Contextos/LoginProvider"
import { ListaDashboard } from "./ListaDashboard"

const useStyles = makeStyles((theme) => ({
    icono:{
        fontSize:100,
    },
    box:{
        display:"flex",
        justifyContent:"center",
        flexDirection:"column",
        alignItems:"center",
        border:"1px solid whitesmoke",
        borderRadius:"6px",
    },
    titulo:{
        marginBottom:theme.spacing(4),
    },
    link:{
        textDecoration:"none"
    },

}))

const Dashboard = () => {
    const classes = useStyles()

    const {permisos, nombre_user} = useLogin()

    const FilterData =  [];

    ListaDashboard.forEach(item=>{
        if(permisos.some(elem => elem.id_permiso_permiso ===item.id_permiso)){
            FilterData.push(item)
        }
    })

  return (
    <Container>
        <div className={classes.titulo}>
            <Typography variant="h5" >
                Hola: {nombre_user}
            </Typography>
            <Typography variant="caption">
                ¡Bienvenido! Este es el panel principal del sistema acá podrá encontrar atajos para acceder a los distintos listados de cada módulo del sistema.
            </Typography>
        </div>

        <Grid container spacing={3} >
            {
                FilterData.map((e,i)=>(
                    <Grid key={i} item xs={12} sm={6} md={3} >
                        <Link to={e.url} className={classes.link} >
                            <Box bgcolor={e.bgcolor} className={classes.box} color={e.color} p={2}>
                                <Icon className={classes.icono} >{e.icon}</Icon>
                                <Typography variant="button">{e.text}</Typography>
                            </Box>
                        </Link>
                    </Grid>
                ))
            }
        </Grid>

    </Container>
  )
}

export default Dashboard
