import { useState, useEffect, useRef, useCallback } from "react";
import {  Alert } from "@material-ui/lab";
import {
  Icon,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Snackbar,
  DialogTitle,
  IconButton,
  LinearProgress, FormControlLabel,Radio,FormControl,InputLabel,Select, MenuItem,Tab,Tabs
} from "@material-ui/core";
import { APICALLER } from "../../../Api/ApiCaller";

import { useLogin } from "../../../Contextos/LoginProvider";
import { Styles } from "./Styles";
import { Funciones } from "../../../Funciones/Funciones";

const ProductosFormEdit = (props) => {
  const { id } = props.match.params;
  const classes = Styles();
  const { token_user } = useLogin();
  const [cargando, setCargando] = useState(true);

  const [tabValue,setTabValue] = useState(0)

  const [listaMedidas,setListaMedidas] = useState([])
  const [listaCategorias, setListaCategorias] = useState([]);

  const [listaMarcas, setListaMarcas] = useState([]);

  const [listaProveedor, setListaProveedor] = useState([]);


  const initialForm = {
    id_categoria_producto: "",
    id_proveedor_producto: "",
    id_marca_producto:"",
    id_unidad_medida_producto:"",
    codigo_producto: "",
    nombre_producto: "",
    costo_producto: "0",
    precio_producto: "0",
    preciom_producto: "0",
    minimo_producto:"0",
    stock_producto: "0",
    iva_producto: "0",
    tipo_producto:"1",
  };
  const [formulario, setFormulario] = useState(initialForm);

  const [existeProducto, setExisteProducto] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msjSnack, setMsjSnack] = useState("");

  const initialErrors = {
    codigo_producto: false,
    nombre_producto: false,
    error: false,
    errorMsj: "Complete todos los campos correctamente",
  };
  const [formErrores, setFormErrores] = useState(initialErrors);

  const inputCodigo = useRef(null);


  const handlerOnChange = (e) => {
    setFormErrores(initialErrors);
    const { name, value } = e.target;
    setFormulario({ ...formulario, [name]: value });
  };

  const verificarProducto = async ()=>{
    let res = await APICALLER.listar({table:`productos`,linkTo:`codigo_producto`,equalTo:formulario.codigo_producto,fields:`id_producto`})
    
   if(res.response==="ok"){
    if(res.results.length>0){
      res.results[0].id_producto===id ? setExisteProducto(false) : setExisteProducto(true)
    }
   }
   else{
     console.log(res)
   }

  }
  
  const verificado = async()=>{
    try {
      if(existeProducto){

      }else{
        setFormErrores(initialErrors);
        setCargando(true);
        delete formulario.nombre_categoria;
        delete formulario.nombre_proveedor;
        delete formulario.nombre_marca;
        let res = await APICALLER.update({table:`productos`,data:formulario,token:token_user,namecolumn:`id_producto`,equalTo:id})
        if (res.response === "ok") {
          inputCodigo.current.focus();
          setMsjSnack("Actualizado correctamente");
          setOpenSnack(true);
          setExisteProducto(false);
         
        }else{ console.log(res)}
        setCargando(false);
      }

    } catch (error) {
      console.log(error);
      setCargando(false);
    } 
  }
  const enviarFormulario = async (e) => {
    e.preventDefault();
    if (formulario.codigo_producto.length < 1) {
      setFormErrores({ ...formErrores, codigo_producto: true, error: true });
      return false;
    }
    setFormErrores({ ...formErrores, codigo_producto: false });
    if (formulario.nombre_producto.length < 2) {
      setFormErrores({ ...formErrores, nombre_producto: true, error: true });
      return false;
    }
    
      let valorCodigo = formulario.codigo_producto
      if (valorCodigo.length > 0) {  
        let res = await APICALLER.listar({
          table: "productos",
          fields: `id_producto`,
          linkTo: "codigo_producto",
          equalTo: valorCodigo,
        });
  
        if (res.results.length > 0) {
          setCargando(true);
          if(res.results[0].id_producto === id)
          { 
            setExisteProducto(false)
            verificado()
          }
          else{ 
            setExisteProducto(true)
            setCargando(false);
          }
        } else {
          setExisteProducto(false);
          verificado()
        }

      } 
  }

  //console.log(formulario);
  const traerDatos = useCallback(async () => {
    let res = await APICALLER.listartablas({
      tables: `productos,categorias,proveedors,marcas`,
      on: `id_categoria_producto,id_categoria,id_proveedor_producto,id_proveedor,id_marca_producto,id_marca`,
      linkTo: `id_producto`,
      equalTo: id,
      fields:`id_producto,id_unidad_medida_producto,codigo_producto,nombre_producto,id_categoria_producto,nombre_categoria,id_proveedor_producto,nombre_proveedor,
      costo_producto,precio_producto,preciom_producto,stock_producto,iva_producto,tipo_producto,minimo_producto,id_marca_producto,nombre_marca`
    });

    let cat = await APICALLER.listar({
      table: `categorias`,
      fields: `id_categoria,nombre_categoria`,
      startAt: 0,
      endAt: 60,
    });
    let pro = await APICALLER.listar({
      table: "proveedors",
      fields: "id_proveedor,nombre_proveedor",
      startAt: 0,
      endAt: 60,
    });

    let mar = await APICALLER.listar({
      table: `marcas`,
      fields: `id_marca,nombre_marca`,
      startAt: 0,
      endAt: 60,
    });
    setListaMarcas(mar.results)
    let un = await APICALLER.listar({
      table:`unidad_medidas`
    })
    setListaMedidas(un.results)

    setFormulario(res.results[0]);

    setListaCategorias(cat.results);
    setListaProveedor(pro.results);
    setCargando(false);
    
  }, [id]);

  useEffect(() => {
    traerDatos();
  }, [traerDatos]);

  const TabPanel = ({children,index})=>{
    return(
      tabValue === index && (
        <>
          {children}
        </>
      )
    )
  }

  return (
    <>
      <Snackbar
        open={openSnack}
        onClose={() => setOpenSnack(!openSnack)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert severity="success" variant="filled">
          {msjSnack}
        </Alert>
      </Snackbar>
      <Dialog
        fullScreen
        open={true}
        onClose={() => Funciones.goto(`/productos`)}
      >
        <form onSubmit={enviarFormulario}>
          <DialogTitle>
            <div className={classes.titulodialog}>
              EDITAR
              <IconButton onClick={() => Funciones.goto(`/productos`)}>
                <Icon>close</Icon>
              </IconButton>
            </div>
          </DialogTitle>

          <DialogContent dividers>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={tabValue}
              onChange={(e,value)=>{ setTabValue(value)}}
            >
              <Tab label="Principal" />
              <Tab label="Secundario" />
            </Tabs>

            {cargando && <LinearProgress className={classes.loaderSend} />}
            {existeProducto && (
              <Alert severity="warning">
                Atención: Ese código de producto ya existe, por favor cambielo
              </Alert>
            )}
            {formErrores.error && (
              <Alert severity="error">{formErrores.errorMsj}</Alert>
            )}

              <TabPanel value={tabValue} index={1}>
                <h1>Subir una foto</h1>
              </TabPanel>

          <TabPanel value={tabValue} index={0} >
            <TextField
              autoFocus
              inputRef={inputCodigo}
              onChange={handlerOnChange}
              onBlur={verificarProducto}
              className={classes.textfield}
              label="Código"
              autoComplete="off"
              name="codigo_producto"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon color="primary">qr_code</Icon>
                  </InputAdornment>
                ),
              }}
              value={formulario.codigo_producto}
              error={formErrores.codigo_producto}
              variant="outlined"
              helperText="Código de barra del producto"
            />
            <TextField
              className={classes.textfield}
              onChange={handlerOnChange}
              label="Nombre"
              autoComplete="off"
              name="nombre_producto"
              value={formulario.nombre_producto}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon color="action">inventory_2</Icon>
                  </InputAdornment>
                ),
              }}
              fullWidth
              variant="outlined"
              error={formErrores.nombre_producto}
              helperText="Descripción del producto"
            />
            <div className={classes.tipoProducto}>
                <FormControlLabel
                  value="1"
                  control={
                    <Radio
                      name="tipo_producto"
                      checked={formulario.tipo_producto==="1"}
                      onChange={handlerOnChange}
                      color="primary"
                    />
                  }
                  label="Producto"
                  labelPlacement="end"
                />

                <FormControlLabel
                  value="2"
                  control={
                    <Radio
                      name="tipo_producto"
                      checked={formulario.tipo_producto==="2"}
                      onChange={handlerOnChange}
                      color="primary"
                    />
                  }
                  label="Servicio"
                  labelPlacement="end"
                />
            </div>

            <FormControl className={classes.inputsProducto}>
                <InputLabel className={classes.labelSelect}> Unidad de medida</InputLabel>
                  <Select 
                    name="id_unidad_medida_producto"
                    value={formulario.id_unidad_medida_producto}
                    onChange={handlerOnChange}
                    variant="standard"
                    >
                      {
                        listaMedidas.map((d)=>(
                          <MenuItem key={d.id_unidad_medida} value={d.id_unidad_medida} >{d.descripcion_medida}</MenuItem>
                        ))
                      }
                    </Select>
            </FormControl>
            
            <FormControl className={classes.inputsProducto}>
              <InputLabel className={classes.labelSelect} >Selecccionar categoría</InputLabel>
                  <Select 
                    name="id_categoria_producto"
                    value={formulario.id_categoria_producto}
                    onChange={handlerOnChange}
                    variant="standard"
                    >
                      {
                        listaCategorias.map((d)=>(
                          <MenuItem key={d.id_categoria} value={d.id_categoria} >{d.nombre_categoria}</MenuItem>
                        ))
                      }
                      
                    </Select>
            </FormControl>
            <FormControl className={classes.inputsProducto}>
              <InputLabel className={classes.labelSelect} >Seleccionar proveedor</InputLabel>
                  <Select 
                    name="id_proveedor_producto"
                    value={formulario.id_proveedor_producto}
                    onChange={handlerOnChange}
                    variant="standard"
                    >
                      {
                        listaProveedor.map((d)=>(
                          <MenuItem key={d.id_proveedor} value={d.id_proveedor} >{d.nombre_proveedor}</MenuItem>
                        ))
                      }
                      
                    </Select>
            </FormControl>
            <FormControl className={classes.inputsProducto}>
              <InputLabel className={classes.labelSelect} >Marca</InputLabel>
                  <Select 
                    name="id_marca_producto"
                    value={formulario.id_marca_producto}
                    onChange={handlerOnChange}
                    variant="standard"
                    fullWidth
                    >
                      {
                        listaMarcas.map((d)=>(
                          <MenuItem key={d.id_marca} value={d.id_marca} >{d.nombre_marca}</MenuItem>
                        ))
                      }
                      
                    </Select>
            </FormControl>
            <div className={classes.inputsNumeros}>
              <TextField
                className={classes.textfield}
                onChange={handlerOnChange}
                label="Costo"
                type="number"
                autoComplete="off"
                name="costo_producto"
                value={formulario.costo_producto}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon style={{ color: "#ffa501" }}>attach_money</Icon>
                    </InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
                variant="outlined"
                error={false}
                helperText="Costo del producto"
              />
              <TextField
                className={classes.textfield}
                onChange={handlerOnChange}
                label="Precio"
                type="number"
                autoComplete="off"
                name="precio_producto"
                value={formulario.precio_producto}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon color="primary">point_of_sale</Icon>
                    </InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
                variant="outlined"
                error={false}
                helperText="Precio del producto"
              />
              <TextField
                className={classes.textfield}
                onChange={handlerOnChange}
                label="Precio Mayorista"
                type="number"
                autoComplete="off"
                name="preciom_producto"
                value={formulario.preciom_producto}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon color="primary">point_of_sale</Icon>
                    </InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
                variant="outlined"
                error={false}
                helperText="Precio para mayoristas"
              />
              <TextField
                className={classes.textfield}
                onChange={handlerOnChange}
                label="Stock"
                type="number"
                autoComplete="off"
                disabled
                name="stock_producto"
                value={formulario.stock_producto}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon color="secondary">inventory_2</Icon>
                    </InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
                variant="outlined"
                error={true}
                helperText="Cambiarlo en inventario"
              />
              <TextField
              className={classes.textfield}
              onChange={handlerOnChange}
              label="Stock mínimo"
              type="number"
              autoComplete="off"
              name="minimo_producto"
              value={formulario.minimo_producto}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon color="primary">inventory_2</Icon>
                  </InputAdornment>
                ),
                inputProps: { min: 0 },
              }}
              variant="outlined"
              error={false}
              helperText="Cantidad mínima de existencia"
            />
              <TextField
                className={classes.textfield}
                onChange={handlerOnChange}
                label="IVA"
                color="secondary"
                type="number"
                autoComplete="off"
                name="iva_producto"
                value={formulario.iva_producto}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon color="error">monetization_on</Icon>
                    </InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
                variant="outlined"
                error={false}
                helperText="Impuesto %"
              />
              </div>
           </TabPanel>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              disabled={cargando || existeProducto}
            >
              EDITAR
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                Funciones.goto(`/productos`);
              }}
              variant="outlined"
              disabled={cargando}
            >
              CERRAR
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ProductosFormEdit;
