import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import swal from "sweetalert";
import { APICALLER } from "../../../Api/ApiCaller";
import { history, BASEURL } from "../../../VariablesGlobales/globales";
import { useLogin } from "../../../Contextos/LoginProvider";

const VentasProvider = createContext();

const VentasContext = ({ children }) => {
  const [terminarVentaDialog, setTerminarVentaDialog] = useState(false);
  const { id_user } = useLogin();

  // verificar si existe en storage unos items
  const storage = JSON.parse(localStorage.getItem("factura"));

  const [itemsFactura, setItemsFactura] = useState(
    storage ? storage.items : []
  );
  const [totalPrecioFactura, setTotalPrecioFactura] = useState(
    storage ? storage.total : 0
  );

  const [totalIva, setTotalIva] = useState(0);

  const [moneda, setMoneda] = useState(storage ? storage.moneda : {});
  const [monedas, setMonedas] = useState([]);
  const [valorMoneda,setValorMoneda] = useState(storage ? storage.moneda.valor_moneda : 1)


  const [openInsertaPrecio,setOpenInsertaPrecio] = useState(false);
  const [openDialogCotizacion, setOpenDialogCotizacion] = useState(false);
  const [openBuscaProductos, setOpenBuscaProductos] = useState(false);
  const [openEditaPrecio, setOpenEditaPrecio] = useState(false);
  const [openRegistrarCliente, setOpenRegistrarCliente] = useState(false);
  const [openBuscaCliente, setOpenBuscaCliente] = useState(false);

  const [listaBuscaProducto, setListaBuscaProducto] = useState([]);

  const [indexPrecioCambiar, setIndexPrecioCambiar] = useState(0);

  const [nroFactura, setNroFactura] = useState(0);
  const [idCaja,setIdCaja] = useState("")
  const [listaCajas,setListaCajas] = useState([])
  const [idFactura,setIdFactura] = useState(0)

  const [openSnack, setOpenSnack] = useState(false);

  const [ordenDeCompra,setOrdenDeCompra] = useState("")
  
  const [cargando, setCargando] = useState(true);
  const [cargandoItem, setCargandoItem] = useState(false)

  const [precioOriginal, setPrecioOriginal] = useState(0);

  const inputBuscaCodigo = useRef(null);

  const inputBuscaProducto = useRef(null);

  const cantidadProductoInput = useRef();

  const nroDocumentoInput = useRef(null);

  const [cantidadRecibida, setCantidadRecibida] = useState(0);

  const initialdatoscliente = {
    id_cliente: 1,
    nombre_cliente: "SIN NOMBRE",
    ruc_cliente: 0,
  };

  const [datosCliente, setDatosCliente] = useState(initialdatoscliente);

  const [formasPago, setFormasPago] = useState([]);

  const date = new Date();
  const fecha = date.getFullYear().toString() +"-" +(date.getMonth() + 1).toString().padStart(2, 0) +"-" +date.getDate().toString().padStart(2, 0);
  

  const switchBuscaProducto = () => setOpenBuscaProductos(!openBuscaProductos);

  /***** FORMATO DE NUMEROS */
  const numberWithCommas = (nro) => (parseFloat(nro)).toLocaleString('de-DE')

  /** REDONDEAR DE ACUERDO A LA MONEDA ATENCION */
  const redondearNro = (nro) => Math.round(nro*100 )/100;
  


  const limpiarTodo = () => {
    let valuesClear = JSON.stringify({
      total: 0,
      nrofactura: nroFactura,
      items: [],
      moneda: moneda,
      total_iva:0,
    });
    localStorage.setItem("factura", valuesClear);
    setItemsFactura([]);
    setDatosCliente(initialdatoscliente);
    inputBuscaCodigo.current.value = "";
    !nroDocumentoInput.current === null
      ? (nroDocumentoInput.current.value = "")
      : setCantidadRecibida("");
    inputBuscaCodigo.current.focus();
  };

  const BuscaProducto = (e) => e.key === `Enter` && BotonAgregar();
  

  const cambioMoneda = (e) => {
    let nm = [...monedas];
    let i = nm.findIndex((elem) => elem.id_moneda === e.target.value);
    let storage = JSON.parse(localStorage.getItem("factura"));
    let datanm = {
      abreviatura_moneda: nm[i].abreviatura_moneda,
      nombre_moneda: nm[i].nombre_moneda,
      valor_moneda: parseFloat(nm[i].valor_moneda),
      id_moneda: nm[i].id_moneda,
    };
    storage.moneda = datanm;
    setValorMoneda(parseFloat(nm[i].valor_moneda))
    localStorage.setItem("factura", JSON.stringify(storage));
    setMoneda(datanm);
  };

  // AGREGA EL PRODUCTO Y LUEGO PASA A INSERT
  const BotonAgregar = async () => {
    let codigo = inputBuscaCodigo.current?.value;
    let it = [...itemsFactura];
    let index = it.findIndex((e) => e.codigo_producto === codigo);
    let found = it.filter((i) => i.codigo_producto === codigo);
    // si ya hay un producto tons aumenta la cantidad
      if (found.length > 0) {
        var storage = JSON.parse(localStorage.getItem("factura"));
        let cantidadProducto = parseFloat(cantidadProductoInput.current?.value);

        it[index].cantidad_producto += cantidadProducto;
        it[index].total_precio = it[index].precio_producto * it[index].cantidad_producto;
        it[index].iva_total_producto = redondearNro((it[index].total_precio * it[index].iva_producto) /(100 + it[index].iva_producto));

        storage.items[index].cantidad_producto = it[index].cantidad_producto;
        storage.items[index].total_precio = it[index].total_precio;
        storage.items[index].iva_total_producto =it[index].iva_total_producto

        setItemsFactura(it);
        localStorage.setItem("factura", JSON.stringify(storage));
        HACERTOTAL(it);
        inputBuscaCodigo.current.value = "";
        cantidadProductoInput.current.value = "1";

      } else {
        setCargandoItem(true)
        let res = await consultarPorCodigo(codigo);
        if (res.results.length === 1) {
          let stock = parseInt(res.results[0].stock_producto);
          let tipo = parseInt(res.results[0].tipo_producto)
          let nombre = res.results[0].nombre_producto;
          if (stock < 1 && tipo ===1) {
            swal({
              icon: "warning",
              title: `${nombre}`,
              text: ` Sin stock. Desea agregar igualmente?`,
              buttons: ["Cancelar", "Agregar"],
            }).then((e) => {
              if (e) {
                insertarProducto(res.results[0]);
              }
            });
          } else {  
            insertarProducto(res.results[0]);
          }
        } else {
          // CODIGO PARA ALGUNA BALANZA
          // hacemos otra peticion para ver si es por codigo de balanza
          //2000001011102
          /*let newCodigo = Math.trunc(codigo.substr(1, 6));
          let newCant = Math.trunc(codigo.substr(7, 5));
          //console.log(`CODIGO: ${newCodigo}, CANTIDAD: ${newCant/1000}`)
          let res2 = await consultarPorCodigo(newCodigo);
          if (res2.results.length === 1) {
            cantidadProductoInput.current.value = newCant / 1000;
            insertarProducto(res2.results[0]);
          } else {
            //SI NO EXISTE EL CODIGO LANZA EL ERROR
            setOpenSnack(true);
            cantidadProductoInput.current.value = 1;
          } */
          // BORRAR ESTO SI HAY BALANZA
          setOpenSnack(true);
          cantidadProductoInput.current.value = 1;
        } 
        setCargandoItem(false)
        inputBuscaCodigo.current.focus()
    }
  };

  /**HACE LA CONSULTA PARA TRAER EL PRODUCTO O SI EXISTE YA */
  const consultarPorCodigo = async (codigo) => {
    let res = await APICALLER.listar({
      table: "productos",
      fields: `id_producto,codigo_producto,nombre_producto,precio_producto,
        iva_producto,id_producto,stock_producto,costo_producto,preciom_producto,tipo_producto`,
      linkTo: "codigo_producto",
      equalTo: codigo,
    });
    return await res;
  }; /**HACE LA CONSULTA PARA TRAER EL PRODUCTO O SI EXISTE YA */

  /** INSERTA EL PRODUCTO EL LA FACTURA */
  const insertarProducto = (data) => {
    let itA = [...itemsFactura];
    let cantidadProducto = parseFloat(cantidadProductoInput.current?.value);
    var storage = JSON.parse(localStorage.getItem("factura"));

    let total = data.precio_producto * cantidadProducto;
    //console.log(data);
    let nuevo_item = {
      id_producto: data.id_producto,
      cantidad_producto: cantidadProducto,
      codigo_producto: data.codigo_producto,
      nombre_producto: data.nombre_producto,
      precio_producto: parseFloat(data.precio_producto),
      precio_guardado: parseFloat(data.precio_producto),
      iva_producto: parseFloat(data.iva_producto),
      iva_total_producto: 
        (data.precio_producto * data.iva_producto) /(100 + parseFloat(data.iva_producto))
      ,
      total_precio: parseFloat(total),
      stock_producto: parseFloat(data.stock_producto),
      costo_producto: parseFloat(data.costo_producto),
      preciom_producto: parseFloat(data.preciom_producto),
      precio_original: parseFloat(data.precio_producto),
      tipo_producto: parseInt(data.tipo_producto)
    };
    itA.push(nuevo_item);
    setItemsFactura(itA);
    storage.items.push(nuevo_item);
    localStorage.setItem("factura", JSON.stringify(storage));
    
    (cantidadProducto===1 && nuevo_item.tipo_producto===2 ) && insertarPrecio()
    
    HACERTOTAL(itA);
    inputBuscaCodigo.current.value = "";
    cantidadProductoInput.current.value = "1";
    
  };

  const insertarPrecio = ()=>setOpenInsertaPrecio(true)


  const HACERTOTAL = (item) => {
    let suma = 0;
    let iva = 0;
    item.forEach((element) => {
      suma += element.total_precio;
      iva += element.iva_total_producto;
    });
    setTotalPrecioFactura(suma);
    setTotalIva(iva);
    let storage = JSON.parse(localStorage.getItem("factura"));
    storage.total = suma;
    storage.total_iva = iva;
    localStorage.setItem("factura", JSON.stringify(storage));
  };

  const borrarItem = (codigo) => {
    let array = [...itemsFactura];
    let index = array.findIndex((e) => e.codigo_producto === codigo);
    array.splice(index, 1);
    setItemsFactura(array);

    var facturaStorage = JSON.parse(localStorage.getItem("factura"));
    facturaStorage.items = array;
    localStorage.setItem("factura", JSON.stringify(facturaStorage));
    HACERTOTAL(array);
  };

  const AgregarUno = (codigo) => {
    let itA = [...itemsFactura];
    let index = itA.findIndex((e) => e.codigo_producto === codigo);
    let cantidad = parseFloat(itA[index].cantidad_producto) + 1;
    let total = cantidad * itA[index].precio_producto;
    let ivaTotal =
      (total * itA[index].iva_producto) /
      (100 + parseFloat(itA[index].iva_producto));

    itA[index].total_precio = total;
    itA[index].cantidad_producto = cantidad;
    itA[index].iva_total_producto = ivaTotal;
    setItemsFactura(itA);

    var storage = JSON.parse(localStorage.getItem("factura"));
    storage.items[index].total_precio = total;
    storage.items[index].iva_total_producto = ivaTotal;
    storage.items[index].cantidad_producto = cantidad;
    localStorage.setItem("factura", JSON.stringify(storage));
    HACERTOTAL(itA);
  };

  const RestarUno = (codigo) => {
    let itA = [...itemsFactura];
    let index = itA.findIndex((e) => e.codigo_producto === codigo);
    let cantidad = parseFloat(itA[index].cantidad_producto) - 1;
    let total = cantidad * itA[index].precio_producto;
    let ivaTotal =
      (total * itA[index].iva_producto) /
      (100 + parseFloat(itA[index].iva_producto));

    if (cantidad > 0) {
      itA[index].total_precio = total;
      itA[index].cantidad_producto = cantidad;
      itA[index].iva_total_producto = ivaTotal;
      setItemsFactura(itA);

      var storage = JSON.parse(localStorage.getItem("factura"));
      storage.items[index].total_precio = total;
      storage.items[index].iva_total_producto = ivaTotal;
      storage.items[index].cantidad_producto = cantidad;
      localStorage.setItem("factura", JSON.stringify(storage));
      HACERTOTAL(itA);
    }
  };

  // cambiar precio en la factura
  const accionPrecioCambiar = async (codigo) => {
    setOpenEditaPrecio(true);//abre el modal de editar precio
    let itA = [...itemsFactura];
    let index = itA.findIndex((e) => e.codigo_producto === codigo);
    setPrecioOriginal(itA[index].precio_original);

    let found = itA.filter((i) => i.codigo_producto === codigo);
    if (found.length === 1) {
      setIndexPrecioCambiar(index);
      HACERTOTAL(itA);
    } 
    
  };

  // cantidad en la factura
  const cambiarCantidadInput = (value, codigo) => {
    if (value > 0) {
      let itA = [...itemsFactura];
      let i = itA.findIndex((e) => e.codigo_producto === codigo);

      itA[i].cantidad_producto = value;
      let cantidad = itA[i].cantidad_producto;
      itA[i].total_precio = cantidad * itA[i].precio_producto;
      itA[i].iva_total_producto = redondearNro(
        (itA[i].total_precio * itA[i].iva_producto) /
          (100 + itA[i].iva_producto)
      );
      setItemsFactura(itA);

      let storage = JSON.parse(localStorage.getItem("factura"));
      storage.items[i].total_precio = parseFloat(itA[i].total_precio);
      storage.items[i].cantidad_producto = parseFloat(itA[i].cantidad_producto);
      storage.items[i].iva_total_producto = itA[i].iva_total_producto;
      localStorage.setItem("factura", JSON.stringify(storage));

      HACERTOTAL(itA);
    }
  };

  /******************* TODOS LOS DATOS NECESARIO AL PRINCIPIO  *******************/

  /**FORMAS DE PAGO, EFE, CHEQUE, TARJETAS, TRANSFERENCIAS */
  const getFormasPago = useCallback(async () => {
    const res = await APICALLER.listar({ table: "facturas_formas_pagos" });
    setFormasPago(res.response === "ok" ? res.results : []);
  }, []);

  /** SI EXISTE EN EL LOCALSTORAGE UNA FACTURA */
  const getFactura = useCallback(async () => {
    
    const res = await APICALLER.listar({ table: "monedas" });
    setMonedas(res.results);

    if (!localStorage.getItem("factura")) {
      let activo = [...res.results];
      let monedaActiva = activo.filter((e) => e.activo_moneda === "1");
      let dataMonedaActiva = {
        abreviatura_moneda: monedaActiva[0].abreviatura_moneda,
        nombre_moneda: monedaActiva[0].nombre_moneda,
        valor_moneda: parseFloat(monedaActiva[0].valor_moneda),
        id_moneda: monedaActiva[0].id_moneda,
      };
      setMoneda(dataMonedaActiva);
      const values = JSON.stringify({
        total: 0,
        total_iva: 0,
        nrofactura: nroFactura,
        items: [],
        moneda: dataMonedaActiva,
      });
      localStorage.setItem("factura", values);
    }

  }, [nroFactura]);



  const verificarCaja = useCallback(async () => {
    /**********************/
    let res = await APICALLER.listartablas ({
      tables: "cajas_users,cajas",
      on:'id_caja_caja,id_caja',
      fields: "habilitado_caja,id_caja,id_user_caja,estado_caja,nombre_caja",
      linkTo: "id_user_caja",
      equalTo: `${id_user}`,
    });

    if(res.response==="ok"){

      if (res.results.length === 0) {
        swal({icon: "warning",text: "Para facturar debe abrir y habilitar caja",}).then(() => {history.push(`${BASEURL}/cajas?dialog=abrir`);});
      }else{
        if(res.results[0].habilitado_caja==="0"){
          swal({icon: "warning",text: "Su caja está deshabilitada. Por favor comuniquese con el administrador",}).then(() => {history.push(`${BASEURL}/`);});
        }
        else if(res.results[0].estado_caja==="0"){
          swal({icon: "warning",text: "Debe abrir la caja para poder vender.",}).then(() => {history.push(`${BASEURL}/aperturacierrecaja`);});
        }
        else{
          /**********************/
          //let fac = await APICALLER.listar({table:`empresa_facturas`, fields:"id_empresa_factura,last_nro_factura", linkTo:`id_caja_factura`,equalTo:res.results[0].id_caja})
          
          let fac = await APICALLER.listarcomparefields({tables:'empresa_facturas',fieldscompare:'nro_fin_factura,last_nro_factura',expresion:">",fields:"id_empresa_factura,last_nro_factura"})
          
          if(fac.response==="ok" ){ 
            if(fac.results.length>0){ 
              setNroFactura(parseInt(fac.results[0].last_nro_factura))
              setIdFactura(parseInt(fac.results[0].id_empresa_factura))
              setListaCajas(res.results)
              setIdCaja(res.results[0].id_caja);
            }else{
              swal({icon: "warning",text: "Registre nueva factura para poder vender"}).then(() => {history.push(`${BASEURL}/`);});
            }
          } else { console.log(fac)} 
          
          
        }
      } 
    } else{ console.log(res)} 
    
  }, [id_user]);

  const verificarCotizacion = useCallback(() => {}, []);
  /******************* TODOS LOS DATOS NECESARIO AL PRINCIPIO  *******************/

  useEffect(() => {
    setCargando(false)
    verificarCaja();
    verificarCotizacion();
    getFactura();
    getFormasPago();
    setCargando(false)
  }, [getFactura, getFormasPago, verificarCotizacion, verificarCaja]);

  return (
    <VentasProvider.Provider
      value={{
        itemsFactura,
        setItemsFactura,
        totalPrecioFactura,
        setTotalPrecioFactura,
        BuscaProducto,
        openBuscaProductos,
        setOpenBuscaProductos,
        switchBuscaProducto,
        listaBuscaProducto,
        setListaBuscaProducto,
        inputBuscaCodigo,
        insertarProducto,
        openSnack,
        setOpenSnack,
        openEditaPrecio,
        setOpenEditaPrecio,
        precioOriginal,
        setPrecioOriginal,
        moneda,
        setMoneda,
        indexPrecioCambiar,
        setIndexPrecioCambiar,
        terminarVentaDialog,
        setTerminarVentaDialog,
        inputBuscaProducto,
        limpiarTodo,
        fecha,
        openBuscaCliente,
        setOpenBuscaCliente,
        openRegistrarCliente,
        setOpenRegistrarCliente,
        HACERTOTAL,
        cantidadProductoInput,
        nroDocumentoInput,
        accionPrecioCambiar,
        AgregarUno,
        RestarUno,
        borrarItem,
        nroFactura,
        setNroFactura,
        cambiarCantidadInput,
        datosCliente,
        setDatosCliente,
        cantidadRecibida,
        setCantidadRecibida,
        BotonAgregar,
        formasPago,
        setFormasPago,
        monedas,
        setMonedas,
        cambioMoneda,
        openDialogCotizacion,
        setOpenDialogCotizacion,
        redondearNro,
        cargando,
        setCargando,
        numberWithCommas,
        totalIva,
        openInsertaPrecio,setOpenInsertaPrecio,
        idCaja,setIdCaja,cargandoItem, setCargandoItem,
        valorMoneda,setValorMoneda,
        idFactura,
        listaCajas,setListaCajas,
        ordenDeCompra,setOrdenDeCompra
      }}
    >
      {children}
    </VentasProvider.Provider>
  );
};

export default VentasContext;

export const useVentas = () => {
  const {
    itemsFactura,
    setItemsFactura,
    totalPrecioFactura,
    setTotalPrecioFactura,
    BuscaProducto,
    openBuscaProductos,
    setOpenBuscaProductos,
    switchBuscaProducto,
    listaBuscaProducto,
    setListaBuscaProducto,
    inputBuscaCodigo,
    insertarProducto,
    openSnack,
    setOpenSnack,
    openEditaPrecio,
    setOpenEditaPrecio,
    precioOriginal,
    setPrecioOriginal,
    moneda,
    setMoneda,
    indexPrecioCambiar,
    setIndexPrecioCambiar,
    terminarVentaDialog,
    setTerminarVentaDialog,
    inputBuscaProducto,
    limpiarTodo,
    fecha,
    openBuscaCliente,
    setOpenBuscaCliente,
    openRegistrarCliente,
    setOpenRegistrarCliente,
    HACERTOTAL,
    cantidadProductoInput,
    nroDocumentoInput,
    accionPrecioCambiar,
    AgregarUno,
    RestarUno,
    borrarItem,
    nroFactura,
    setNroFactura,
    cambiarCantidadInput,
    datosCliente,
    setDatosCliente,
    cantidadRecibida,
    setCantidadRecibida,
    BotonAgregar,
    formasPago,
    setFormasPago,
    monedas,
    setMonedas,
    cambioMoneda,
    openDialogCotizacion,
    setOpenDialogCotizacion,
    redondearNro,
    cargando,
    setCargando,
    numberWithCommas,
    totalIva,
    openInsertaPrecio,
    setOpenInsertaPrecio,
    idCaja,setIdCaja,cargandoItem, setCargandoItem,
    valorMoneda,setValorMoneda,
    idFactura,
    listaCajas,setListaCajas,
    ordenDeCompra,setOrdenDeCompra
  } = useContext(VentasProvider);
  return {
    itemsFactura,
    setItemsFactura,
    totalPrecioFactura,
    setTotalPrecioFactura,
    BuscaProducto,
    openBuscaProductos,
    setOpenBuscaProductos,
    switchBuscaProducto,
    listaBuscaProducto,
    setListaBuscaProducto,
    inputBuscaCodigo,
    insertarProducto,
    openSnack,
    setOpenSnack,
    openEditaPrecio,
    setOpenEditaPrecio,
    precioOriginal,
    setPrecioOriginal,
    moneda,
    setMoneda,
    indexPrecioCambiar,
    setIndexPrecioCambiar,
    terminarVentaDialog,
    setTerminarVentaDialog,
    inputBuscaProducto,
    limpiarTodo,
    fecha,
    openBuscaCliente,
    setOpenBuscaCliente,
    openRegistrarCliente,
    setOpenRegistrarCliente,
    HACERTOTAL,
    cantidadProductoInput,
    nroDocumentoInput,
    accionPrecioCambiar,
    AgregarUno,
    RestarUno,
    borrarItem,
    nroFactura,
    setNroFactura,
    cambiarCantidadInput,
    datosCliente,
    setDatosCliente,
    cantidadRecibida,
    setCantidadRecibida,
    BotonAgregar,
    formasPago,
    setFormasPago,
    monedas,
    setMonedas,
    cambioMoneda,
    openDialogCotizacion,
    setOpenDialogCotizacion,
    redondearNro,
    cargando,
    setCargando,
    numberWithCommas,
    totalIva,
    openInsertaPrecio,setOpenInsertaPrecio,
    idCaja,setIdCaja,cargandoItem, setCargandoItem,
    valorMoneda,setValorMoneda,
    idFactura,
    listaCajas,setListaCajas,
    ordenDeCompra,setOrdenDeCompra
  };
};
