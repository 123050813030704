import {
 makeStyles,
  } from "@material-ui/core";

export const Styles = makeStyles((theme) => ({
    absolute: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(3),
    },
    botones:{
      "& > *":{
        margin: theme.spacing(0,1)
      }
    },
    textfield: {
      marginTop: theme.spacing(1),
      marginBottom:theme.spacing(1),
    },
    inputs:{
        display:"flex",
        alignItems:"center",
        "& > *":{
          marginRight:"25px",
        }
      },
    titulodialog:{
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center"
    },
    loaderSend:{
      margin:theme.spacing(1,0,2,0),
    },
    page:{
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      "& > *": {
          margin: theme.spacing(0, 1),
        },
    },
  }));