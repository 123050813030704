import React from 'react'

const PresupuestosNew = () => {
  return (
    <div>
        <h1>Nuevo presupuesto</h1>
    </div>
  )
}

export default PresupuestosNew
