import { StylesGenerales } from '../../Styles/StylesGenerales'
import { Container, Typography,Icon,Fab} from '@material-ui/core'
import { Funciones } from '../../Funciones/Funciones'

const NotFound = () => {
  const classes = StylesGenerales()
  return (
    <Container className={classes.centrar}>
        <Icon className={classes.iconWarning}>power_off</Icon>
        <Typography variant="body1" >A donde quieres llegar, porque no vuelves a casa?</Typography>
        <Fab variant="extended" onClick={()=>{Funciones.goto(`/`)}} >
          <Icon>home</Icon>
          VOLVER A CASA
        </Fab>
    </Container>
  )
}

export default NotFound
