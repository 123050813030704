
const FacturasViewFactura = (props) => {

  return (
    <h1>No disponible por el momento</h1>
  )

}

export default FacturasViewFactura
