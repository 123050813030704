import { useState, useContext, createContext, useEffect, useCallback } from "react";
import swal from "sweetalert";
import { APICALLER } from "../../../Api/ApiCaller";
import { useLogin } from "../../../Contextos/LoginProvider";

const CategoriasContext = createContext();
const CategoriasProvider = ({ children }) => {
  
  const [lista, setLista] = useState([]);
  const [limite,setLimite] = useState(30);
  const [countTotal,setCountTotal] = useState(0); 
  const [page,setPage] = useState(0);
  const {token_user} = useLogin()
  const [cargando, setCargando] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  
  const borrarRegistro = async(id,nombre)=>{
    swal({title:"Borrar?",text:nombre,icon:"warning",buttons:["Cancelar","OK"]}).then(
      async(e)=>{
        if(e){
          setCargando(true)
          let cons = await APICALLER.listar({table:"productos",fields:"id_producto",linkTo:"id_categoria_producto",equalTo:id});
          let resC = await APICALLER.count({tables:`categorias`,field:`id_categoria`,condicion:`id_padre_categoria=${id}`});
          if(cons.results.length>0 || resC.count >0){
            swal({icon:"error",title:"Error",text:"No se puede borrar porque tiene productos/categorias en ella"})
          }
          else{
            const res = await APICALLER.delete({table:"categorias",namecolumn:"id_categoria",equalTo:id,token:token_user})
            swal({icon:"success",text:"Borrado correctamente"})
            if(res.response==="ok"){
              let array = [...lista];
              let index = lista.findIndex(e => e.id_categoria === id);
              array.splice(index,1);
              setLista(array);
            } 
          }
          setCargando(false)          
        }
      }
    )
  }

  const buscarRegistro = async (txt) => {
    console.log(txt);
  };

  const getLista = useCallback(async()=>{
      setCargando(true)
      const res = await APICALLER.listar({
        table: "categorias",
        fields: "id_categoria,nombre_categoria,id_padre_categoria",
        startAt:page,
        endAt:limite
      });
      if (res.response === "ok") {
        setLista(res.results);
      }
      setCargando(false);
  },[setLista, setCargando,limite,page])

  const getCountTotal =  useCallback(
    async() => {
      let res = await APICALLER.count({tables:`categorias`,field:`id_categoria`});
      res.response==="ok" ? setCountTotal(res.count) : console.log(res)
    },[setCountTotal])

  useEffect(() => {
    getLista();
    getCountTotal();
  }, [getLista,getCountTotal]);

  return (
    <CategoriasContext.Provider
      value={{
        lista,
        setLista,
        cargando,
        setCargando,
        buscarRegistro,
        openDialog,
        setOpenDialog,
        openDialogEdit, setOpenDialogEdit,
        borrarRegistro,limite,setLimite,page,setPage,
        countTotal,getLista
      }}
    >
      {children}
    </CategoriasContext.Provider>
  );
};

export const useCategorias = () => {
  const {
    lista,
    setLista,
    cargando,
    setCargando,
    buscarRegistro,
    openDialog,
    setOpenDialog,
    openDialogEdit, setOpenDialogEdit,
    borrarRegistro,limite,setLimite,page,setPage,
    countTotal,getLista
  } = useContext(CategoriasContext);
  return {
    lista,
    setLista,
    cargando,
    setCargando,
    buscarRegistro,
    openDialog,
    setOpenDialog,
    openDialogEdit, setOpenDialogEdit,
    borrarRegistro,limite,setLimite,page,setPage,
    countTotal,getLista
  };
};

export default CategoriasProvider;
