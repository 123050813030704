import React from 'react'
import ClientesMain from './ClientesMain'
import ClientesProvider from './ClientesProvider'

const Clientes = () => {
  return (
    <ClientesProvider>
      <ClientesMain />
    </ClientesProvider>
  )
}

export default Clientes
