import Tablas from "../../../Componentes/Tablas"
import {TextField, Button} from '@material-ui/core'
import { useMovimientos } from "./MovimientosProvider"

import {StylesGenerales} from '../../../Styles/StylesGenerales'

const ListaMovimientos = () => {

    const classes = StylesGenerales()
    const {setDesdeFecha,setHastaFecha,desdeFecha,hastaFecha,setOpenDialog} = useMovimientos()

    const changeDatadesde= e=> setDesdeFecha( e.target.value)
    const changeDatahasta= e=> setHastaFecha( e.target.value)

    const abrir = ()=> setOpenDialog(true)

    const columnas = [{
        field:"id_cajas_movimiento",
        headerName:"ID"
    },
    {
        field:"id_registro_caja",
        headerName:"Caja",
    },
    {
        field:"id_user_caja",
        headerName:"Usuario",
    },
    {
        field:"tipo_caja_registro",
        headerName:"Tipo de movimiento",
    },
    {
        field:"fecha_registro",
        headerName:"Fecha de movimiento",
    },
]

    const Acciones = ()=>(<p>Lorenzo</p>)


    const search = (
        <div className={classes.inputs}>
            <TextField variant="outlined" type="date" defaultValue={desdeFecha} onChange={changeDatadesde} name="desdeFecha" />
            <TextField variant="outlined" type="date" defaultValue={hastaFecha} onChange={changeDatahasta} name="hastaFecha" />
            <Button variant="outlined" color="primary" onClick={abrir} >
                Registrar movimiento
            </Button>
        </div>
       )

  return (
    <>
    <Tablas
        icono="show_chart"
        bgicono="#303f9f"
        nombretabla="Movimientos"
        search={search}
        columnas={columnas}
        Acciones={Acciones}
        filas={[]}
    />
    </>
  )
}

export default ListaMovimientos
