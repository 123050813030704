import {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
  useRef,
} from "react";
import { APICALLER } from "../../../Api/ApiCaller";

const Contexto = createContext();

const EntregasProvider = ({ children }) => {
  const [cargando, setCargando] = useState(false);
  const [lista, setLista] = useState([]);
  const nroFactura = useRef(null)
  

  const getFactura = useCallback(async () => {
    setCargando(true)
    let nro = parseInt(nroFactura.current.value);
    if(!isNaN(nro)){
        
        let res = await APICALLER.listartablas({
            tables: "facturas_items,facturas,clientes,productos",
            on: "id_factura,id_items_factura,id_cliente,id_cliente_factura,id_producto,id_producto_factura",
            linkTo: "nro_factura,tipo_producto",
            expresion: "=",
            equalTo: `${nro},1`,
            fields:'codigo_producto,cantidad_producto,nombre_producto,ruc_cliente,nombre_cliente,fecha_factura,nro_factura,entregado_item,id_producto,entregado_item'
          }); 
          res.response==="ok" ? setLista(res.results) : console.log(res)
          //console.log(res.results)
    } 
    setCargando(false)
  }, []);


  /*const getLista = useCallback(async () => {
    let res = await APICALLER.listartablas({
      tables: "facturas_items,facturas,clientes,productos",
      on: "id_factura,id_items_factura,id_cliente,id_cliente_factura,id_producto,id_producto_factura",
      linkTo: "nro_factura",
      expresion: "=",
      equalTo: "311",
      fields:'codigo_producto,cantidad_producto,nombre_producto,ruc_cliente,nombre_cliente,fecha_factura,nro_factura,entregado_item'
    }); 

  }, []);*/

  useEffect(() => {

  }, []);
  return (
    <Contexto.Provider value={{ cargando, setCargando, lista, setLista,getFactura,nroFactura}}>
      {children}
    </Contexto.Provider>
  );
};

export const useEntregas = () => {
  const { cargando, setCargando, lista, setLista, getFactura,nroFactura } = useContext(Contexto);
  return { cargando, setCargando, lista, setLista, getFactura,nroFactura };
};

export default EntregasProvider;
